import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  // CardBody,
  Table
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddButton = React.memo((props) => {
  const { fields, maxElements, parseProps, node, globalDisable } = props;
  if (maxElements && fields.length >= maxElements) return null;

  return (
    <div
      style={{
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        marginBottom: '1rem'
      }}>
      <Button
        onClick={() => {
          fields.push({});

          const currentFields = fields.getAll() || [];

          const changeProps = {
            fieldName: fields.name,
            trigger: fields.name,
            newValue: [...currentFields, {}],
            oldValue: [currentFields],
            arrayAction: {
              action: 'add',
              rootKey: fields.name,
              subKey: fields.name
            }
          };

          parseProps.clientFormChange(changeProps);
          parseProps.serverFormChange(changeProps);
        }}
        style={{ padding: '1rem 2rem' }}
        color="primary"
        type="button"
        disabled={globalDisable}>
        {node.addAnother}{' '}
        <FontAwesomeIcon icon="plus-circle" style={{ marginLeft: '0.25rem' }} />
      </Button>
    </div>
  );
});

const renderFieldArray = (props) => {
  const {
    fields,
    meta: { error, submitFailed },
    node,
    // change,
    // autofill,
    globalDisable,
    viewAsHtml,
    parseSchema,
    parseProps
  } = props;

  const properties = node.properties;
  const maxElements = properties ? properties.maxElements : null;

  const arrayItems = fields.map((arrayField, index) => (
    <tr key={index}>
      <td>
        {node.components.map((field) => {
          const isArrayField = true;
          const subNode = {
            ...field,
            key: `${arrayField}.${field.key}`,
            rootKey: fields.name,
            subKey: field.key,
            keyIndex: index
          };
          return parseSchema({
            ...parseProps,
            node: subNode,
            isArrayField
          });
        })}
      </td>
    </tr>
  ));

  return (
    <React.Fragment>
      <Card>
        <CardHeader
          className="bg-primary"
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
            display: 'grid',
            gridTemplateColumns: '1fr auto'
          }}>
          <div style={{ padding: '0.75rem 0rem' }}>{node.label}</div>
        </CardHeader>
        <Table striped style={{ marginBottom: '0px' }}>
          <tbody>{arrayItems}</tbody>
        </Table>
      </Card>

      {false && !viewAsHtml && (
        <AddButton
          fields={fields}
          maxElements={maxElements}
          parseProps={parseProps}
          globalDisable={globalDisable === true}
          node={node}
        />
      )}
      {submitFailed && error && <span>{error}</span>}
    </React.Fragment>
  );
};

export default renderFieldArray;
