import React from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash-es/cloneDeep';

const AddButton = React.memo((props) => {
  const { fields, maxElements, parseProps, node, globalDisable } = props;
  if (maxElements && fields.length >= maxElements) return null;

  return (
    <div
      style={{
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        marginBottom: '1rem'
      }}>
      <Button
        onClick={() => {
          fields.push({});

          const currentFields = fields.getAll() || [];

          const changeProps = {
            fieldName: fields.name,
            trigger: fields.name,
            newValue: [...currentFields, {}],
            oldValue: [currentFields],
            arrayAction: {
              action: 'add',
              rootKey: fields.name,
              subKey: fields.name
            }
          };

          parseProps.clientFormChange(changeProps);
          parseProps.serverFormChange(changeProps);
        }}
        style={{ padding: '1rem 2rem' }}
        color="primary"
        type="button"
        disabled={globalDisable}>
        {node.addAnother}{' '}
        <FontAwesomeIcon icon="plus-circle" style={{ marginLeft: '0.25rem' }} />
      </Button>
    </div>
  );
});

const renderFieldArray = (props) => {
  const {
    fields,
    meta: { error, submitFailed },
    node,
    // change,
    // autofill,
    globalDisable,
    viewAsHtml,
    parseSchema,
    parseProps
  } = props;

  const properties = node.properties;
  const maxElements = properties ? properties.maxElements : null;

  const arrayItems = fields.map((arrayField, index) => (
    <Card key={index} style={{ marginBottom: '1rem' }}>
      <CardHeader
        className="bg-primary"
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
          display: 'grid',
          gridTemplateColumns: '1fr auto'
        }}>
        <div style={{ padding: '0.75rem 0rem' }}>
          {node.label}
          {index === 0 ? null : ` #${index + 1}`}
        </div>
        {!viewAsHtml && (
          <Button
            title="Delete"
            onClick={() => {
              const currentFields = fields.getAll() || [];
              const newFields = cloneDeep(currentFields);
              newFields.splice(index, 1);

              fields.remove(index);

              const changeProps = {
                fieldName: fields.name,
                trigger: fields.name,
                newValue: newFields,
                oldValue: currentFields,
                arrayAction: {
                  action: 'remove',
                  rootKey: fields.name,
                  subKey: fields.name,
                  index: index
                }
              };

              parseProps.clientFormChange(changeProps);
              parseProps.serverFormChange(changeProps);
            }}
            color="danger"
            style={{ height: 'fit-content', alignSelf: 'center' }}
            type="button"
            disabled={globalDisable === true}>
            {node.removeRow}
          </Button>
        )}
      </CardHeader>
      <CardBody>
        {node.components.map((field) => {
          const isArrayField = true;
          const subNode = {
            ...field,
            key: `${arrayField}.${field.key}`,
            rootKey: fields.name,
            subKey: field.key,
            keyIndex: index
          };
          return parseSchema({
            ...parseProps,
            node: subNode,
            isArrayField
          });
        })}
      </CardBody>
    </Card>
  ));

  return (
    <React.Fragment>
      {arrayItems}
      {!viewAsHtml && (
        <AddButton
          fields={fields}
          maxElements={maxElements}
          parseProps={parseProps}
          globalDisable={globalDisable === true}
          node={node}
        />
      )}
      {submitFailed && error && <span>{error}</span>}
    </React.Fragment>
  );
};

export default renderFieldArray;
