import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
// import { setAuthRedirectPath } from '../../store/actions';

import classes from './PasswordRecovery.module.scss';
import PasswordRecoveryConfirmFormTokenized from '../../components/Forms/PasswordRecoveryForm/PasswordRecoveryConfirmFormTokenized';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Row, Col, Alert } from 'reactstrap';

let Login = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to={props.authRedirectPath} />;
  } else {
    const query = new URLSearchParams(props.location.search);

    const requestSent = query.get('requestSent');

    const email = query.get('email');

    return (
      <Row>
        <Col sm="10" md="8" lg="6" xl="5" className={classes.centered}>
          {requestSent && (
            <React.Fragment>
              <Alert color="success" fade={false}>
                Jeśli adres e-mail istnieje w naszej bazie danych, wyślemy na
                niego token pozwalający na zmianę hasła. Prosimy sprawdzić
                skrzynkę i wprowadzić go poniżej.
              </Alert>
              <br />
            </React.Fragment>
          )}
          <GoogleReCaptchaProvider reCaptchaKey="6LfwmGMhAAAAAK4YxJ0Kdx3dASJum_bvYSWUE_jJ">
            <PasswordRecoveryConfirmFormTokenized email={email} />
          </GoogleReCaptchaProvider>

          <Alert
            color="secondary"
            fade={false}
            style={{ marginTop: '1rem', backgroundColor: 'inherit' }}>
            {' '}
            Nie posiadasz tokenu do odzyskania hasła?{' '}
            <Link to="/password-recovery">Kliknij tutaj.</Link>
          </Alert>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     setRedirect: () =>
//       dispatch(setAuthRedirectPath(props.location.state.referrer))
//   };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Login);
