import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
// import { setAuthRedirectPath } from '../../store/actions';

import classes from './Login.module.scss';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';

import { Row, Col, Alert } from 'reactstrap';

const isOrange = window.location.hostname === 'orange.uspartner.pl';
const isNais =
  window.location.hostname === 'nais.uspartner.pl' ||
  window.location.hostname === 'renomia.uspartner.pl' ||
  'ubezpieczenia.uspartner.pl';

let Login = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to={props.authRedirectPath} />;
  } else {
    // if (props.location.state && props.location.state.referrer)
    //   props.setRedirect();
    const query = new URLSearchParams(props.location.search);
    let loggedOut;
    let emailConfirmed;
    let newPassword;
    if (query.get('emailConfirmed') === 'true') {
      emailConfirmed = (
        <Alert className={classes.centeredText} color="success">
          Dziękujemy za aktywowanie konta, możesz się zalogować.
        </Alert>
      );
    } else if (query.get('emailConfirmed') === 'false') {
      emailConfirmed = (
        <Alert className={classes.centeredText} color="danger">
          Wystąpił błąd, prosimy spróbować ponownie.
        </Alert>
      );
    }
    if (query.get('loggedOut') === 'true') {
      loggedOut = (
        <Alert className={classes.centeredText} color="success">
          Użytkownik został wylogowany.
        </Alert>
      );
    }
    if (query.get('newPassword') === 'true') {
      loggedOut = (
        <Alert className={classes.centeredText} color="success">
          Hasło zostało zmienione, prosimy o ponowne zalogowanie się.
        </Alert>
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Col xs="12" md="8" lg="6" xl="5" className={classes.centered}>
            {loggedOut}
            {emailConfirmed}
            {newPassword}
            <Row>
              <Col xs="12">
                {isNais ? null : (
                  <Alert
                    color="success"
                    fade={false}
                    style={{
                      margin: '0rem 0rem 1rem 0rem',
                      textAlign: 'justify',
                      color: 'black'
                    }}>
                    <strong>UWAGA!</strong>
                    <br />
                    <span>
                      Przed pierwszym zalogowaniem do portalu należy się
                      <Link to="/register"> zarejestrować</Link>.{' '}
                      {!isOrange &&
                        'Dotychczasowe dane do logowania na platformie tvp-tuwpzuw.uspartner.pl nie mają tu zastosowania.'}
                    </span>
                  </Alert>
                )}
              </Col>
            </Row>
            <LoginForm key={props.location.key} />
            <Row>
              <Col sm="12" md="6" className={classes.centered}>
                <Alert
                  color="secondary"
                  fade={false}
                  style={{ marginTop: '1rem', backgroundColor: 'inherit' }}>
                  Nie masz jeszcze konta?{' '}
                  <Link to="/register">Zarejestruj się.</Link>
                </Alert>
              </Col>
              <Col sm="12" md="6" className={classes.centered}>
                <Alert
                  color="secondary"
                  fade={false}
                  style={{ marginTop: '1rem', backgroundColor: 'inherit' }}>
                  Zapomniane hasło?{' '}
                  <Link to="/password-recovery">Odzyskaj je.</Link>
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" className={classes.centered}>
                <Alert
                  color="secondary"
                  fade={false}
                  style={{
                    marginTop: '1rem',
                    backgroundColor: 'inherit',
                    textAlign: 'center'
                  }}>
                  <Link to="/activation">Aktywacja konta</Link>
                </Alert>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     setRedirect: () =>
//       dispatch(setAuthRedirectPath(props.location.state.referrer))
//   };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Login);
