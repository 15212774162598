import * as actionTypes from '../actions/actionTypes';

export const fetchMenu = () => ({
  type: actionTypes.FETCH_MENU,
  request: {
    url: `/identity/ProgramConfig`,
    method: 'get'
  },
  meta: {
    // meta is optional, it will be added to success, error or abort action when defined
  }
});

export const fetchDynamicPage = (pageId, queryString) => {
  const meta = { pageId: pageId };
  let url = `/domain/WebPage/${pageId}`;
  if (queryString) {
    meta.queryString = queryString;
    url += queryString;
    const queryStringParams = new URLSearchParams(queryString);
    const objectId = queryStringParams.get('objectId');
    meta.objectId = objectId;
  }

  return {
    type: actionTypes.FETCH_DYNAMIC_PAGE,
    request: {
      url: url,
      method: 'get'
    },
    meta: meta
  };
};

export const clearDynamicSession = (pageId, objectId) => {
  const meta = { pageId: pageId };
  if (objectId) {
    meta.objectId = objectId;
  }

  return {
    type: actionTypes.CLEAR_DYNAMIC_SESSION,
    meta: meta
  };
};
