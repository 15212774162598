const pl_PL = {};

pl_PL.strings = {
  // When `inline: false`, used as the screen reader label for the button that closes the modal.
  closeModal: 'Zamknij Modal',
  // Used as the screen reader label for the plus (+) button that shows the “Add more files” screen
  addMoreFiles: 'Dodaj więcej plików',
  // Used as the header for import panels, e.g., "Import from Google Drive".
  importFrom: 'Importuj z %{name}',
  // When `inline: false`, used as the screen reader label for the dashboard modal.
  dashboardWindowTitle: 'Uppy Dashboard Window (Press escape to close)',
  // When `inline: true`, used as the screen reader label for the dashboard area.
  dashboardTitle: 'Dashboard',
  // Shown in the Informer when a link to a file was copied to the clipboard.
  copyLinkToClipboardSuccess: 'Skopiowano link.',
  // Used when a link cannot be copied automatically — the user has to select the text from the
  // input element below this string.
  copyLinkToClipboardFallback: 'Kopiuj URL',
  // Used as the hover title and screen reader label for buttons that copy a file link.
  copyLink: 'Kopiuj link',
  // Used as the hover title and screen reader label for file source icons, e.g., "File source: Dropbox".
  fileSource: 'Źródło pliku: %{name}',
  // Used as the label for buttons that accept and close panels (remote providers or metadata editor)
  done: 'Ok',
  // Used as the screen reader label for buttons that remove a file.
  removeFile: 'Wyrzuć plik',
  // Used as the screen reader label for buttons that open the metadata editor panel for a file.
  editFile: 'Edytuj plik',
  // Shown in the panel header for the metadata editor. Rendered as "Editing image.png".
  editing: 'Edytuj %{file}',
  // Text for a button shown on the file preview, used to edit file metadata
  edit: 'Edytuj',
  // Used as the screen reader label for the button that saves metadata edits and returns to the
  // file list view.
  finishEditingFile: 'Zakończ edycję pliku',
  // Used as the label for the tab button that opens the system file selection dialog.
  dropPaste: 'Upuść lub wklej pliki tutaj albo %{browse}',
  // This string is clickable and opens the system file selection dialog.
  browse: 'przeglądaj.',
  // Used as the hover text and screen reader label for file progress indicators when
  // they have been fully uploaded.
  uploadComplete: 'Upload zakończony',
  // Used as the hover text and screen reader label for the buttons to retry failed uploads.
  retryUpload: 'Spróbuj ponownie',

  // Used in a title, how many files are currently selected
  xFilesSelected: {
    0: 'Zaznaczono %{smart_count} plik',
    1: 'Zaznaczono %{smart_count} pliki'
  },

  uploading: 'Upload trwa',
  complete: 'Zakończono',

  // Shown in the status bar if an upload failed.
  uploadFailed: 'Upload nieudany',
  // Shown next to `uploadFailed`.
  pleasePressRetry: 'Spróbuj ponownie',
  // Shown in the status bar while the upload is paused.
  paused: 'Wstrzymany',
  error: 'Błąd',
  // Used as the label for the button that retries an upload.
  retry: 'Ponów',
  // Used as the label for the button that cancels an upload.
  cancel: 'Anuluj',
  // Used as the screen reader label for the button that pauses an upload.
  pauseUpload: 'Wstrzymaj upload',
  // Used as the screen reader label for the button that resumes a paused upload.
  resumeUpload: 'Wznów upload',
  // Used as the screen reader label for the button that cancels an upload.
  cancelUpload: 'Anuluj upload',
  // When `showProgressDetails` is set, shows the number of files that have been fully uploaded so far.
  filesUploadedOfTotal: {
    0: 'Wysłano %{complete} z %{smart_count} plików',
    1: 'Wysłano %{complete} z %{smart_count} plików'
  },
  // When `showProgressDetails` is set, shows the amount of bytes that have been uploaded so far.
  dataUploadedOfTotal: '%{complete} z %{total}',
  // When `showProgressDetails` is set, shows an estimation of how long the upload will take to complete.
  xTimeLeft: 'Pozostało %{time}',
  // Used as the label for the button that starts an upload.
  uploadXFiles: {
    0: 'Uploaduj %{smart_count} plik',
    1: 'Uploaduj %{smart_count} pliki'
  },
  // Used as the label for the button that starts an upload, if another upload has been started in the past
  // and new files were added later.
  uploadXNewFiles: {
    0: 'Uploaduj +%{smart_count} plik',
    1: 'Uploaduj +%{smart_count} pliki'
  }
};

pl_PL.pluralize = function(n) {
  if (n === 1) {
    return 0;
  } else {
    return 1;
  }
};

export default pl_PL;
