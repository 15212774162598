import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Alert } from 'reactstrap';
// import classes from './Dynamic.module.scss';
import Loading from '../../components/Loading/Loading';

// import Formio from '../Formio/Formio';
import FormContainer from '../../components/Forms/FormContainer/FormContainer';

import TwoColumnPageTemplate from './Templates/TwoColumnPageTemplate/TwoColumnPageTemplate';
import TabPageTemplate from './Templates/TabPageTemplate/TabPageTemplate';
import StaticPageTemplate from './Templates/StaticPageTemplate/StaticPageTemplate';
import DocumentsPageTemplate from './Templates/DocumentsPageTemplate/DocumentsPageTemplate';
import ObjectDocumentsPageTemplate from './Templates/ObjectDocumentsPageTemplate/ObjectDocumentsPageTemplate';
import DataTableTemplate from './Templates/TablePageTemplate/TablePageTemplate';
import LettersPageTemplate from './Templates/LettersPageTemplate/LettersPageTemplate';
import WorkflowPageTemplate from './Templates/WorkflowPageTemplate/WorkflowPageTemplate';
import EmployeesConfirmProposalPageTemplate from './Templates/EmployeesConfirmProposalPageTemplate/EmployeesConfirmProposalPageTemplate';

const renderComponent = (props) => {
  const {
    data,
    guid
    // match
  } = props;

  const renderProps = {
    ...props,
    key: guid,
    dynamicComponent: connectedDynamic
  };

  switch (data.pageTemplate) {
    case 'FormPageTemplate':
      return <FormContainer {...renderProps} />;
    case 'TwoColumnPageTemplate':
      return <TwoColumnPageTemplate {...renderProps} />;
    case 'TabPageTemplate':
      return <TabPageTemplate {...renderProps} />;
    case 'StaticPageTemplate':
      return <StaticPageTemplate {...renderProps} />;
    case 'ConfigurationErrorTemplate':
      return (
        <Alert color="danger">
          <div>{data.error}</div>
        </Alert>
      );
    case 'DocumentsPageTemplate':
      return <DocumentsPageTemplate {...renderProps} />;
    case 'WorkflowPageTemplate':
      return <WorkflowPageTemplate {...renderProps} />;
    case 'ObjectDocumentsPageTemplate':
      return <ObjectDocumentsPageTemplate {...renderProps} />;
    case 'DataTableTemplate':
      return <DataTableTemplate {...renderProps} />;
    case 'LettersPageTemplate':
      return <LettersPageTemplate {...renderProps} />;
    case 'EmployeesConfirmProposalPageTemplate':
      return <EmployeesConfirmProposalPageTemplate {...renderProps} />;
    case 'MainPageTemplate':
    case 'ReportsPageTemplate':
    case 'JournalPageTemplate':
    case 'ReportDetailsTemplate':
    case 'UserConfigurationTemplate':
    case 'JournalDocumentsPageTemplate':
    case 'ListPageTemplate':
    default:
      return (
        <React.Fragment>
          <h2 className="page-header">{data.pageName}</h2>
          <Alert color="info">
            <div>Component: {data.pageTemplate}</div>
            <div>Not implemented yet</div>
          </Alert>
        </React.Fragment>
      );
  }
};
class Dynamic extends React.Component {
  componentDidMount() {
    this.updateDynamicPage();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.guid !== prevProps.guid) {
      this.updateDynamicPage();
    }
  }
  updateDynamicPage() {
    this.props.loadDynamic();
  }
  render() {
    const loading = this.props.meta.loading;
    const error = this.props.meta.error;
    const isCached = this.props.data && this.props.data.pageId !== undefined;

    let component = renderComponent({
      data: this.props.data,
      meta: this.props.meta,
      objectId: this.props.objectId,
      guid: this.props.guid,
      match: this.props.match
    });

    return (
      <React.Fragment>
        {loading &&
        (!isCached ||
          (isCached && this.props.data.pageTemplate !== 'FormPageTemplate')) ? (
          <Loading />
        ) : null}
        {isCached && component}
        {error && <Alert color="danger">Error occured :( </Alert>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const queryStringParams = new URLSearchParams(ownProps.location.search);
  const objectId = queryStringParams.get('objectId');

  return {
    data: state.dynamic.ids[ownProps.guid] || {},
    meta: state.dynamic.meta[ownProps.guid] || {},
    objectId
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const queryString = props.queryString
    ? props.queryString
    : props.location.search;

  return {
    loadDynamic: () => {
      dispatch(actions.fetchDynamicPage(props.guid, queryString));
    }
  };
};

const connectedDynamic = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dynamic);

export default connectedDynamic;
