import React from 'react';
import { connect } from 'react-redux';

import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';

import { Switch, Redirect, Route, Link, NavLink } from 'react-router-dom';
import Dynamic from '../Dynamic/Dynamic';

import pageComponents from './zagraniczne/';

//const isOrange = window.location.hostname === 'orange.uspartner.pl';
//const isInsurances = window.location.hostname === 'ubezpieczenia.uspartner.pl';
const isNais =
  window.location.hostname === 'nais.uspartner.pl' ||
  window.location.hostname === 'renomia.uspartner.pl';

let linkZagraniczne =
  'https://www.axa-assistance.pl/ubezpieczenie-turystyczne/?externalpartner=13000170';

if (isNais)
  linkZagraniczne =
    'https://www.axa-assistance.pl/ubezpieczenie-turystyczne/?externalpartner=13100174';

const pracownicyData = [
  { title: 'Informacje Ogólne', component: 'Ogolne', link: 'opis/' }
];

class PublicTwoColumnTemplate extends React.Component {
  render() {
    let currentBreadcrumb;

    const menu = pracownicyData.map((item) => {
      if (item.externalLink) {
        return (
          <ListGroupItem
            tag="a"
            key={item.title}
            href={item.externalLink}
            target="_blank"
            rel="noopener noreferrer"
            action>
            {item.title}
          </ListGroupItem>
        );
      }

      if (
        !currentBreadcrumb &&
        (`${this.props.match.path}${item.link}` ===
          this.props.location.pathname ||
          `${this.props.match.path}${item.link}` ===
            `${this.props.location.pathname}/`)
      ) {
        currentBreadcrumb = (
          <BreadcrumbItem active>{item.title}</BreadcrumbItem>
        );
      }

      const link = `${this.props.match.path}${item.link}`;
      return (
        <ListGroupItem tag={NavLink} key={item.title} to={link} action>
          {item.title}
        </ListGroupItem>
      );
    });

    const routes = pracownicyData.map((item) => {
      if (item.component) {
        return (
          <Route
            key={item.link}
            path={`${this.props.match.path}${item.link}`}
            component={pageComponents[item.component]}
          />
        );
      }
      return null;
    });
    const isTvpTuw =
      window.location.hostname === 'tvp-tuwpzuw.uspartner.pl' &&
      new Date('2022-10-24').getTime() > new Date().getTime();
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{this.props.rootSection}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={this.props.match.path}>{this.props.section}</Link>
          </BreadcrumbItem>
          {currentBreadcrumb}
        </Breadcrumb>

        <Row>
          <Col xs="12" sm="3">
            <ListGroup>{menu}</ListGroup>
            {!isTvpTuw && (
              <a
                href={linkZagraniczne}
                target="_blank"
                rel="noopener noreferrer">
                <Button
                  color="danger"
                  size="lg"
                  className="sidebarDeclarationButton">
                  <i className="fa fa-chevron-circle-right" /> Kup ubezpieczenie
                  <br />
                </Button>
                <br />
              </a>
            )}
          </Col>
          <Col xs="12" sm="9">
            <Switch>
              {routes}
              {this.props.isAuthenticated && (
                <Route
                  path={`/zagraniczne/kup/`}
                  render={(props) => (
                    <Dynamic
                      {...props}
                      guid="e5044f7c-37ab-4ca0-afa6-1d512a29fbee"
                      // key={props.match.params.guid}
                    />
                  )}
                />
              )}
              <Redirect exact from="/zagraniczne" to="/zagraniczne/opis/" />
              <Redirect
                from="/zagraniczne/kup/"
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
              <Redirect
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null
    // username: state.auth.user.name,
    // menu: state.menu.data ? state.menu.data.menu : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicTwoColumnTemplate);
