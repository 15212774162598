import React from 'react';
import { connect } from 'react-redux';
// import classes from './DocumentsPageTemplate.module.scss';
import * as actions from '../../../../store/actions/index';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Moment from 'react-moment';
import Table from '../../../../components/Table/Table';

import //   Card,
//   // CardText,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   Button,
//   CardHeader,
//   CardFooter,
//   ListGroup,
//   ListGroupItem
'reactstrap';

class EmployeesConfirmProposalPageTemplate extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <h2 className="page-header">{this.props.data.pageName}</h2>

        <Table {...this.props} />
      </div>
    );
  }
}

// const mapStateToProps = (state, ownProps) => {
//   return {
//     data: data,
//     categories: categories || [],
//     meta: state.dynamic.meta[ownProps.guid] || {}
//   };
// };

const mapDispatchToProps = (dispatch, props) => {
  return {
    openLetter: (letterId) => {
      dispatch(
        actions.openModal({
          letterId: letterId,
          objectId: props.objectId,
          type: 'letter'
        })
      );
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(EmployeesConfirmProposalPageTemplate);
