import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Input, Button, Form, Alert } from 'antd';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

let siteSource = window.location.hostname;

const renderInput = ({
  input,
  label,
  meta: { touched, error },
  textarea,
  rows,
  placeholder,
  ...rest
}) => (
  <Form.Item
    label={label}
    validateStatus={touched && error ? 'error' : ''}
    help={touched && error ? error : null}
    style={{ marginBottom: '16px' }}>
    {textarea ? (
      <Input.TextArea
        placeholder={placeholder}
        {...input}
        rows={rows || 4}
        {...rest}
      />
    ) : (
      <Input placeholder={placeholder} {...input} {...rest} />
    )}
  </Form.Item>
);

// Validators
const required = (value) => (value ? undefined : 'Pole wymagane');
const validatePhoneNumber = (value) =>
  value && !/^[0-9]{9}$/.test(value) ? 'Niepoprawny numer telefonu' : undefined;
const validateEmail = (value) =>
  value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    ? 'Niepoprawny adres email'
    : undefined;

const LuxmedForm = (props) => {
  const { handleSubmit } = props;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmitForm = async (formValues) => {
    if (!executeRecaptcha) {
      alert('reCAPTCHA not yet available');
      return;
    }
    const captchaToken = await executeRecaptcha('submitForm');

    console.log({ ...formValues }, siteSource, {
      'g-recaptcha-response': captchaToken
    });

    axios
      .post('/ws/luxmedpublic/SubmitForm', {
        ...formValues,
        siteSource,
        'g-recaptcha-response': captchaToken
      })
      .then(function (response) {
        console.log(response);
        setFormSubmitted(true);
      })
      .catch(function (error) {
        console.log(error);
        alert('Wystąpił błąd. Skontakuj się z administratorem!');
      });
  };

  return (
    <React.Fragment>
      <h4 className="firstColor">Formularz kontaktowy</h4>
      <br />
      {!formSubmitted ? (
        <Form
          layout="vertical" // Ustawienie układu etykiet nad polami
          onFinish={handleSubmit(onSubmitForm)}
          autoComplete="off">
          <Field
            name="fullName"
            component={renderInput}
            label="Imię i nazwisko"
            validate={required}
            placeholder="Podaj imię i nazwisko"
          />
          <Field
            name="phone"
            component={renderInput}
            label="Telefon"
            validate={[required, validatePhoneNumber]}
            placeholder="Podaj nr telefonu"
          />
          <Field
            name="email"
            component={renderInput}
            label="Email"
            validate={[required, validateEmail]}
            placeholder="Podaj email"
          />
          <Field
            name="message"
            component={renderInput}
            label="Zakres ubezpieczenia"
            validate={[required]}
            textarea
            placeholder="Jaki zakres ubezpieczenia Cię interesuje?"
          />
          <Form.Item style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Wyślij
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Alert
          message="Dziękujemy za kontakt!"
          description="Odezwiemy się w ciągu 48 godzin."
          type="success"
          showIcon
        />
      )}
      <br />

      <div className="text-center">
        <h4 className="firstColor">Telefon: +48 605 308 400</h4>
      </div>
    </React.Fragment>
  );
};

export default reduxForm({
  form: 'publicForm'
})(LuxmedForm);
