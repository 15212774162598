import DokumentyDoPobrania from './DokumentyDoPobrania';
import Ogolne from './Ogolne';
import PorownaniePakietow from './PorownaniePakietow';
import Refundacja from './Refundacja';
import Reklamacja from './Reklamacja';
import ZasadyPrzystepowania from './ZasadyPrzystepowania';
import ZasadyUmawiania from './ZasadyUmawiania';
import Pomoc from './Pomoc';

export default {
  DokumentyDoPobrania,
  Ogolne,
  PorownaniePakietow,
  Refundacja,
  Reklamacja,
  ZasadyPrzystepowania,
  ZasadyUmawiania,
  Pomoc
};
