import React from 'react';
import 'url-search-params-polyfill';

import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { Route, Switch } from 'react-router-dom';
// import './index.scss';

import * as serviceWorker from './serviceWorker';
import { store, history, sagaMiddleware } from './store';

import { rootSaga } from './store/sagas';
import LayoutSelector from './LayoutSelector';

import Moment from 'moment';
import 'moment/locale/pl';
import momentLocalizer from 'react-widgets-moment';

// requ 'typeface-lato';
require('typeface-lato');

momentLocalizer();
Moment.locale('pl');

sagaMiddleware.run(rootSaga);

const rootElement = document.getElementById('root');

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={LayoutSelector} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
