import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';

import './DefaultLayout.scss';

import { AppFooter, AppHeader } from '@coreui/react';

import { Helmet } from 'react-helmet';

import ModalController from '../../components/ModalController/ModalController';
import Login from '../../pages/Login/Login';
import Logout from '../../pages/Logout/Logout';
import Register from '../../pages/Register/Register';
import Dynamic from '../../pages/Dynamic/Dynamic';
import ChangeProgram from '../../pages/ChangeProgram/ChangeProgram';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import ActivationExpired from '../../pages/ActivationExpired/ActivationExpired';
import PasswordRecovery from '../../pages/PasswordRecovery/PasswordRecovery';

// const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));
const Sidebar = React.lazy(() => import('./Sidebar'));

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  render() {
    const { isAuthenticated, hasPrograms, firstMenuItem } = this.props;

    return (
      <div className="defaultLayout">
        <Helmet>
          <title>Ares</title>
        </Helmet>
        <ModalController />
        <div className="app">
          <AppHeader fixed>
            <Suspense fallback={this.loading()}>
              <DefaultHeader />
            </Suspense>
          </AppHeader>
          <div className="app-body">
            <Suspense fallback={this.loading()}>
              <Sidebar {...this.props} />
            </Suspense>
            <main className="main">
              {/*  <AppBreadcrumb appRoutes={routes} /> */}
              <Container fluid className="mainPadding">
                <Suspense fallback={this.loading()}>
                  <Card
                    className="animated fadeIn"
                    key={this.props.location.pathname}>
                    <CardBody>
                      <Switch>
                        {isAuthenticated && (
                          <Route
                            path="/change-password"
                            exact
                            component={ChangePassword}
                          />
                        )}
                        <Route
                          path="/"
                          exact
                          render={(props) => {
                            if (!firstMenuItem) return null;
                            return <Dynamic {...props} guid={firstMenuItem} />;
                          }}
                        />
                        <Route path="/login" exact component={Login} />
                        <Route
                          path="/password-recovery"
                          exact
                          component={PasswordRecovery}
                        />
                        <Route path="/logout" exact component={Logout} />
                        <Route path="/register" exact component={Register} />
                        <Route
                          path="/activationExpired"
                          exact
                          component={ActivationExpired}
                        />
                        <Route path="/404" render={() => <div>404</div>} />
                        {isAuthenticated && (
                          <Route
                            path="/:guid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
                            // component={Dynamic}
                            render={(props) => {
                              return (
                                <Dynamic
                                  {...props}
                                  guid={props.match.params.guid}
                                  // key={props.match.params.guid}
                                />
                              );
                            }}
                          />
                        )}
                        {hasPrograms && isAuthenticated && (
                          <Route
                            path="/changeprogram"
                            component={ChangeProgram}
                          />
                        )}
                        <Redirect
                          from="/:guid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
                          // to="/login"
                          to={{
                            pathname: '/login',
                            state: {
                              referrer: this.props.location.pathname
                            }
                          }}
                        />
                        <Redirect to="/404" />
                      </Switch>
                    </CardBody>
                  </Card>
                </Suspense>
              </Container>
            </main>
            {/*           <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside> */}
          </div>
          <AppFooter>
            <Suspense fallback={this.loading()}>
              <DefaultFooter />
            </Suspense>
          </AppFooter>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const programs = state.auth.programs || [];
  const menu =
    state.menu.data &&
    state.menu.data.menu &&
    Array.isArray(state.menu.data.menu)
      ? state.menu.data.menu
      : [];
  let firstItem;
  if (menu && menu.length > 0) {
    if (menu[0].pageId) {
      firstItem = menu[0].pageId;
    } else if (menu[0].nestedElements) {
      firstItem = menu[0].nestedElements[0].pageId;
    }
  }

  return {
    isAuthenticated: state.auth.token !== null,
    hasPrograms: programs.length > 1 ? true : false,
    firstMenuItem: firstItem,
    menu
  };
};

export default connect(mapStateToProps)(DefaultLayout);
