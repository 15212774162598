import React from 'react';
import { connect } from 'react-redux';

import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';

import { Switch, Redirect, Route, Link, NavLink } from 'react-router-dom';
import Dynamic from '../Dynamic/Dynamic';

import pageComponents from './majatkowe/';

const pracownicyData = [
  { title: 'Informacje Ogólne', component: 'Ogolne', link: 'opis/' },
  { title: 'Kontakt', component: 'Public', link: 'public/' }
];

const pracownicyData2 = [
  { title: 'Informacje Ogólne', component: 'Ogolne', link: 'opis/' },
  { title: 'Wypełnij zgłoszenie', component: 'Public', link: 'public/' }
];

class PublicTwoColumnTemplate extends React.Component {
  render() {
    let currentBreadcrumb;

    const menu = pracownicyData.map((item) => {
      if (item.externalLink && item.component) {
        return (
          <ListGroupItem
            tag="a"
            key={item.title}
            href={item.externalLink}
            target="_blank"
            rel="noopener noreferrer"
            action>
            {item.title}
          </ListGroupItem>
        );
      }

      if (
        !currentBreadcrumb &&
        (`${this.props.match.path}${item.link}` ===
          this.props.location.pathname ||
          `${this.props.match.path}${item.link}` ===
            `${this.props.location.pathname}/`)
      ) {
        currentBreadcrumb = (
          <BreadcrumbItem active>{item.title}</BreadcrumbItem>
        );
      }

      const link = `${this.props.match.path}${item.link}`;
      return (
        <ListGroupItem tag={NavLink} key={item.title} to={link} action>
          {item.title}
        </ListGroupItem>
      );
    });

    const routes = pracownicyData2.map((item) => {
      if (item.component) {
        return (
          <Route
            key={item.link}
            path={`${this.props.match.path}${item.link}`}
            component={pageComponents[item.component]}
          />
        );
      }
      return null;
    });

    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{this.props.rootSection}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={this.props.match.path}>{this.props.section}</Link>
          </BreadcrumbItem>
          {currentBreadcrumb}
        </Breadcrumb>

        <Row>
          <Col xs="12" sm="3">
            <ListGroup>{menu}</ListGroup>
            {/* <Button
              tag={Link}
              to="/majatkowe/public"
              color="primary"
              size="lg"
              className="sidebarDeclarationButton">
              Wybierz wariant ubezpieczenia
            </Button> */}
          </Col>
          <Col xs="12" sm="9">
            <Switch>
              {routes}
              <Redirect exact from="/majatkowe/opis" to="/majatkowe/opis/" />
              <Redirect exact from="/majatkowe/kup" to="/majatkowe/public/" />

              {/* {this.props.isAuthenticated && (
                <Route
                  path={`/majatkowe/kup/`}
                  render={(props) => (
                    <Dynamic
                      {...props}
                      guid="e5044f7c-37ab-4ca0-afa6-1d512a29fbee"
                      // key={props.match.params.guid}
                    />
                  )}
                />
              )} */}
              <Redirect exact from="/majatkowe" to="/majatkowe/opis/" />
              {/* <Redirect
                from="/majatkowe/kup/"
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              /> */}
              <Redirect
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null
    // username: state.auth.user.name,
    // menu: state.menu.data ? state.menu.data.menu : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicTwoColumnTemplate);
