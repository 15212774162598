import React from 'react';
import mail2 from './../../../assets/img/brand/pzu/mail2.png';
import post from './../../../assets/img/brand/pzu/post.png';
import calendar2 from './../../../assets/img/brand/pzu/calendar2.png';

const ZasadyPrzystepowania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor">
        Jak przystąpić do ubezpieczenia zdrowotnego
      </h3>
      <div className="secondColor">
        <p>
          <b>W ramach ubezpieczenia TUW PZUW</b> masz możliwość rozszerzyć
          opiekę medyczną również o:{' '}
        </p>
        <ul>
          <li>
            Senior – dla rodziców i teściów pracownika, współpracownika, byłego
            pracownika, którzy nie ukończyli 80 r.ż.
          </li>
          <li>
            Senior – dla współpracowników i byłych pracowników, którzy ukończyli
            67 rok życia a nie ukończyli 80 r.ż.
          </li>
        </ul>
        <p>
          Aby objąć ubezpieczeniem rodziców/teściów wypełnij{' '}
          <b>formularz online</b>, a następnie{' '}
          <b>wydrukuj deklarację i przekaż do podpisania członkom rodziny.</b>{' '}
          Dla każdego członka rodziny w tym pakiecie konieczne jest wypełnienie
          odrębnej deklaracji. Pamiętaj, że składki za ubezpieczenie seniora
          opłacane są indywidualnie na wskazany w deklaracji numer subkonta
          bankowego.
        </p>
        <p>
          Aby móc zgłosić rodzica/teścia do pakietu senior musisz posiadać{' '}
          <b>aktywny pakiet opieki medycznej</b> jako pracownik, współpracownik
          lub były pracownik.
        </p>
        <p>
          Aby przystąpić do pakietu{' '}
          <b>senior jako współpracownik lub były pracownik</b> wypełnij
          formularz online, a następnie <b>wydrukuj deklarację.</b> Pamiętaj, że
          składki za ubezpieczenie seniora opłacane są indywidualnie na wskazany
          w deklaracji numer subkonta bankowego.
        </p>
        <br />
        <p>Wypełnioną i podpisaną deklarację prześlij:</p>
        <div class="container mt-4 ml-5">
          <div class="row">
            <div class="col-sm-1 d-flex align-items-center justify-content-end">
              <img src={mail2} alt="mail" />
            </div>
            <div class="col-sm-11">
              w formie skanu lub zdjęcia
              <br /> na adres mailowy:{' '}
              <a href="mailto:deklaracje@datus.pl">
                <b>deklaracje@datus.pl</b>
              </a>
            </div>
          </div>
        </div>
        <div class="container mt-4 ml-5">
          <div class="row">
            <div class="col-sm-1 d-flex align-items-center justify-content-end">
              <img src={post} alt="post" />
            </div>
            <div class="col-sm-11">
              lub listownie na adres:
              <br />
              <b>Datus Sp. z o.o.</b>
              <br />
              ul. Obrzeżna 7
              <br />
              02-691 Warszawa
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div class="row">
            <div class="col-sm-1 d-flex align-items-center justify-content-end">
              <img src={calendar2} alt="calendar" />
            </div>
            <div class="col-sm-11">
              Aby przystąpić do ubezpieczeniem zdrowotnego dla seniora od{' '}
              <b>1 dnia miesiąca</b> wypełnij i prześlij deklarację do{' '}
              <b>
                20 dnia miesiąca poprzedzającego rozpoczęcie ochrony
                ubezpieczeniowej,
              </b>
              <br />
              np. jeśli wypełnisz deklarację do <b>20 listopada</b> opieka
              medyczna rozpocznie się od <b>1 grudnia</b>, jeśli wypełnisz{' '}
              <b>20 grudnia</b> opieka medyczna rozpocznie się{' '}
              <b>1 stycznia.</b>
            </div>
          </div>
        </div>
        <br />
        <p>
          Termin opłaty składki upływa <b>20 dnia miesiąca</b> poprzedzającego
          miesiąc ochrony ubezpieczeniowej. Nieterminowe opłacenie składki
          będzie skutkowało zakończeniem ochrony.
        </p>
        <p>
          Byli pracownicy mogą przystąpić do ubezpieczenia w ramach pakietu
          senior w ciągu 3 miesięcy od daty zakończenia umowy z pracodawcą.
        </p>
        <br />

        <span>
          <b className="firstColor">
            Możesz dokonać zmiany zakresu ubezpieczenia wypełniając nową
            deklarację przystąpienia
          </b>
        </span>
        <ul>
          <li>
            zmiana zakresu z <b>„niższego na wyższy”</b> (tj. z zakresu
            podstawowego na zakres rozszerzony) jest możliwa w dowolnym momencie
            z datą obowiązywania od 1 dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana zakresu z „wyższego na niższy” (tj. z zakresu rozszerzonego
            na podstawowy jest możliwa <b>2 razy w roku</b> w określonych
            terminach, tj.
            <br />
            <br />
            <ul>
              <li>
                Dla deklaracji złożonych w okresie od 21 października do 20
                listopada, ze skutkiem od 1 grudnia.
              </li>
              <li>
                Dla deklaracji złożonych w okresie od 21 kwietnia do 20 maja, ze
                skutkiem od 1 czerwca.
              </li>
            </ul>
            <br />
          </li>
        </ul>
        {/* <h5 className="firstColor">
          Możesz dokonać zmiany pakietu lub zakresu ubezpieczenia wypełniając
          nową deklarację przystąpienia
        </h5>
        <ul>
          <li>
            zmiana pakietu z indywidualnego na pakiet partnerski lub rodzinny
            jest możliwa w <b>dowolnym momencie</b> z datą obowiązywania od 1
            dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana pakietu z rodzinnego na partnerski lub indywidualny, bądź z
            partnerskiego na indywidualny jest możliwa w dowolnym momencie z
            datą obowiązywania od 1 dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana zakresu z <b>„niższego na wyższy”</b> (tj. z zakresu
            podstawowego na rozszerzony lub VIP, bądź z zakresu rozszerzonego na
            VIP) jest możliwa w dowolnym momencie z datą obowiązywania od 1 dnia
            kolejnego miesiąca,
          </li>
          <li>
            zmiana zakresu z <b>„wyższego na niższy”</b> (tj. z zakresu VIP na
            rozszerzony lub podstawowy, bądź z zakresu rozszerzonego na
            podstawowy jest możliwa <b>2 razy w roku</b> w określonych
            terminach*
          </li>
        </ul>

        <small>
          *Dla deklaracji złożonych w okresie od 21 października do 20
          listopada, ze skutkiem od 1 grudnia.
        </small>
        <small>
          Dla deklaracji złożonych w okresie od 21 kwietnia do 20 maja, ze
          skutkiem od 1 czerwca.
        </small>

        <h5 className="firstColor mt-5">Rezygnacja</h5>
        <p>
          Masz możliwość rezygnacji z ubezpieczenia po{' '}
          <b>6 pełnych miesiącach</b> ochrony ubezpieczeniowej.
        </p>
        <p>
          W celu rezygnacji z ubezpieczenia wypełnij deklarację rezygnacji i
          przekaż ją zgodnie z wskazanymi wyżej zasadami i terminami.
        </p>
        <p>
          <b>Ponowne przystąpienie</b> możliwe jest <b>2 razy</b> w roku w
          określonych terminach:
        </p>
        <p>
          Dla deklaracji złożonych w okresie od 21 października do 20 listopada,
          ze skutkiem od 1 grudnia.
          <br /> Dla deklaracji złożonych w okresie od 21 kwietnia do 20 maja,
          ze skutkiem od 1 czerwca.
        </p> */}
      </div>
    </React.Fragment>
  );
};

export default ZasadyPrzystepowania;
