import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import classes from './SendLetterForm.module.scss';
import InputField from '../../StaticFormComponents/TextField';
import download from 'js-file-download';

import {
  Button,
  Form,
  Alert,
  Row,
  Col,
  FormGroup,
  Label,
  FormFeedback
} from 'reactstrap';

import { required } from 'redux-form-validators';

const Editor = React.lazy(() => import('../../Editor/Editor'));

const renderEditor = ({
  input,
  label,
  name,
  type,
  height = null,
  meta: { touched, error, warning }
}) => {
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Suspense fallback={<div>Loading...</div>}>
        <Editor
          height={height}
          {...input}
          value={input.value}
          onEditorChange={(e) => {
            console.log('change');
            input.onChange(e);
          }}
        />
      </Suspense>
      {touched && (error && <FormFeedback>{error}</FormFeedback>)}
      {warning && (
        <Alert color="danger" style={{ marginTop: '1rem' }}>
          {warning}
        </Alert>
      )}
    </FormGroup>
  );
};

let SendLetterForm = (props) => {
  const {
    handleSubmit,
    // pristine,
    initialValues,
    submitting,
    error,
    submitSucceeded
  } = props;
  return (
    <Form className={classes.form} onSubmit={handleSubmit}>
      <h4>Pismo: {initialValues.letterName}</h4>
      {submitSucceeded && (
        <Alert color="success">
          Rejestracja powiodła się, prosimy oczekiwać na email.
        </Alert>
      )}

      {!submitSucceeded && (
        //TODO: fix height?
        <React.Fragment>
          <Field
            name="letterSchema"
            label="Letter:"
            component={renderEditor}
            type="textarea"
            className="form-control"
            height="800px"
          />
          <Row>
            <Col sm={12} md={6}>
              <Field
                name="emailTo"
                label="To:"
                component={InputField}
                type="text"
                className="form-control"
                validate={[required({ msg: 'Pole wymagane' })]}
              />
            </Col>
            <Col sm={12} md={6}>
              <Field
                name="emailReplyTo"
                label="Reply to:"
                component={InputField}
                type="text"
                className="form-control"
                // validate={[required({ msg: 'Pole wymagane' })]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Field
                name="emailCC"
                label="Cc:"
                component={InputField}
                type="text"
                className="form-control"
                // validate={[required({ msg: 'Pole wymagane' })]}
              />
            </Col>
            <Col sm={12} md={6}>
              <Field
                name="emailBCC"
                label="Bcc:"
                component={InputField}
                type="text"
                className="form-control"
                // validate={[required({ msg: 'Pole wymagane' })]}
              />
            </Col>
          </Row>

          <Field
            name="emailTitle"
            label="Email title:"
            component={InputField}
            type="text"
            className="form-control"
            validate={[required({ msg: 'Pole wymagane' })]}
          />

          <Field
            name="emailBody"
            label="Email body:"
            component={renderEditor}
            height={250}
            type="textarea"
            className="form-control"
          />

          {error && <Alert color="danger">{error}</Alert>}

          <Button
            type="button"
            color="success"
            onClick={handleSubmit((data) => {
              return new Promise((resolve, reject) => {
                props
                  .sendLetterByEmail(data)
                  .then((successAction) => {
                    resolve();
                  })
                  .catch((errorOrAbortAction) => {
                    console.log('error', errorOrAbortAction);
                    reject(
                      new SubmissionError({
                        _error: 'Error'
                      })
                    );
                  });
              });
            })}
            className={classes.submitButton}
            disabled={submitting}>
            Wyslij Email
          </Button>
          <br />
          <Button
            type="button"
            color="success"
            className={classes.submitButton}
            disabled={submitting}
            onClick={handleSubmit((data) => {
              return new Promise((resolve, reject) => {
                props
                  .generateLetterPdf(data)
                  .then((successAction) => {
                    console.log(successAction);
                    reject();
                    download(
                      successAction.data,
                      successAction.meta.letterName + '.pdf',
                      'application/pdf'
                    );
                  })
                  .catch((errorOrAbortAction) => {
                    console.log('error', errorOrAbortAction);
                    reject(
                      new SubmissionError({
                        _error: 'Error'
                      })
                    );
                  });
              });
            })}>
            Pobierz PDF
          </Button>
        </React.Fragment>
      )}
    </Form>
  );
};

SendLetterForm = reduxForm({
  enableReinitialize: true
})(SendLetterForm);

class SendLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true
    };
  }
  componentDidMount() {
    const { letterId, objectId } = this.props;
    this.props
      .generateLetterTemplate({ letterId, objectId })
      .then((successAction) =>
        this.setState({ data: successAction.data, loading: false })
      );
  }
  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <SendLetterForm
        form={this.props.letterId}
        initialValues={this.state.data}
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      // return new Promise((resolve, reject) => {
      //   dispatch(actions.auth({ values, resolve, reject }));
      // });
      console.log('onsub from map');
    },
    generateLetterTemplate: ({ letterId, objectId }) => {
      return dispatch(actions.generateLetterTemplate({ letterId, objectId }));
    },
    generateLetterPdf: (data) => {
      return dispatch(actions.generateLetterPdf(data));
    },
    sendLetterByEmail: (data) => {
      return dispatch(actions.sendLetterByEmail(data));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SendLetter);
