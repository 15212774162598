import React from 'react';
import { Fields } from 'redux-form';
import { Input, FormGroup, Label, FormFeedback, Row } from 'reactstrap';

const renderFields = (props) => {
  const {
    globalDisable,
    viewAsHtml,
    // change,
    // autofill,
    label,
    labelColWidth,
    type,
    placeholder,
    required,
    isArrayField,
    clientFormChange,
    serverFormChange,
    serverArrayFormChange,
    showOnHtmlView,
    rows
  } = props;

  const fieldName = props.names[0];

  let field = props[fieldName];
  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    // console.log('array props? ', props);
    field = props[props.rootKey][props.keyIndex][props.subKey];
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }

  const hidden = formMeta.hidden.input.value;
  const disabled = formMeta.disabled.input.value;

  if (hidden) return null;

  const rowColWidth = 12 - labelColWidth;

  const {
    touched,
    error,
    // autofilled,
    // active,
    // pristine,
    // initial,
    warning
  } = field.meta || {};

  const onChange = (event) => {
    field.input.onChange(event);

    const changeProps = {
      fieldName,
      // trigger: fieldName,
      newValue: event.target.value,
      oldValue: field.input.value
    };

    if (isArrayField) {
      changeProps.arrayAction = {
        rootKey: props.rootKey,
        index: props.keyIndex,
        subKey: props.subKey
      };
    }

    clientFormChange(changeProps);

    // clientFormChange({
    //   fieldName,
    //   newValue: event.target.value,
    //   oldValue: field.input.value
    // });
  };

  const onBlur = (event) => {
    event.preventDefault();
    field.input.onBlur(undefined);

    if (isArrayField) {
      serverArrayFormChange({
        rootKey: props.rootKey,
        arrayAction: {
          rootKey: props.rootKey,
          index: props.keyIndex,
          subKey: props.subKey,
          newValue: event.target.value
        }
      });
    } else {
      serverFormChange({
        trigger: fieldName,
        newValue: event.target.value,
        oldValue: field.input.value
      });
    }
  };

  if (viewAsHtml && !showOnHtmlView) {
    return (
      <Row>
        <Label sm={labelColWidth}>{label}</Label>
        <Label sm={rowColWidth} style={{ fontWeight: 'bold' }}>
          {field.input.value}
        </Label>
      </Row>
    );
  }

  return (
    <React.Fragment>
      {!hidden && (
        <FormGroup>
          <Label for={fieldName}>
            {label}{' '}
            {required && (
              <span style={{ color: 'red', fontWeight: '500' }}>*</span>
            )}
          </Label>
          <Input
            {...(touched && !!error ? { invalid: true } : {})}
            // {...(touched && !!!error ? { valid: true } : {})}
            {...field.input}
            placeholder={placeholder}
            type={type}
            className="form-control"
            disabled={disabled || globalDisable}
            onChange={onChange}
            onBlur={onBlur}
            {...{ rows: rows } || null}
          />
          {touched &&
            ((error && <FormFeedback>{error}</FormFeedback>) ||
              (warning && <FormFeedback>{warning}</FormFeedback>))}
        </FormGroup>
      )}
    </React.Fragment>
  );
};

class TextInput extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label, type } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label ||
      type !== nextProps.type
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        key="k"
        globalDisable={this.props.globalDisable}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={renderFields}
      />
    );
  }
}

export default TextInput;
