import { call } from 'redux-saga/effects';
import printJS from 'print-js';

export function* downloadFileSaga(action) {
  yield call((link) => window.location.replace(link), action.data);
}

export function* printPdfSaga(action) {
  console.log(action.data);
  yield call(
    (pdf) => printJS({ printable: `${pdf}`, type: 'pdf' }),
    action.data
  );
}
