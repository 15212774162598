import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Route, Link, NavLink } from 'react-router-dom';

class TwoColumnPageTemplate extends React.Component {
  parseMenu = (node) => {
    const nodeSplit = node.nodeType ? node.nodeType.split(';') : [];
    const nodeType = nodeSplit[0];
    const nodeTypeLink = nodeSplit[1];

    switch (nodeType) {
      case 'listGroup':
        return (
          <Card
            outline
            color="muted"
            key={node.nodeId}
            style={{ marginBottom: '1rem' }}>
            <CardHeader className="bg-muted">{node.displayName}</CardHeader>
            <ListGroup>
              {node.nestedElements
                ? node.nestedElements.map((subNode) => this.parseMenu(subNode))
                : null}
            </ListGroup>
          </Card>
        );
      case 'externalLink':
        return (
          <ListGroupItem
            tag="a"
            key={node.nodeId}
            href={nodeTypeLink}
            target="_blank"
            rel="noopener noreferrer"
            action>
            {node.displayName}
          </ListGroupItem>
        );
      default:
        return (
          <ListGroupItem
            tag={NavLink}
            key={node.nodeId}
            to={`/${this.props.match.params.guid}/${node.pageId}`}
            action>
            {node.displayName}
          </ListGroupItem>
        );
    }
  };

  parsePageIds = (node) => {
    if (node.pageId) {
      return node.pageId;
    } else if (node.nestedElements) {
      const flatMap = (a, cb) => [].concat(...a.map(cb));
      const cb = (subNode) => this.parsePageIds(subNode);
      return flatMap(node.nestedElements, cb);

      // return node.nestedElements.flatMap((subNode) =>
      //   this.parsePageIds(subNode)
      // );
    }
    return;
  };

  render() {
    const Dynamic = this.props.dynamicComponent;
    const rootPage = this.props.data.settings.rootPage;
    const pageName = this.props.data.pageName;
    const rootPageName = this.props.data.settings.rootPageName;
    const menuColumn = this.props.data.config.menuColumn || [];

    const rootElement =
      rootPage && rootPageName ? (
        <ListGroup style={{ marginBottom: '1rem' }}>
          <ListGroupItem
            tag={NavLink}
            to={`/${this.props.match.params.guid}/`}
            exact
            action>
            {rootPageName}
          </ListGroupItem>
        </ListGroup>
      ) : null;

    const leftColumn = menuColumn.map((node) => this.parseMenu(node));

    return (
      <React.Fragment>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{pageName}</Link>
          </BreadcrumbItem>
        </Breadcrumb>

        <Row>
          <Col xs="12" sm="3">
            <ListGroup>
              {rootElement}
              {leftColumn}
            </ListGroup>
          </Col>
          <Col xs="12" sm="9">
            <Switch>
              <Route
                path="/:guid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
                exact
                render={(props) => {
                  return <Dynamic {...props} guid={rootPage} />;
                }}
              />
              <Route
                path="/:guid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/:subGuid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
                render={(props) => {
                  return (
                    <Dynamic
                      {...props}
                      key={props.match.params.subGuid}
                      guid={props.match.params.subGuid}
                    />
                  );
                }}
              />
            </Switch>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TwoColumnPageTemplate;
