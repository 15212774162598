import React from 'react';
// import photoA from './../../../assets/img/brand/photo1.png';
import photoC from './../../../assets/img/brand/photo13.png';
import logo from './../../../assets/img/brand/luxmed/interrisk.png';
import bar from './../../../assets/img/brand/luxmed/barImg.png';
import { Alert } from 'reactstrap';
import { FourthTable } from './FourthTable';

const Ogolne3 = (props) => {
  return (
    <React.Fragment>
      <img style={{ maxWidth: '250px' }} src={logo} alt={logo} />
      <br />
      <br />
      <br />

      <Alert color="secondary">
        <p className="text-justify">
          <b>Szanowni Państwo,</b>
          <br />
          <br />
          Dla wszystkich zainteresowanych, którzy chcieliby ubezpieczyć swoje
          dzieci (w wieku przedszkolnym, szkolnym i studentów do 26 roku życia)
          od następstw nieszczęśliwych wypadków przygotowaliśmy wspólnie z
          InterRisk możliwość indywidulanego zawierania ubezpieczenia.
        </p>
      </Alert>
      <h2 className="page-header mt-4">EDU Plus</h2>
      <h4>Ubezpieczenie, które zdaje egzamin nie tylko w szkole</h4>
      <img src={bar} alt={bar} />
      <br />
      <br />
      <div className="container">
        <div class="row">
          <div class="col-md-6">
            <div style={{ padding: '10px' }}>
              {' '}
              <p>
                Oferta ubezpieczenia dla osób w wieku do 26 roku życia. Szeroki
                zakres świadczeń – w pakiecie m. in.:
                {/* dla dzieci */}
              </p>
              {/* <h4 className="thirdColor">
                {isInsurances ? '' : 'PRACOWNIKÓW I WSPÓŁPRACOWNIKÓW '}
                {isOrange && 'GRUPY ORANGE'}
                {!isOrange && !isInsurances && 'TVP S.A.'}
              </h4> */}
            </div>
            <ul>
              <li>
                <b>Ochrona zdrowia przez 365 dni w roku, 24 godziny na dobę</b>{' '}
                w dowolnym miejscu na świecie
              </li>
              <li>
                <b>Hejt Stop</b> – wsparcie psychologiczne, prawne i
                informatyczne, w zakresie mowy nienawiści i bezpieczeństwa w
                sieci
              </li>
              <li>
                <b>Assistance EDU Plus</b> – pomoc medyczna, indywidualne
                korepetycje{' '}
              </li>
              <li>
                <b>Wyczynowe uprawianie sportu</b>
              </li>
              <li>
                <b>Pakiet Kleszcz</b>
              </li>
              <li>
                <b>Wsparcie finansowe w razie nieszczęśliwego wypadku</b>
              </li>
              <li>
                <b>Pobyt w szpitalu</b> w związku z chorobą lub nieszczęśliwym
                wypadkiem
              </li>
              <li>
                <b>
                  Pobyt w szpitalu w związku z chorobą lub nieszczęśliwym
                  wypadkiem
                </b>
              </li>
              <li>
                <b>Zwrot kosztów akcji poszukiwawczej dziecka</b>
              </li>
            </ul>
            <br />
            <div style={{ border: '1px dashed black', padding: '10px' }}>
              <h5>Wejdź na stronę i kup:</h5>

              <a
                href="https://klient.interrisk.pl/EduPlusOfertaIndywidualna?agent=OEw1SDVG"
                target="_blank">
                indywidualne ubezpieczenie NNW dla dziecka
              </a>
            </div>
          </div>

          <div class="col-md-6">
            <img src={photoC} alt="firstPhoto" />
          </div>
          <br />
          <br />

          {/* <p>
            <b>Ofertę przygotował:</b>
            <br />
            <b>II Oddział InterRisk TU SA Vienna Insurance Group w Warszawie</b>
          </p>
          <br />

          <small>
            Oferta przygotowana na podstawie Ogólnych Warunków Ubezpieczenia EDU
            Plus zatwierdzonych uchwałą nr 01/18/04/2023 Zarządu InterRisk TU
            S.A. Vienna Insurance Group z dnia 18 kwietnia 2023r. Wyłączenia i
            ograniczenia odpowiedzialności zawarte są w Ogólnych Warunkach
            Ubezpieczenia EDU Plus zatwierdzonych uchwałą nr 01/18/04/2023
            Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 18 kwietnia
            2023r. dostępnych na stronie www.interrisk.pl
          </small> */}
        </div>
      </div>
      {/* <br />
      <br />
      <h4 className="firstColor">EDU Plus</h4>
      <h5 className="firstColor mb-4">
        WYBIERZ PROGRAM OCHRONY UBEZPIECZENIOWEJ NA ROK 2023/2024
      </h5>
      {isOrange || isInsurances ? <FirstTableOrange /> : <FirstTable />}
      
      <SecondTable /> */}

      {/* <FirstTable /> */}
      <br />
      {/* <Regulamin /> */}
      <br />
      {/* <SecondTable /> */}
      <FourthTable />
    </React.Fragment>
  );
};

export default Ogolne3;
