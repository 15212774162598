import React from 'react';
import logo from './../../../assets/img/brand/axa_logo.png';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <h2 className=" mt-4 firstColor page-header" style={{ width: '50%' }}>
          Podróże zagraniczne
        </h2>
        <img style={{ maxWidth: '100px' }} src={logo} alt={logo} />
      </div>
      <br />
      <div className="container firstColor">
        <div className="row text-justify">
          <div className="col-md-6">
            <p>
              <b style={{ color: '#f41044' }}>
                Jakie ubezpieczenie mogę wykupić?
              </b>
            </p>
            <p>
              <b>Jednorazowe ubezpieczenie turystyczne </b>
              <br /> – najpopularniejszy typ ubezpieczenia podróży zagranicznej,
              która może trwać od jednego dnia do jednego roku.
            </p>
            <p>
              <b>Ubezpieczenie podróży wielokrotnych </b> <br />– weź pod uwagę
              ten wariant, jeżeli często wyjeżdżasz za granicę. Ochroną objęta
              jest nieograniczona liczba wyjazdów o długości od jednego do
              sześćdziesięciu dni. Składki mogą być opłacane w formie wygodnych
              płatności w systemie miesięcznym lub rocznym.
            </p>
            <p>
              <b style={{ color: '#f41044' }}>
                Od kiedy obowiązuje ochrona ubezpieczeniowa?
              </b>
            </p>
            <p>
              Jeżeli kupujesz ubezpieczenie w ostatniej chwili ochronę
              ubezpieczeniową zapewniamy już 4 godziny po zakupie ubezpieczenia
              i dokonaniu płatności. Jeżeli więc zapłacisz za ubezpieczenie od
              razu przy zakupie (kartą płatniczą lub przelewem bankowym
              on-line), na przykład dziś w samo południe, to już o godzinie
              szesnastej będziesz ubezpieczony.
            </p>
            <p>
              <b style={{ color: '#f41044' }}>
                Jaki jest zasięg terytorialny ubezpieczenia?
              </b>
            </p>
            <p>
              Obecnie rozróżniamy trzy strefy geograficzne: Europa (obejmująca
              całe terytorium Europy i kraje basenu
            </p>
          </div>
          <div className="col-md-6">
            <br />
            <p>
              Morza Śródziemnego), Świat bez USA i Kanady (oraz ich terytoriów
              zależnych) oraz Świat (z wyłączeniem obszarów powyżej 6 500 m
              n.p.m., Arktyki i Antarktydy). Jeżeli jeszcze się wahasz lub nie
              jesteś pewien, jak sklasyfikować miejsce docelowe, najłatwiej
              będzie wybrać je bezpośrednio z listy krajów docelowych. Jeżeli w
              trakcie podróży zamierzasz odwiedzić więcej krajów, wybierz jako
              cel podróży najbardziej oddalone miejsce, do którego się
              wybierasz.
            </p>
            <p>
              <b style={{ color: '#f41044' }}>
                Nie wiem co wybrać. Pomożecie mi?
              </b>
            </p>
            <p>
              Oczywiście! W godzinach pracy (od poniedziałku do piątku w godz.
              8:00–17:00) na wszystkie pytania dotyczące ubezpieczenia
              turystycznego chętnie odpowiedzą pracownicy naszej infolinii{' '}
              <br />
              <b>+48 22 529 84 44</b>. Możesz też wysłać do nas wiadomość pocztą
              elektroniczną na adres <b>kontakt@axa-assistance.pl</b>.
            </p>
            <p>
              <b style={{ color: '#f41044' }}>Kogo można ubezpieczyć?</b>
            </p>
            <p>
              Każdego! Możesz u nas ubezpieczyć dzieci (od 0 do 17 lat), osoby
              dorosłe (do 69 lat) i seniorów (powyżej 70 lat). Dla dzieci i
              seniorów nie można rozszerzyć zakresu ochrony o ubezpieczenie
              pracy fizycznej.
            </p>
          </div>
        </div>
      </div>
      <br />
      <h2 className="firstColor mt-4">
        Świadczenia realizowane w ubezpieczeniu turystycznym
      </h2>
      <table className="table table-bordered mb-0 text-center firstColor">
        <thead>
          <tr className="prettyRow">
            <td>ZAKRES UBEZPIECZENIA</td>
            <td>URLOPOWICZ</td>
            <td>PODRÓŻNIK</td>
            <td>ZDOBYWCA</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Koszty leczenia i assistance (KL), w tym:</b>
            </td>
            <td>
              <b>200 000 PLN</b>
            </td>
            <td>
              <b>600 000 PLN</b>
            </td>
            <td>
              <b>40 000 000 PLN</b>
            </td>
          </tr>
          <tr>
            <td>Limit na leczenie stomatologiczne</td>
            <td>900 PLN</td>
            <td>1 600 PLN</td>
            <td>2 000 PLN</td>
          </tr>
          <tr>
            <td>Transport do/między/z placówki medycznej</td>
            <td colSpan={3}>do sumy ubezpieczenia</td>
          </tr>
          <tr>
            <td>Ratownictwo</td>
            <td colSpan={3}>do sumy ubezpieczenia</td>
          </tr>
          <tr>
            <td>Transport do Kraju stałego zamieszkania</td>
            <td colSpan={3}>do sumy ubezpieczenia</td>
          </tr>
          <tr>
            <td>Transport do Miejsca zamieszkania</td>
            <td colSpan={3}>do sumy ubezpieczenia</td>
          </tr>
          <tr>
            <td>Przedłużony pobyt</td>
            <td>200 PLN (wyżywienie i nocleg łącznie)/dobę</td>
            <td>450 PLN wyżywienie i nocleg łącznie/dobę</td>
            <td>600 PLN wyżywienie i nocleg łącznie/dobę</td>
          </tr>
          <tr>
            <td>
              Transport i pobyt Osoby bliskiej wezwanej do towarzyszenia
              małoletniemu
            </td>
            <td>transport – do sumy ubezpieczenia nocleg – 200 PLN/noc</td>
            <td>transport – do sumy ubezpieczenia nocleg – 450 PLN/noc</td>
            <td>transport – do sumy ubezpieczenia nocleg – 600 PLN/noc</td>
          </tr>
          <tr>
            <td>
              Transport i pobyt opiekuna towarzyszącego małoletniemu
              Ubezpieczonemu
            </td>
            <td>transport – do sumy ubezpieczenia nocleg – 200 PLN/noc</td>
            <td>transport – do sumy ubezpieczenia nocleg – 450 PLN/noc</td>
            <td>transport – do sumy ubezpieczenia nocleg – 600 PLN/noc</td>
          </tr>
          <tr>
            <td>Pobyt osoby wezwanej do Ubezpieczonego</td>
            <td>transport – do sumy ubezpieczenia nocleg – 200 PLN/noc</td>
            <td>transport – do sumy ubezpieczenia nocleg – 450 PLN/noc</td>
            <td>transport – do sumy ubezpieczenia nocleg – 600 PLN/noc</td>
          </tr>
          <tr>
            <td>Transport zwłok oraz zakup trumny</td>
            <td colSpan={3}>do sumy ubezpieczenia</td>
          </tr>
          <tr>
            <td>Dyżur Centrum Pomocy Assistance</td>
            <td colSpan={3}>TAK</td>
          </tr>
          <tr>
            <td>Kwarantanna/Izolacja</td>
            <td>
              przedłużony pobyt: wyżywienie i nocleg łącznie 200 PLN/dobę,
              maksymalnie 14 dni transport – 1 000 PLN
            </td>
            <td>
              przedłużony pobyt: wyżywienie i nocleg łącznie 450 PLN/dobę,
              maksymalnie 14 dni transport – 1 000 PLN
            </td>
            <td>
              przedłużony pobyt: wyżywienie i nocleg łącznie 600 PLN/dobę,
              maksymalnie 14 dni transport – 2 000 PLN
            </td>
          </tr>
          <tr>
            <td>Opóźnienie Bagażu</td>
            <td>200 PLN</td>
            <td>350 PLN</td>
            <td>500 PLN</td>
          </tr>
          <tr>
            <td>Opóźnienie lub odwołanie lotu</td>
            <td>200 PLN</td>
            <td>350 PLN</td>
            <td>500 PLN</td>
          </tr>{' '}
          <tr>
            <td>Infolinia</td>
            <td colSpan={3}>TAK</td>
          </tr>{' '}
          <tr>
            <td>Tłumaczenia telefoniczne</td>
            <td colSpan={3}>TAK</td>
          </tr>{' '}
          <tr>
            <td>Pomoc prawna</td>
            <td>x</td>
            <td>3 000 PLN</td>
            <td>15 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Lifestyle assistance</td>
            <td>x</td>
            <td>x</td>
            <td>TAK</td>
          </tr>{' '}
          <tr>
            <td>Pomoc dla zwierząt</td>
            <td>500 PLN</td>
            <td>1 000 PLN</td>
            <td>3 000 PLN</td>
          </tr>
          <tr className="prettyRow">
            <td>Następstwa Nieszczęśliwych wypadków (NNW)</td>
            <td>20 000 PLN</td>
            <td>50 000 PLN</td>
            <td>150 000 PLN</td>
          </tr>
          <tr>
            <td>Trwały uszczerbek na zdrowiu</td>
            <td>20 000 PLN</td>
            <td>50 000 PLN</td>
            <td>150 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Śmierć</td>
            <td>10 000 PLN</td>
            <td>25 000 PLN</td>
            <td>75 000 PLN</td>
          </tr>{' '}
          <tr className="prettyRow">
            <td>Bagaż</td>
            <td>x</td>
            <td>3 000 PLN</td>
            <td>6 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Elektronika, Sprzęt sportowy</td>
            <td>x</td>
            <td>1 500 PLN</td>
            <td>3 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Sprzęt służbowy</td>
            <td>x</td>
            <td>x</td>
            <td>6 000 PLN</td>
          </tr>{' '}
          <tr className="prettyRow">
            <td>Odpowiedzialność cywilna (OC)</td>
            <td>x</td>
            <td>250 000 PLN</td>
            <td>1 500 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Szkody osobowe</td>
            <td>x</td>
            <td>250 000 PLN</td>
            <td>1 500 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Szkody rzeczowe</td>
            <td>x</td>
            <td>125 000 PLN</td>
            <td>750 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Udział własny przy szkodach poniżej 750 PLN</td>
            <td>x</td>
            <td>100%</td>
            <td>100%</td>
          </tr>{' '}
          <tr className="prettyRow">
            <td>Pozostawione mienie</td>
            <td>x</td>
            <td>x</td>
            <td>25 000 PLN</td>
          </tr>{' '}
          <tr>
            <td>Elektronika</td>
            <td>x</td>
            <td>x</td>
            <td>5 000 PLN</td>
          </tr>{' '}
          <tr className="prettyRow">
            <td>Rozszerzenia ochrony:</td>
            <td>x</td>
            <td>x</td>
            <td>5 000 PLN</td>
          </tr>{' '}
          <tr>
            <td colSpan={4}>Rozszerzenia ochrony:</td>
          </tr>{' '}
          <tr>
            <td>Sporty wysokiego ryzyka</td>
            <td>Do sumy ubezpieczenia KL i NNW</td>
            <td colSpan={2}>Do sumy ubezpieczenia: KL, NNW, Bagażu i OC</td>
          </tr>{' '}
          <tr>
            <td>Praca fizyczna</td>
            <td colSpan={3}>Do sumy ubezpieczenia KL i NNW</td>
          </tr>
          <tr>
            <td>Rezygnacja z/Przerwanie Podróży</td>
            <td colSpan={3}>45 000 PLN na wszystkich Ubezpieczonych łącznie</td>
          </tr>
          <tr style={{ backgroundColor: '#f8f4fc' }}>
            <td colSpan={4}>
              <b>Motto assistance</b>
            </td>
          </tr>
          <tr>
            <td>Naprawa na miejscu lub holowanie</td>
            <td colSpan={3}>750 PLN</td>
          </tr>
          <tr>
            <td>Taksówka z miejsca zdarzenia</td>
            <td colSpan={3}>350 PLN</td>
          </tr>
          <tr>
            <td>Zakwaterowanie na czas naprawy</td>
            <td colSpan={3}>300 PLN/ noc, max. 3 noce</td>
          </tr>
          <tr>
            <td>Repatriacja nienaprawionego samochodu</td>
            <td colSpan={3}>
              4 000 PLN (jeżeli naprawa nie jest możliwa w ciągu 5 dni)
            </td>
          </tr>
          <tr>
            <td>Złomowanie</td>
            <td colSpan={3}>2 500 PLN</td>
          </tr>
          <tr style={{ backgroundColor: '#f8f4fc' }}>
            <td colSpan={4}>
              <b>Kontynuacja leczenia w PL</b>
            </td>
          </tr>
          <tr>
            <td>Pierwsza wizyta u lekarza</td>
            <td colSpan={3}>500 PLN</td>
          </tr>
          <tr>
            <td>Zdjęcie gipsu, szwów, zmiana opatrunku</td>
            <td colSpan={3}>200 PLN</td>
          </tr>
          <tr>
            <td>Rehabilitacja</td>
            <td colSpan={3}>2 000 PLN</td>
          </tr>
          <tr>
            <td>Transport do i z placówki medycznej</td>
            <td colSpan={3}>500 PLN</td>
          </tr>
          <tr>
            <td>Sprzęt rehabilitacyjny</td>
            <td colSpan={3}>400 PLN</td>
          </tr>
          <tr>
            <td>Pomoc domowa</td>
            <td colSpan={3}>500 PLN</td>
          </tr>
          <tr>
            <td>Program animacyjny</td>
            <td colSpan={3}>300 PLN</td>
          </tr>
          <tr>
            <td>Psycholog</td>
            <td colSpan={3}>500 PLN</td>
          </tr>
          <tr>
            <td>Leczenie choroby tropikalnej</td>
            <td colSpan={3}>500 PLN</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Ogolne;
