import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import classes from './ChangeProgram.module.scss';

import { Card, CardHeader } from 'reactstrap';

const ChangeProgram = (props) => {
  return (
    <div className={classes.cardContainerBody}>
      {props.programs.map((program, index) => {
        const active =
          props.currentProgram === program.programId
            ? `${classes.programCard} ${classes.active}`
            : classes.programCard;
        return (
          <Card
            key={index}
            className={active}
            onClick={() =>
              props.setProgram(props.currentProgram, program.programId)
            }>
            <div
              style={{
                backgroundImage: `url("${program.imageSrc}")`
              }}
              alt="file thumbnail"
              className={classes.cardImg}
            />
            <CardHeader tag="h5">{program.programName}</CardHeader>
          </Card>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const user = state.auth.user;

  return {
    programs: state.auth.programs || [],
    currentProgram: user.programId
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setProgram: (currentProgram, newProgramId) => {
      dispatch(actions.setProgram(currentProgram, newProgramId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeProgram);
