import React from 'react';
import { Fields } from 'redux-form';
import { FormGroup, Label, FormFeedback, Row } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import Moment from 'react-moment';

const renderDateTime = (props) => {
  const {
    label,
    placeholder,
    required,
    dateFormat,
    clientFormChange,
    serverFormChange,
    serverArrayFormChange,
    labelColWidth,
    viewAsHtml,
    isArrayField
  } = props;

  const fieldName = props.names[0];
  const globalDisable = props.globalDisable === true;

  const showTime = false;
  const showDate = true;

  let field = props[fieldName];
  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    field = props[props.rootKey][props.keyIndex][props.subKey];
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }
  const hidden = formMeta.hidden.input.value;
  const disabled = formMeta.disabled.input.value === true;

  if (hidden) return null;

  const rowColWidth = 12 - labelColWidth;

  const {
    touched,
    error,
    // autofilled,
    // active,
    // pristine,
    // initial,
    warning
  } = field.meta;

  const onChange = (value) => {
    if (value) {
      const dateObj = value;
      var month = dateObj.getMonth(); //months from 1-12
      var day = dateObj.getDate();
      var year = dateObj.getFullYear();
      const newUTC = new Date(Date.UTC(year, month, day));
      field.input.onChange(newUTC);

      const changeProps = {
        fieldName,
        // trigger: fieldName,
        newValue: newUTC,
        oldValue: field.input.value
      };

      if (isArrayField) {
        changeProps.arrayAction = {
          rootKey: props.rootKey,
          index: props.keyIndex,
          subKey: props.subKey
        };
      }

      clientFormChange(changeProps);

      if (isArrayField) {
        serverArrayFormChange({
          rootKey: props.rootKey,
          arrayAction: {
            rootKey: props.rootKey,
            index: props.keyIndex,
            subKey: props.subKey,
            newValue: newUTC
          }
        });
      } else {
        serverFormChange({
          trigger: fieldName,
          newValue: newUTC,
          oldValue: field.input.value
        });
      }
    } else {
      field.input.onChange(value);

      const changeProps = {
        fieldName,
        // trigger: fieldName,
        newValue: value,
        oldValue: field.input.value
      };

      if (isArrayField) {
        changeProps.arrayAction = {
          rootKey: props.rootKey,
          index: props.keyIndex,
          subKey: props.subKey
        };
      }

      clientFormChange(changeProps);
      if (isArrayField) {
        serverArrayFormChange({
          rootKey: props.rootKey,
          arrayAction: {
            rootKey: props.rootKey,
            index: props.keyIndex,
            subKey: props.subKey,
            newValue: value
          }
        });
      } else {
        serverFormChange({
          trigger: fieldName,
          newValue: value,
          oldValue: field.input.value
        });
      }
    }

    // clientFormChange({
    //   fieldName,
    //   newValue: value,
    //   oldValue: field.input.value
    // });
    // serverFormChange({
    //   trigger: fieldName,
    //   newValue: value,
    //   oldValue: field.input.value
    // });
  };

  const onBlur = (event) => {
    field.input.onBlur(undefined);
  };

  const datePlaceholder =
    placeholder && placeholder !== '' ? placeholder : 'RRRR-MM-DD';

  if (viewAsHtml) {
    return (
      <Row>
        <Label sm={labelColWidth}>{label}</Label>
        <Label sm={rowColWidth} style={{ fontWeight: 'bold' }}>
          {field.input.value && (
            <Moment format={dateFormat}>{field.input.value}</Moment>
          )}
        </Label>
      </Row>
    );
  }
  return (
    <React.Fragment>
      {!hidden && (
        <FormGroup>
          <Label for={fieldName}>
            {label}{' '}
            {required && (
              <span style={{ color: 'red', fontWeight: '500' }}>*</span>
            )}
          </Label>
          <DateTimePicker
            {...field.input}
            format={dateFormat}
            parse={dateFormat}
            editFormat={dateFormat}
            onChange={onChange}
            onBlur={onBlur}
            time={showTime}
            date={showDate}
            placeholder={datePlaceholder}
            disabled={disabled || globalDisable}
            value={!field.input.value ? null : new Date(field.input.value)}
            containerClassName={touched && !!error ? 'invalid' : null}
          />

          {touched &&
            ((error && (
              <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>
            )) ||
              (warning && (
                <FormFeedback style={{ display: 'block' }}>
                  {warning}
                </FormFeedback>
              )))}
        </FormGroup>
      )}
    </React.Fragment>
  );
};

class DateTime extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, label, values, viewAsHtml } = this.props;
    if (
      globalDisable !== nextProps.globalDisable ||
      viewAsHtml !== nextProps.viewAsHtml ||
      label !== nextProps.label ||
      values !== nextProps.values
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        globalDisable={this.props.globalDisable}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={renderDateTime}
      />
    );
  }
}

export default DateTime;
