import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classes from './ModalController.module.scss';
import * as actions from '../../store/actions/index';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dynamic from '../../pages/Dynamic/Dynamic';
import SendLetter from '../Forms/SendLetterForm/SendLetterForm';

import Draggable from 'react-draggable';

import {
  Modal,
  Alert,
  Button,
  // ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import WorkflowPageTemplate from '../../pages/Dynamic/Templates/WorkflowPageTemplate/WorkflowPageTemplate';

class ModalController extends React.Component {
  modalRender = (modalObj, toggle) => {
    // console.log('modal', modalObj);
    switch (modalObj.type) {
      case 'dynamic':
        return (
          <React.Fragment>
            <ModalBody>
              <Dynamic
                {...this.props}
                guid={modalObj.id}
                queryString={modalObj.queryString}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </React.Fragment>
        );
      case 'workflow':
        return (
          <React.Fragment>
            <ModalBody>
              <WorkflowPageTemplate
                data={modalObj.data}
                guid={modalObj.guid}
                meta={{ loading: false }}
              />
            </ModalBody>
            <ModalFooter>
              {modalObj.triggers.map((trigger, index) => {
                return (
                  <Button
                    onClick={() => {
                      this.props.workflowTriggerAction({
                        sessionId: modalObj.data.config.formConfig.sessionId,
                        formId: modalObj.guid,
                        transitionId: trigger.triggerId,
                        modalConfirmed: true,
                        baseFormId: modalObj.baseFormId
                      });
                    }}
                    key={index}
                    color="success">
                    {trigger.buttonDisplayName}
                  </Button>
                );
              })}
              <Button color="danger" onClick={toggle}>
                Anuluj
              </Button>
            </ModalFooter>
          </React.Fragment>
        );
      case 'letter':
        return (
          <React.Fragment>
            <ModalBody>
              <SendLetter {...modalObj} />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <ModalBody>
              <Alert color="warning">
                <h2 className="page-header">Invalid modal</h2>
                <div>Modal type: {modalObj.type || 'undefined'}</div>
              </Alert>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </React.Fragment>
        );
    }
  };
  render() {
    return (
      <React.Fragment>
        {Object.keys(this.props.modal.ids).map((modal, index) => {
          const toggle = () => this.props.closeModal(modal);
          const modalObj = this.props.modal.ids[modal];
          return (
            <Draggable
              key={index}
              grid={[50, 50]}
              handle=".handle"
              // bounds="body"
            >
              <Modal
                isOpen={true}
                toggle={toggle}
                className={classes.modalWidth}>
                {/* <ModalHeader toggle={toggle}>\</ModalHeader> */}
                <div className={`${classes.handle} handle`}>•••</div>
                {this.modalRender(modalObj, toggle)}
              </Modal>
            </Draggable>
          );
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modal: state.modal
  };
};

const mapDispatchToProps = (dispatch, props) => {
  //   const pageId = props.match.params[props.queryParam];
  //   const queryStringParams = new URLSearchParams(props.location.search);
  //   const objectId = queryStringParams.get('objectId');

  return {
    closeModal: (modalId) => {
      dispatch(actions.closeModal({ id: modalId }));
    },
    workflowTriggerAction: (meta) => {
      dispatch(actions.workflowTriggerAction(meta));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalController));
