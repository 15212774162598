import React from 'react';
import { Link } from 'react-router-dom';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h2 className="page-header">Ubezpieczenie NNW</h2>

      <div className="row">
        <div className="col-sm-7">
          <br />
          <p className="text-justify">
            UNIQUE SERVICE PARTNER Sp. z o.o. prowadząca działalność
            ubezpieczeniową (nr agenta w KNF 11235054/A) w ramach posiadanych
            pełnomocnictw z Zakładami Ubezpieczeń oferuje Państwu ubezpieczenia
            następstw nieszczęśliwych wypadków (NNW) oraz następstw
            nieszczęśliwych wypadków dzieci i młodzieży (NNW Szkolne).
            <br />
            <br />W przypadku zainteresowania przedstawiciel naszej agencji
            skontaktuje się z Państwem w ciągu 48h.
          </p>
          <div className="text-center">
            <Link to="/nnw2024/kup" className="btn btn-lg btn-primary">
              Wypełnij zgłoszenie
            </Link>
          </div>
        </div>

        <div className="col-sm-5">
          <div className="thumbnail">
            <img src="/tvp/dashboard/10.jpg" alt="accident" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Ogolne;
