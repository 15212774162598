import React from 'react';
import consultant from './../../../assets/img/brand/pzu/consultant.png';
import mail from './../../../assets/img/brand/pzu/mail.png';
import screen from './../../../assets/img/brand/pzu/screen.png';

const Pomoc = (props) => {
  return (
    <React.Fragment>
      <h5 className="firstColor mb-4">
        Jeżeli potrzebujesz pomocy technicznej bądź merytorycznej przy składaniu
        deklaracji w systemie Agenta
      </h5>
      <div className="container ">
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-end">
            <img src={consultant} alt="person" />
          </div>
          <div class="col-sm-11 secondColor">
            Zadzwoń pod numer 22 417 34 40 - infolinia czynna od poniedziałku do
            piątku w godzinach 8:00 - 16:00.
          </div>
        </div>
      </div>

      <h5 className="firstColor mt-5">Wsparcie merytoryczne TUW PZUW</h5>
      <div className="container">
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-end">
            <img src={mail} alt="person" />
          </div>
          <div class="col-sm-11 secondColor">
            Napisz na adres:{' '}
            <a href="mailto:ubezpieczeniazdrowotne@tuwpzuw.pl">
              <b>ubezpieczeniazdrowotne@tuwpzuw.pl</b>
            </a>
          </div>
        </div>
      </div>
      <h5 className="firstColor mt-5 ">
        Jeżeli chcesz umówić się na wizytę lekarską lub badanie
      </h5>
      <div className="container">
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-end">
            <img src={consultant} alt="person" />
          </div>
          <div class="col-sm-11 secondColor">
            Zadzwoń – dostępne są 3 całodobowe infolinie czynne 7 dni w
            tygodniu: <b>22 505 15 48; 799 698 698; 801 405 905</b> (opłata
            zgodnie z taryfą operatora)
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-end">
            <img src={screen} alt="person" />
          </div>
          <div class="col-sm-11 secondColor">
            Skorzystaj z Portalu mojePZU na stronie <b>moje.pzu.pl</b> (po
            wcześniejszym założeniu konta)
          </div>
        </div>
      </div>
      <h5 className="firstColor mt-5 ">
        Jeżeli potrzebujesz wsparcia przy zakładniu konta na mojePZU
      </h5>
      <div className="container">
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-end">
            <img src={consultant} alt="person" />
          </div>
          <div class="col-sm-11 secondColor">
            Zadzwoń pod numer 22 566 50 07 (wybierz tonowo 5)
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-end">
            <img src={mail} alt="person" />
          </div>
          <div class="col-sm-11 secondColor">
            Napisz wiadomość na adres{' '}
            <a href="mailto:moje@pzu.pl">
              <b>moje@pzu.pl</b>
            </a>
          </div>
        </div>
      </div>

      <br />
      <span className="secondColor">
        Zachęcamy również do{' '}
        <b>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/public-assets/tvp/2022/Interaktywna_instrukcja.pdf">
            pobrania
          </a>{' '}
        </b>
        instrukcji, jak skorzystać z portalu mojePZU{' '}
      </span>
    </React.Fragment>
  );
};

export default Pomoc;
