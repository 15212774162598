import React from 'react';
import { Fields } from 'redux-form';
import { PDFObject } from 'react-pdfobject';

const renderPdfObject = (props) => {
  const { isArrayField } = props;

  const fieldName = props.names[0];

  let field = props[fieldName];
  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    field = props[props.rootKey][props.keyIndex][props.subKey];
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }

  const hidden = formMeta.hidden.input.value;
  if (hidden) return null;

  return (
    <div>
      {!hidden && field.input.value !== '' && (
        <PDFObject url={`${field.input.value}`} height="600px" />
      )}
    </div>
  );
};

class PdfObject extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label, type } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label ||
      type !== nextProps.type
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        key="k"
        globalDisable={this.props.globalDisable}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={renderPdfObject}
      />
    );
  }
}

export default PdfObject;
