import * as actionTypes from '../actions/actionTypes';

export const fetchDocuments = (pageId) => ({
  type: actionTypes.DOCUMENTS_FETCH,
  request: {
    url: `/files/Catalog/GetAllFiles`,
    method: 'get'
  },
  meta: {
    pageId,
    type: 'documents'
  }
});

export const fetchDocumentsByObjectIdTvp = (objectId) => ({
  type: actionTypes.DOCUMENTS_BY_OBJECT_ID_FETCH,
  request: {
    url: `/domain/InsuranceData/GetPolicyFilesByID/${objectId}`,
    method: 'get'
  },
  meta: {
    objectId
  }
});

export const downloadFile = (fileId, download = true) => ({
  type: actionTypes.DOCUMENTS_DOWNLOAD,
  request: {
    url: `/files/FileStorage/GetPreSignedFileURL/${fileId}`,
    method: 'get'
  },
  meta: {
    fileId,
    download
  }
});

export const printPdf = (fileId, download = true) => ({
  type: actionTypes.DOCUMENTS_PRINT_PDF,
  request: {
    url: `/files/FileStorage/GetPreSignedFileURL/${fileId}`,
    method: 'get'
  },
  meta: {
    fileId,
    download
  }
});
