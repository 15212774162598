import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

import classes from './Register.module.scss';
import RegisterForm from '../../components/Forms/RegisterForm/RegisterForm';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Row, Col, Alert } from 'reactstrap';

let Register = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to={props.authRedirectPath} />;
  } else {
    return (
      <Row>
        <Col xs="12" md="10" xl="8" className={classes.centered}>
          <GoogleReCaptchaProvider reCaptchaKey="6LfwmGMhAAAAAK4YxJ0Kdx3dASJum_bvYSWUE_jJ">
            <RegisterForm key={props.location.key} />
          </GoogleReCaptchaProvider>
          <Alert
            color="secondary"
            fade={false}
            style={{ marginTop: '1rem', backgroundColor: 'inherit' }}>
            Posiadasz konto? <Link to="/login">Zaloguj się.</Link>
          </Alert>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

export default connect(mapStateToProps)(Register);
