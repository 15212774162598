import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import classes from './PasswordRecoveryForm.module.scss';
import { push } from 'connected-react-router';

import InputField from '../../StaticFormComponents/TextField';

import { Button, Form, Alert, Spinner } from 'reactstrap';

import { required, confirmation, format } from 'redux-form-validators';

let LoginForm = (props) => {
  const { handleSubmit, pristine, submitting, error } = props;
  return (
    <Form className={classes.form} onSubmit={handleSubmit}>
      <h4>Ustaw nowe hasło</h4>
      {error && <Alert color="danger">{error}</Alert>}

      <Field
        name="newPassword"
        label="Nowe hasło"
        component={InputField}
        placeholder="Login"
        type="password"
        autocomplete="new-password"
        className="form-control"
        validate={[
          required({ msg: 'Pole wymagane' }),
          format({
            with: '^[\x00-\xFF]*$',
            message: 'Polskie znaki są niedozwolone.'
          }),
          format({
            with: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z$@$!%*?&].{11,}/i,
            message:
              'Hasło powinno składać się z co najmniej 12 znaków, zawierać małe jak i duże litery oraz znaki specjalne ($, @, !, %, *, ?, &) i cyfry.'
          })
        ]}
      />
      <Field
        name="newPasswordConfirm"
        label="Powtórz nowe hasło"
        component={InputField}
        type="password"
        className="form-control"
        autocomplete="new-password"
        validate={[
          required({ msg: 'Pole wymagane' }),
          format({
            with: '^[\x00-\xFF]*$',
            message: 'Polskie znaki są niedozwolone.'
          }),
          format({
            with: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z$@$!%*?&].{11,}/i,
            message:
              'Hasło powinno składać się z co najmniej 12 znaków, zawierać małe jak i duże litery oraz znaki specjalne ($, @, !, %, *, ?, &) i cyfry.'
          }),
          confirmation({
            msg: 'Hasła nie są takie same',
            field: 'newPassword',
            fieldLabel: 'Hasło'
          })
        ]}
      />
      <Button
        type="submit"
        color="success"
        className={classes.submitButton}
        disabled={submitting || pristine}>
        {!submitting && 'Zmień hasło'}
        {submitting && <Spinner size="sm" />}
      </Button>
    </Form>
  );
};

LoginForm = reduxForm({
  form: 'passwordRecoveryConfirm'
})(LoginForm);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    error: state.auth.error,
    submitting: state.auth.loading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values) => {
      console.log(values, ownProps);
      const payload = { newPassword: values.newPassword, ...ownProps };

      return new Promise((resolve, reject) => {
        dispatch(actions.passwordRecoverySetNew(payload))
          .then((successAction) => {
            console.log(successAction);
            resolve(true);
            dispatch(push('/login?newPassword=true'));
          })
          .catch((errorOrAbortAction) => {
            let errorMessage = errorOrAbortAction.error.response.data;
            if (Array.isArray(errorMessage)) {
              errorMessage = (
                <ul>
                  {errorMessage.map((error, index) => (
                    <li key={index}>{error.description}</li>
                  ))}
                </ul>
              );
            }
            reject(
              new SubmissionError({
                _error: errorMessage
              })
            );
          });
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
