import React from 'react';
import { connect } from 'react-redux';
// import classes from './DocumentsPageTemplate.module.scss';
import * as actions from '../../../../store/actions/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Moment from 'react-moment';

import {
  //   Card,
  //   // CardText,
  //   CardBody,
  //   CardTitle,
  //   CardSubtitle,
  //   Button,
  //   CardHeader,
  //   CardFooter,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

class LettersPageTemplate extends React.Component {
  componentDidMount() {}

  render() {
    const templates = this.props.data.config.letters || [];
    return (
      <div>
        <h2 className="page-header">{this.props.data.pageName}</h2>
        <ListGroup>
          {templates.map((template, index) => (
            <ListGroupItem
              key={index}
              tag="div"
              onClick={(event) => {
                this.props.openLetter(template.letterId);
              }}
              action>
              <FontAwesomeIcon
                icon="file-alt"
                style={{ marginRight: '0.5rem' }}
              />
              {template.name}
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    );
  }
}

// const mapStateToProps = (state, ownProps) => {
//   return {
//     data: data,
//     categories: categories || [],
//     meta: state.dynamic.meta[ownProps.guid] || {}
//   };
// };

const mapDispatchToProps = (dispatch, props) => {
  return {
    openLetter: (letterId) => {
      dispatch(
        actions.openModal({
          letterId: letterId,
          objectId: props.objectId,
          type: 'letter'
        })
      );
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LettersPageTemplate);

// export default LettersPageTemplate;
