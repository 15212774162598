import React from 'react';
import { connect } from 'react-redux';

import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Redirect, Route, Link, NavLink } from 'react-router-dom';
import Dynamic from '../Dynamic/Dynamic';

// import Ogolne from "./pracownicy/Ogolne";

import pageComponents from './pracownicy/';

const pracownicyData = [
  { title: 'Informacje ogólne', component: 'Ogolne', link: 'opis/' },
  {
    title: 'Zasady przystępowania',
    component: 'ZasadyPrzystepowania',
    link: 'przystapienie/'
  },
  {
    title: 'Dokumenty do pobrania',
    component: 'DokumentyDoPobrania',
    link: 'dokumenty/'
  },
  {
    title: 'Szpitale',
    externalLink: 'https://www.luxmed.pl/szpitale'
  }

  // {
  //   title: 'Jak umówić się na wizytę',
  //   component: 'ZasadyUmawiania',
  //   link: 'umawianie/'
  // },
  // {
  //   title: 'Jak skorzystać z refundacji?',
  //   component: 'Refundacja',
  //   link: 'refundacja/'
  // },
  // { title: 'Reklamacje', component: 'Reklamacja', link: 'reklamacja/' },
  // {
  //   title: 'Dokumenty do pobrania',
  //   component: 'DokumentyDoPobrania',
  //   link: 'dokumenty/'
  // },
  // {
  //   title: 'Nasze placówki',
  //   externalLink: 'https://www.pzuzdrowie.pl/nasze-placowki'
  // },
  // {
  //   title: 'Dostępne zakresy opieki medycznej',
  //   component: 'PorownaniePakietow',
  //   link: 'porownanie/'
  // },
  // {
  //   title: 'Pomoc',
  //   component: 'Pomoc',
  //   link: 'pomoc/'
  // }
];

class PublicTwoColumnTemplate extends React.Component {
  render() {
    let currentBreadcrumb;

    const menu = pracownicyData.map((item) => {
      if (item.externalLink) {
        return (
          <ListGroupItem
            tag="a"
            key={item.title}
            href={item.externalLink}
            target="_blank"
            rel="noopener noreferrer"
            action>
            {item.title}
          </ListGroupItem>
        );
      }

      if (
        !currentBreadcrumb &&
        (`${this.props.match.path}${item.link}` ===
          this.props.location.pathname ||
          `${this.props.match.path}${item.link}` ===
            `${this.props.location.pathname}/`)
      ) {
        currentBreadcrumb = (
          <BreadcrumbItem active>{item.title}</BreadcrumbItem>
        );
      }

      // Temporary
      if (this.props.location.pathname === '/pracownicy/kup/') {
        currentBreadcrumb = <BreadcrumbItem active>Wycena</BreadcrumbItem>;
      }

      const link = `${this.props.match.path}${item.link}`;
      return (
        <ListGroupItem tag={NavLink} key={item.title} to={link} action>
          {item.title}
        </ListGroupItem>
      );
    });

    const routes = pracownicyData.map((item) => {
      if (item.component) {
        return (
          <Route
            key={item.link}
            path={`${this.props.match.path}${item.link}`}
            component={pageComponents[item.component]}
          />
        );
      }
      return null;
    });
    const isTvpTuw =
      window.location.hostname === 'tvp-tuwpzuw.uspartner.pl' &&
      new Date('2022-10-24').getTime() > new Date().getTime();

    const hostnameSplit = window.location.hostname.split('.');
    let hostnameParam = hostnameSplit[0];

    if (
      hostnameSplit[0] === 'localhost' ||
      hostnameSplit[0] === 'tvpubezpieczenia'
    ) {
      hostnameParam = 'tvpubezpieczenia';
    } else if (hostnameSplit[0] === 'nais') {
      hostnameParam = 'nais';
    } else if (hostnameSplit[0] === 'renomia') {
      hostnameParam = 'renomia';
    }

    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{this.props.rootSection}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={this.props.match.path}>{this.props.section}</Link>
          </BreadcrumbItem>
          {currentBreadcrumb}
        </Breadcrumb>

        <Row>
          <Col xs="12" sm="3">
            <ListGroup>{menu}</ListGroup>
            {!isTvpTuw && (
              <Button
                tag={Link}
                to="/pracownicy/kup"
                color="primary"
                size="lg"
                className="sidebarDeclarationButton">
                <FontAwesomeIcon icon="arrow-alt-circle-right" />
                Zgłoś chęć ubezpieczenia
              </Button>
            )}
          </Col>
          <Col xs="12" sm="9">
            <Switch>
              {routes}
              {this.props.isAuthenticated && (
                <Route
                  path={`/pracownicy/kup/`}
                  render={(props) => (
                    <Dynamic
                      {...props}
                      guid="1f3a17bc-a0f9-4f2b-9c92-60947514697d"
                      queryString={`?domain=${hostnameParam}`}
                      // key={props.match.params.guid}
                    />
                  )}
                />
              )}
              <Redirect exact from="/pracownicy" to="/pracownicy/opis/" />
              <Redirect
                from="/pracownicy/kup/"
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
              <Redirect
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null
    // username: state.auth.user.name,
    // menu: state.menu.data ? state.menu.data.menu : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicTwoColumnTemplate);
