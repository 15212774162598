import * as actionTypes from '../actions/actionTypes';

export const fetchTableRecords = (pageId, pageIndex = 0) => ({
  type: actionTypes.TABLE_RECORDS_FETCH,
  request: {
    url: `/domain/list/data/${pageId}`,
    method: 'post',
    data: { formData: {}, orderBy: [], pageIndex: pageIndex, search: '' }
  },
  meta: {
    pageId,
    pageIndex,
    type: 'tableRecords'
  }
});
