import React from 'react';
import { formValues } from 'redux-form';

const htmlElementContainer = (props) => {
  const { node } = props;
  const regex = /{{([a-zA-Z_.]+)?}}/g;
  const foundHandlebars = node.content.match(regex) || [];

  const dataKeys = foundHandlebars.reduce((accumulator, currentValue) => {
    const processedKey = currentValue
      .substring(2, currentValue.length - 2)
      .split('.')[1];
    accumulator[processedKey] = processedKey;
    return accumulator;
  }, {});

  dataKeys[`formMeta.${node.key}.hidden`] = `formMeta.${node.key}.hidden`;

  const HtmlElement = formValues((props) => {
    return props.dataKeys;
  })(renderHtmlElement);

  return (
    <HtmlElement
      key={node.id}
      content={node.content}
      name={node.key}
      id={node.id}
      foundHandlebars={foundHandlebars}
      dataKeys={dataKeys}
    />
  );
};

const renderHtmlElement = (props) => {
  if (props[`formMeta.${props.name}.hidden`]) {
    return null;
  }

  const handlebarProcessed = props.foundHandlebars.reduce(
    (accumulator, currentValue) => {
      const dataArgs = currentValue
        .substring(2, currentValue.length - 2)
        .split('.');
      if (dataArgs[0] === 'data') {
        const formData =
          props[dataArgs[1]] === undefined ? '' : props[dataArgs[1]];
        return accumulator.replace(currentValue, formData);
      }
      return accumulator;
    },
    props.content
  );
  const html = { __html: handlebarProcessed };
  return (
    <div
      key={props.id}
      dangerouslySetInnerHTML={html}
      style={{ marginBottom: '1rem', marginTop: '1rem' }}
    />
  );
};

export default htmlElementContainer;
