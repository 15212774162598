import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { requestsPromiseMiddleware } from 'redux-saga-requests';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';
import { authCheckState } from './actions/index';
import { middleware as idleMiddleware } from './components/idle';
import thunk from 'redux-thunk';

export const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

export const history = createBrowserHistory();

export function storageAuthListener(store) {
  return (event) => {
    if (event.key === 'authToken') {
      store.dispatch(authCheckState());
    }
  };
}

export function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        idleMiddleware,
        requestsPromiseMiddleware(),
        sagaMiddleware
      )
    )
  );

  // Cross-tab authentication listener
  window.addEventListener('storage', storageAuthListener(store));

  return store;
}

export const store = configureStore();

export default store;
