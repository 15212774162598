import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Field, reduxForm, Fields } from 'redux-form';
import classes from './RegisterForm.module.scss';

import InputField from '../../StaticFormComponents/TextField';
import Checkbox from '../../StaticFormComponents/Checkbox';

import { Button, Form, Alert, Spinner } from 'reactstrap';

import {
  required,
  email,
  confirmation,
  acceptance,
  format
} from 'redux-form-validators';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const isOrange = window.location.hostname === 'orange.uspartner.pl';
const isNais = window.location.hostname === 'nais.uspartner.pl';
let isRenomia =
  window.location.hostname === 'renomia.uspartner.pl' ||
  'ubezpieczenia.uspartner.pl';

const tvpEmailWarning = (value, allValues, props, name) => {
  if (value && value.split('@')[1] === 'tvp.pl') {
    return 'Uwaga: Sugerujemy użyć prywatnego adresu email!';
  }
  return undefined;
};

class selectAllRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ignoreChange: false };
  }
  componentDidUpdate(prevProps) {
    const selectAll = this.props.selectAll.input.value;
    if (
      selectAll !== prevProps.selectAll.input.value &&
      !this.state.ignoreChange
    ) {
      this.selectAll(selectAll);
    } else if (!this.state.ignoreChange) {
      this.checkSelectAll();
    } else {
      this.setState({ ignoreChange: false });
    }
  }

  selectAll(value) {
    this.props.marketing1.input.onChange(value);
    this.props.marketing2.input.onChange(value);
    this.props.terms.input.onChange(value);
  }

  checkSelectAll() {
    const props = this.props;
    const terms = props.terms.input.value;
    const marketing1 = props.marketing1.input.value;
    const marketing2 = props.marketing2.input.value;
    const selectAll = props.selectAll.input.value;

    if (
      terms === true &&
      marketing1 === true &&
      marketing2 === true &&
      selectAll !== true
    ) {
      this.setState({ ignoreChange: true });
      props.selectAll.input.onChange(true);
    } else if (
      (terms !== true || marketing1 !== true || marketing2 !== true) &&
      selectAll === true
    ) {
      this.setState({ ignoreChange: true });
      props.selectAll.input.onChange(false);
    }
  }

  render() {
    return null;
  }
}

let RegisterForm = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    error,
    submitSucceeded,
    onSubmit
  } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();

  let errorAlert = error;
  if (error && error.split(';').length > 1) {
    errorAlert = (
      <ul>
        {error.split(';').map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }

  const hostnameSplit = window.location.hostname.split('.');
  let domain = hostnameSplit[0];

  if (hostnameSplit[0] === 'nais' || hostnameSplit[0] === 'localhost') {
    domain = 'nais';
  } else if (hostnameSplit[0] === 'tvpubezpieczenia') {
    domain = 'tvpubezpieczenia';
  } else if (hostnameSplit[0] === 'renomia') {
    domain = 'renomia';
  } else if (hostnameSplit[0] === 'tvpubezpieczenia') domain = 'usp';

  const onSubmitForm = async (values) => {
    const incomingValues = { ...values };
    const recaptchaToken = await executeRecaptcha('registerTvpUser');
    return onSubmit({ ...incomingValues, recaptchaToken, domain });
  };

  return (
    <Form
      className={classes.form}
      onSubmit={handleSubmit(onSubmitForm)}
      autoComplete="off">
      <h4>Rejestracja</h4>
      {submitSucceeded && (
        <Alert color="success">
          Rejestracja powiodła się. W ciągu kilku minut na adres email podany w
          czasie rejestracji zostanie wysłana wiadomość. W celu aktywacji konta
          należy postępować zgodnie z przesłanymi instrukcjami. Aktywacja konta
          jest niezbędna do uzyskania dostępu do oferty ubezpieczeniowej.
          {/* Rejestracja powiodła się. W ciągu kilku minut na adres email podany w czasie rejestracji
          zostanie wysłany link aktywacyjny. W celu aktywacji konta należy
          kliknąć na przesłany link. Aktywacja konta jest niezbędna do uzyskania
          dostępu do oferty ubezpieczeniowej. */}
        </Alert>
      )}
      {!submitSucceeded && (
        <React.Fragment>
          {/* <Field
            name="login"
            label="Login"
            required
            component={InputField}
            type="text"
            className="form-control"
            validate={[
              required({ msg: 'Pole wymagane' }),
              format({
                with: '^[a-zA-Z0-9]+$',
                message:
                  'Nazwa użytkownika powinna zawierać wyłącznie litery oraz cyfry; polskie znaki są niedozwolone.'
              })
            ]}
          /> */}
          <Field
            name="email"
            label="E-mail"
            required
            component={InputField}
            type="email"
            className="form-control"
            validate={[
              required({ msg: 'Pole wymagane' }),
              email({ msg: 'Wymagany jest poprawny email.' })
            ]}
            warn={tvpEmailWarning}
          />
          <Alert color="info">
            Podany przy rejestracji adres e-mail posłuży Państwu jako
            identyfikator logowania do serwisu.
            <br /> Rekomendujemy posługiwanie się prywatnym adresem mailowym.
          </Alert>
          <Field
            name="password"
            label="Hasło"
            required
            component={InputField}
            type="password"
            className="form-control"
            autocomplete="new-password"
            validate={[
              required({ msg: 'Pole wymagane' }),
              format({
                with: '^[\x00-\xFF]*$',
                message: 'Polskie znaki są niedozwolone.'
              }),
              format({
                with: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z$@$!%*?&].{11,}/i,
                message:
                  'Hasło powinno składać się z co najmniej 12 znaków, zawierać małe jak i duże litery oraz znaki specjalne ($, @, !, %, *, ?, &) i cyfry.'
              })
            ]}
          />
          <Field
            name="confirmPassword"
            label="Powtórz hasło"
            required
            component={InputField}
            type="password"
            className="form-control"
            validate={[
              required({ msg: 'Pole wymagane' }),
              format({
                with: '^[\x00-\xFF]*$',
                message: 'Polskie znaki są niedozwolone.'
              }),
              format({
                with: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z$@$!%*?&].{11,}/i,
                message:
                  'Hasło powinno składać się z co najmniej 12 znaków, zawierać małe jak i duże litery oraz znaki specjalne ($, @, !, %, *, ?, &) i cyfry.'
              }),
              confirmation({
                msg: 'Hasła nie są takie same',
                field: 'password',
                fieldLabel: 'Hasło'
              })
            ]}
          />
          <Alert color="info">
            Hasło powinno składać się z co najmniej 12 znaków, zawierać małe jak
            i duże litery oraz znaki specjalne ($, @, !, %, *, ?, &) i cyfry.
          </Alert>
          <Field
            name="firstname"
            label="Imię"
            required
            component={InputField}
            type="text"
            className="form-control"
            validate={[required({ msg: 'Pole wymagane' })]}
          />
          <Field
            name="lastname"
            label="Nazwisko"
            required
            component={InputField}
            type="text"
            className="form-control"
            validate={[required({ msg: 'Pole wymagane' })]}
          />
          {isOrange && (
            <Field
              name="terms"
              // label="Akceptuję Warunki korzystania z serwisu*"
              label='Akceptuję <a href="/public-assets/USP_regulamin_serwisu_Orange_18.09.2023.pdf" target=\"_blank\" rel=\"noopener noreferrer\">Regulamin świadczenia usług drogą elektroniczną.</a><span style="color:red;">&nbsp;*</span>'
              component={Checkbox}
              type="checkbox"
              validate={acceptance({ msg: 'Zgoda jest wymagana' })}
            />
          )}

          {isNais && (
            <Field
              name="terms"
              // label="Akceptuję Warunki korzystania z serwisu*"
              label='Akceptuję <a href="/public-assets/USP_regulamin_serwisu_Nais.pdf" target=\"_blank\" rel=\"noopener noreferrer\">Regulamin świadczenia usług drogą elektroniczną.</a><span style="color:red;">&nbsp;*</span>'
              component={Checkbox}
              type="checkbox"
              validate={acceptance({ msg: 'Zgoda jest wymagana' })}
            />
          )}

          {isRenomia && (
            <Field
              name="terms"
              // label="Akceptuję Warunki korzystania z serwisu*"
              label='Akceptuję <a href="/public-assets/USP_regulamin_serwisu_Renomia.pdf" target=\"_blank\" rel=\"noopener noreferrer\">Regulamin świadczenia usług drogą elektroniczną.</a><span style="color:red;">&nbsp;*</span>'
              component={Checkbox}
              type="checkbox"
              validate={acceptance({ msg: 'Zgoda jest wymagana' })}
            />
          )}

          {!isOrange && !isNais && !isRenomia && (
            <Field
              name="terms"
              // label="Akceptuję Warunki korzystania z serwisu*"
              label='Akceptuję <a href="/public-assets/USP_regulamin_serwisu%20TVP%2015.04.2019.pdf" target=\"_blank\" rel=\"noopener noreferrer\">Regulamin świadczenia usług drogą elektroniczną.</a><span style="color:red;">&nbsp;*</span>'
              component={Checkbox}
              type="checkbox"
              validate={acceptance({ msg: 'Zgoda jest wymagana' })}
            />
          )}

          <Field
            name="marketing1"
            label='Wyrażam zgodę na przesyłanie przez Unique Service Partner sp. z o.o., będącej agentem ubezpieczeniowym, działającym na rzecz zakładów ubezpieczeń, informacji handlowych, w tym propozycji i ofert ubezpieczenia, za pomocą środków komunikacji elektronicznej.<span style="color:red;">&nbsp;*</span>'
            component={Checkbox}
            type="checkbox"
            validate={acceptance({ msg: 'Zgoda jest wymagana' })}
          />
          <Field
            name="marketing2"
            label="Wyrażam zgodę na używanie przez Unique Service Partner sp. z o.o. automatycznych systemów wywołujących dla celów marketingu bezpośredniego."
            component={Checkbox}
            type="checkbox"
          />
          <Field
            name="selectAll"
            label="<b>Akceptuje wszystkie powyższe oświadczenia</b>"
            component={Checkbox}
            type="checkbox"
          />
          <Fields
            names={['terms', 'marketing1', 'marketing2', 'selectAll']}
            component={selectAllRules}
          />
          <div className={classes.requiredWarning}>
            * oznacza pola wymagane.
          </div>
          <Alert color="secondary">
            Administratorem Twoich danych osobowych jest Unique Service Partner
            sp. z o.o. Szczegółowe informacje na temat zasad przetwarzania
            danych osobowych są zawarte w rozdziale{' '}
            {isOrange && (
              <a
                href="/public-assets/USP_regulamin_serwisu_Orange_18.09.2023.pdf"
                target="_blank"
                rel="noopener noreferrer">
                9 Regulaminu świadczenia usług drogą elektroniczną
              </a>
            )}
            {isNais && (
              <a
                href="/public-assets/USP_regulamin_serwisu_Nais.pdf"
                target="_blank"
                rel="noopener noreferrer">
                9 Regulaminu świadczenia usług drogą elektroniczną
              </a>
            )}
            {isRenomia && (
              <a
                href="/public-assets/USP_regulamin_serwisu_Renomia.pdf"
                target="_blank"
                rel="noopener noreferrer">
                9 Regulaminu świadczenia usług drogą elektroniczną
              </a>
            )}
            {!isOrange && !isNais && !isRenomia && (
              <a
                href="/public-assets/USP_regulamin_serwisu%20TVP%2015.04.2019.pdf"
                target="_blank"
                rel="noopener noreferrer">
                9 Regulaminu świadczenia usług drogą elektroniczną
              </a>
            )}
            .
          </Alert>
          {error && <Alert color="danger">{errorAlert}</Alert>}
          <Button
            type="submit"
            color="success"
            disabled={submitting || pristine}
            className="submitButton">
            {!submitting && 'Zarejestruj się'}
            {submitting && <Spinner size="sm" />}
          </Button>
        </React.Fragment>
      )}
    </Form>
  );
};

RegisterForm = reduxForm({
  form: 'register'
})(RegisterForm);

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.auth.token !== null
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      return new Promise((resolve, reject) => {
        dispatch(actions.register({ values, resolve, reject }));
      });
    }
  };
};

export default connect(null, mapDispatchToProps)(RegisterForm);
