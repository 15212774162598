import * as actionTypes from '../actions/actionTypes';

const initialState = {
  ids: {}
  // meta: {},
  // fetching: {},
  // error: {}
};

const reducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.payload.id]: {
            ...action.payload
          }
        }
      };
    case actionTypes.CLOSE_MODAL:
      const actionId = action.payload.id;
      const { [actionId]: value, ...updatedIds } = state.ids;

      return {
        ...state,
        ids: updatedIds
      };
    default:
      return state;
  }
};

export default reducer;
