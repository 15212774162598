import React from 'react';
import { Fields } from 'redux-form';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './Button.module.scss';

const renderButton = (props) => {
  const {
    viewAsHtml,
    label,
    isArrayField,
    color,
    customClass,
    size,
    leftIcon,
    rightIcon,
    onClick,
    showOnHtmlView
  } = props;

  const fieldName = props.name;

  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    console.log('array props? ', props);
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }

  const hidden = formMeta.hidden.input.value;
  const globalDisable = props.globalDisable === true;
  const disabled = formMeta.disabled.input.value === true;

  if (hidden) return null;

  if (viewAsHtml && !showOnHtmlView) {
    return null;
  }

  const iconLeft = leftIcon ? (
    <FontAwesomeIcon icon={leftIcon} className={classes.leftIcon} />
  ) : null;
  const iconRight = rightIcon ? (
    <FontAwesomeIcon icon={rightIcon} className={classes.rightIcon} />
  ) : null;

  const centered = customClass === 'centered' ? classes.centered : null;

  return (
    <React.Fragment>
      {!hidden && (
        <div className={centered}>
          <Button
            color={color}
            type="button"
            disabled={disabled || globalDisable}
            onClick={onClick}
            size={size}>
            {iconLeft}
            {label}
            {iconRight}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

class FormButton extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        globalDisable={this.props.globalDisable}
        names={[
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={renderButton}
      />
    );
  }
}

export default FormButton;
