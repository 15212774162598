import * as actionTypes from '../actions/actionTypes';

export const clientChangeAction = (meta) => {
  // console.log(meta);
  // const { sessionId, formName } = props;
  return {
    type: actionTypes.FORMENGINE_CLIENT_CHANGE,
    meta: meta
  };
};
export const serverChangeAction = (meta) => {
  const { sessionId, trigger, newValue, data, arrayAction } = meta;

  let url = `/domain/form/change/${sessionId}`;

  return {
    type: actionTypes.FORMENGINE_SERVER_CHANGE,
    request: {
      url: url,
      method: 'post',
      data: { trigger, newValue, data, arrayAction }
    },
    meta: meta
  };
};

export const serverArrayChangeAction = (meta) => {
  return {
    type: actionTypes.FORMENGINE_SERVER_ARRAY_CHANGE,
    meta: meta
  };
};

export const serverFieldChangeAction = (meta) => {
  return {
    type: actionTypes.FORMENGINE_SERVER_FIELD_CHANGE,
    meta: meta
  };
};

export const clientEventAction = (meta) => {
  // console.log(meta);
  // const { sessionId, formName } = props;
  return {
    type: actionTypes.FORMENGINE_CLIENT_EVENT,
    meta: meta
  };
};

export const formEventTriggerAction = (meta) => {
  return {
    type: actionTypes.FORMENGINE_EVENT_TRIGGER,
    meta: meta
  };
};

export const serverEventAction = (meta) => {
  const { sessionId, customEvent, data, buttonName } = meta;

  let url = `/domain/form/customEvent/${sessionId}`;

  return {
    type: actionTypes.FORMENGINE_SERVER_EVENT,
    request: {
      url: url,
      method: 'post',
      data: { customEvent, data, buttonName }
    },
    meta: meta
  };
};

export const serverSubmitActionTrigger = (meta) => {
  return {
    type: actionTypes.FORMENGINE_SUBMIT_EVENT_TRIGGER,
    meta: meta
  };
};

export const serverSubmitActionSend = (meta) => {
  const { sessionId, buttonName, data } = meta;

  let url = `/domain/form/submit/${sessionId}`;

  return {
    type: actionTypes.FORMENGINE_SUBMIT_EVENT_SEND,
    request: {
      url: url,
      method: 'post',
      data: { buttonName, data }
    },
    meta: meta
  };
};

export const formFileUploadAction = (meta) => {
  const { sessionId, trigger, filename, operation } = meta;

  let url = `/domain/form/attachment/${sessionId}`;

  return {
    type: actionTypes.FORMENGINE_FILE_UPLOAD_ACTION,
    request: {
      url: url,
      method: 'post',
      data: { trigger, filename, operation }
    },
    meta: meta
  };
};

export const initializeSession = (props) => {
  // const {
  //   sessionId,
  //   formData,
  //   actions,
  //   triggers,
  //   formId,
  //   lookups,
  //   readOnly,
  //   viewAsHtml
  // } = props;
  return {
    type: actionTypes.FORMENGINE_INITIALIZE,
    meta: { ...props }
  };
};

export const workflowTriggerAction = (meta) => {
  return {
    type: actionTypes.FORMENGINE_WORKFLOW_TRIGGER,
    meta: meta
  };
};
export const workflowTriggerActionSend = (meta) => {
  const { sessionId, data, transitionId, modalConfirmed } = meta;

  let url = `/domain/workflow/transition`;

  return {
    type: actionTypes.FORMENGINE_WORKFLOW_TRIGGER_SEND,
    request: {
      url: url,
      method: 'post',
      data: { sessionId, data, transitionId, modalConfirmed }
    },
    meta: meta
  };
};

export const workflowReloadAction = (objectId, data) => {
  return {
    type: actionTypes.FORMENGINE_WORKFLOW_RELOAD,
    meta: { objectId },
    data
  };
};

// FORM_CHANGE: {
//   URL: '/domain/form/change/{sessionId}',
//     METHOD: 'POST',
//       CONTENT_TYPE: 'application/json; charset=utf-8',
//         WITH_TOKEN: true
// },
// FORM_CUSTOM_EVENT: {
//   URL: '/domain/form/customEvent/{sessionId}',
//     METHOD: 'POST',
//       CONTENT_TYPE: 'application/json; charset=utf-8',
//         WITH_TOKEN: true
// },
