import React from 'react';
import photoA from './../../../assets/img/brand/luxmed//help/1.png';
import photoB from './../../../assets/img/brand/luxmed//help/2.png';
import photoC from './../../../assets/img/brand/luxmed//help/3.png';
import photoD from './../../../assets/img/brand/luxmed//help/4.png';
import photoE from './../../../assets/img/brand/luxmed//help/5.png';
import photoF from './../../../assets/img/brand/luxmed//help/6.png';
import photoG from './../../../assets/img/brand/luxmed//help/7.png';
import photoH from './../../../assets/img/brand/luxmed//help/8.png';

const Pomoc = (props) => {
  return (
    <React.Fragment>
      <h2 className="page-header">
        Instrukcja jak wykupić ubezpieczenie EDU Plus.
      </h2>
      <p>1. Otwórz ofertę na jeden z trzech sposobów: </p>
      <ul>
        <li>kliknij link na ofercie lub </li>
        <li>zeskanuj kod QR lub </li>
        <li>wejdź na stronę: https://klient.interrisk.pl/EduPlusOnline </li>
      </ul>
      <p>Podaj numer( ID Klienta ) uwr3r</p>
      <p>i naciśnij „Sprawdź ofertę”</p>
      <img src={photoA} alt={photoA}></img>
      <p>
        2. Po wybraniu przycisku „Sprawdź ofertę” wyświetli się oferta z
        wariantami ubezpieczenia{' '}
      </p>
      <img src={photoB} alt={photoB}></img>
      <p>................</p>
      <img src={photoC} alt={photoC}></img>
      <p>
        3. Otwórz Ogólne Warunki Ubezpieczenia i zapoznaj się z dokumentem.{' '}
      </p>
      <p>
        4. Zaznacz checkbox o doręczeniu i zapoznaniu się z Ogólnymi Warunkami
        EDU PLUS{' '}
      </p>
      <p>5. Wybierz wariant i wciśnij przycisk „Kup Ubezpieczenie” </p>
      <p>6. </p>

      <img src={photoD} alt={photoD}></img>
      <p>
        Na kolejnym ekranie rodzic lub opiekun prawny dziecka wprowadza swoje
        dane osobowe:
      </p>
      <ul>
        <li>Imię </li>
        <li>nazwisko </li>
        <li>PESEL lub datę urodzenia </li>
        <li>e-mail x2</li>
      </ul>
      <p>Następnie należy przycisnąć „Zapisz”</p>
      <p>7. </p>
      <img src={photoE} alt={photoE}></img>
      <p>
        Zaznacz niezbędne oświadczenia do zawarcia i wykonania umowy
        ubezpieczenia (pierwsze cztery są obowiązkowe) i „Zapisz”
      </p>
      <p>8.</p>
      <img src={photoF} alt={photoF}></img>
      <p>
        Uzupełnij dane ubezpieczonego (dziecka). Możesz wypełnić dane dla kilku
        ubezpieczonych. Po wypełnieniu danych osobowych zatwierdź dane
        przyciskiem „Zapisz” Imię, Nazwisko, kod pocztowy, Miasto, ulica, PESEL
        lub datę urodzenia
      </p>
      <p>9.</p>
      <p>10. </p>
      <img src={photoG} alt={photoG}></img>
      <p>...............</p>
      <img src={photoH} alt={photoH}></img>
      <p>
        Sprawdź dane ubezpieczonych i podany adres e-mail. Na wskazany adres
        e-mail zostanie przesłana dokumentacja.
      </p>
      <p>
        11. Po kliknięciu w przycisk „Przejdź do płatności” następuję
        przekierowanie do systemu płatniczego PayU. Należy dokonać płatności.
        <br />
        Po dokonaniu płatności otrzymasz dokumentację ubezpieczeniową na
        wskazany adres email.
      </p>
    </React.Fragment>
  );
};

export default Pomoc;
