import React from 'react';
import { connect } from 'react-redux';
import classes from './DocumentsPageTemplate.module.scss';
import * as actions from '../../../../store/actions/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import Loading from '../../../../components/Loading/Loading';

import {
  Card,
  // CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  CardFooter
} from 'reactstrap';

class DocumentsPageTemplate extends React.Component {
  render() {
    return (
      <div>
        <h2 className="page-header">{this.props.data.pageName}</h2>
        {this.props.loadingDocuments && <Loading />}
        {this.props.categories.length < 1 && !this.props.loadingDocuments && (
          <div>Brak dokumentów.</div>
        )}

        {this.props.categories.map((category, index) => (
          <Card key={index} className={classes.cardContainer}>
            <CardHeader tag="h5">
              <div className={classes.headerGrid}>
                <div className={classes.headerText}>
                  {category.categoryName}
                </div>
                <div className={classes.fileCount}>
                  {category.files.length} <FontAwesomeIcon icon="paperclip" />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className={classes.cardContainerBody}>
                {category.files.map((file, index) => (
                  <Card key={index}>
                    <div
                      // src={file.fileThumbnailSource}
                      style={{
                        backgroundImage: `url("${file.fileThumbnailSource}")`
                      }}
                      alt="file thumbnail"
                      className={classes.cardImg}
                    />
                    <CardBody>
                      <CardTitle>{file.fileName}</CardTitle>
                      <CardSubtitle>
                        <Moment format="DD.MM.YYYY HH:mm">
                          {file.fileCreated}
                        </Moment>
                      </CardSubtitle>
                      {/* <CardText>{file.extension}</CardText> */}

                      {/* {file.allowedActions.map((action, index) => (
                        <Button
                          key={index}
                          onClick={() => this.props.download(file.fileId)}>
                          {action.displayName}
                        </Button>
                      ))} */}
                    </CardBody>
                    <CardFooter>
                      <Button
                        key={index}
                        onClick={() => this.props.download(file.fileId)}>
                        Pobierz
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const data = state.dynamic.ids[ownProps.guid] || {};
  const categories = data.categories;
  const meta = state.dynamic.meta[ownProps.guid] || {};
  const loadingDocuments = meta.documents && meta.documents.loading === true;
  return {
    data: data,
    categories: categories || [],
    meta,
    loadingDocuments
  };
};

const mapDispatchToProps = (dispatch, props) => {
  //   const pageId = props.match.params[props.queryParam];
  //   const queryStringParams = new URLSearchParams(props.location.search);
  //   const objectId = queryStringParams.get('objectId');

  return {
    download: (fileId) => {
      dispatch(actions.downloadFile(fileId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsPageTemplate);
