import React from 'react';
import InsuranceInfo from './InsuranceInfo';
import BenefitsTable from './BenefitsTable';

import photo1 from './../../../assets/img/brand/CEU_logo.png';
import photo2 from './../../../assets/img/brand/coverholder-lloyds.png';
// import photo3 from './../../../assets/img/brand/71';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-9" style={{}}>
            {/* <p style={{ fontSize: '25px', fontWeight: 'bolder' }}>
              Pracujesz - ZARABIASZ
            </p>
            <p style={{ fontSize: '25px' }}>
              <span style={{ color: 'red' }}>CHOROBA/WYPADEK</span> nie wybiera
            </p>
            <p style={{ fontSize: '25px', fontWeight: 'bolder' }}>
              Nie pracujesz – NIE ZARABIASZ
            </p> */}
            <br />
            <p
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#00ac34'
              }}>
              Jak zabezpieczyć utracone przychody w przypadku choroby lub
              wypadku?
            </p>
            {/* <br />
            <h4 className="secondColor">Mamy dla Ciebie rozwiązanie:</h4>
            <br />
            <br /> */}
          </div>
          <div
            className="d-flex flex-column align-items-center col-md-3"
            style={{}}>
            <div>
              <img style={{ maxWidth: '100px' }} src={photo1} alt={photo1} />
            </div>
            <div>
              <img style={{ maxWidth: '200px' }} src={photo2} alt={photo2} />
            </div>
          </div>
        </div>
      </div>

      <p className="firstColor text-center mt-4" style={{ fontSize: '1.7rem' }}>
        <b>Ubezpieczenie utraty dochodu Lloyd’s</b>
      </p>
      <br />
      <InsuranceInfo />
      <h4 className="secondColor">
        Ryzyko wykonywania Twojego zawodu zostanie objęte ubezpieczeniem
      </h4>
      <BenefitsTable />
    </React.Fragment>
  );
};

export default Ogolne;
