import React from 'react';

const FieldSet = (props) => {
  const { node, parseProps, parseSchema } = props;

  const fieldName = props.node.key;

  let formMeta = props.formMeta[fieldName];
  const hidden = formMeta.hidden.input.value;
  const disabled = formMeta.disabled.input.value;

  if (hidden) return null;

  return (
    <React.Fragment key={node.id}>
      {node.components.map((subNode) =>
        parseSchema({
          ...parseProps,
          node: subNode,
          globalDisable: parseProps.globalDisable || disabled
        })
      )}
    </React.Fragment>
  );
};

export default FieldSet;
