import {
  dates,
  walidatorDziecko,
  walidatorDziecko2,
  walidatorWspolpracownikPlusPartner,
  walidatorDorosleDziecko,
  walidatorSenior
} from './date';
export const validatePesel = (
  value,
  minYears,
  maxYears,
  max67,
  extra = false
) => {
  if (value === '' || !value) return undefined;
  const isNum = /^\d+$/.test(value);

  if (value.length !== 11 || !isNum) {
    return 'Niepoprawny PESEL';
  }

  const pesel = value;
  // http://artpi.pl/?p=8
  // Funkcja dekodujaca nr. Pesel
  // Wycinamy daty z numeru
  var rok = parseInt(pesel.substring(0, 2), 10);
  var miesiac = parseInt(pesel.substring(2, 4), 10);
  var dzien = parseInt(pesel.substring(4, 6), 10);
  // Powszechnie uwaza sie, iz daty w numerach pesel obejmuja tylko ludzi urodzonych do 2000 roku. Na szczescie prawodawcy o tym pomysleli i do miesiaca dodawane sa liczby tak, by pesele starczyly az do 23 wieku.
  if (miesiac > 80) {
    rok = rok + 1800;
    miesiac = miesiac - 80;
  } else if (miesiac > 60) {
    rok = rok + 2200;
    miesiac = miesiac - 60;
  } else if (miesiac > 40) {
    rok = rok + 2100;
    miesiac = miesiac - 40;
  } else if (miesiac > 20) {
    rok = rok + 2000;
    miesiac = miesiac - 20;
  } else {
    rok += 1900;
  }
  // Daty sa ok. Teraz ustawiamy.
  var urodzony = new Date();
  urodzony.setFullYear(rok, miesiac - 1, dzien);

  // Teraz zweryfikujemy numer pesel
  // Metoda z wagami jest w sumie najszybsza do weryfikacji.
  var wagi = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  var suma = 0;

  for (var i = 0; i < wagi.length; i++) {
    suma += parseInt(pesel.substring(i, i + 1), 10) * wagi[i];
  }
  suma = suma % 10;
  var valid = suma === parseInt(pesel.substring(10, 11), 10);

  //plec
  //   if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
  //     var plec = 'm';
  //   } else {
  //     var plec = 'k';
  //   }

  if (!valid) {
    return 'Niepoprawny PESEL';
  }

  if (minYears) {
    let minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - minYears);

    let comparison = dates.compare(urodzony, minDate);

    switch (comparison) {
      case 1:
        return `Wymagany minimalny wiek: ${minYears} lat.`;
      case NaN:
        return 'Zła data';
      case 0:
      case -1:
      default:
        break;
    }
  }
  if (maxYears) {
    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - maxYears);

    let comparison = dates.compare(urodzony, maxDate);

    switch (comparison) {
      case NaN:
        return 'Zła data';
      case 0:
      case -1:
        return `Brak możliwości dodania dziecka którego wiek przekroczył 26 lat, możesz je dodać jako partnera.`;
      case 1:
      default:
        break;
    }
  }
  if (max67) {
    let maxDate = new Date();
    let maxYear = maxDate.getFullYear() - 68;
    maxDate.setMonth(0, 1);
    // maxDate.setMonth(maxDate.getMonth() + 1, 1);
    maxDate.setFullYear(maxYear);

    let comparison = dates.compare(urodzony, maxDate);

    switch (comparison) {
      case NaN:
        return 'Zła data';
      case 0:
      case -1:
        return `Brak możliwości objęcia świadczeniem osób powyżej 67 roku życia.`;
      case 1:
      default:
        break;
    }
  }

  if (extra) {
    if (extra === 'walidatorDziecko') {
      return walidatorDziecko(urodzony);
    } else if (extra === 'walidatorDziecko2') {
      return walidatorDziecko2(urodzony);
    } else if (extra === 'walidatorWspolpracownikPlusPartner') {
      return walidatorWspolpracownikPlusPartner(urodzony);
    } else if (extra === 'walidatorDorosleDziecko') {
      return walidatorDorosleDziecko(urodzony);
    } else if (extra === 'walidatorSenior') {
      return walidatorSenior(urodzony);
    }
  }

  if (valid) {
    return undefined;
  }
};
