import React from 'react';
import { Fields } from 'redux-form';
import { FormGroup, Label, FormFeedback, Row } from 'reactstrap';
// import { DropdownList } from 'react-widgets';
import { SelectList } from 'react-widgets';
// import classes from './Radio.module.scss';

// import { required } from 'redux-form-validators';

const renderSelect = (props) => {
  // console.log(props);
  const {
    // globalDisable,
    label,
    placeholder,
    required,
    clientFormChange,
    serverFormChange,
    values,
    viewAsHtml,
    labelColWidth,
    isArrayField
  } = props;
  const fieldName = props.names[0];
  const globalDisable = props.globalDisable === true;

  let field = props[fieldName];
  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    // console.log('array props? ', props);
    field = props[props.rootKey][props.keyIndex][props.subKey];
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }

  const hidden = formMeta.hidden.input.value;
  const disabled = formMeta.disabled.input.value === true;

  if (hidden) return null;

  const rowColWidth = 12 - labelColWidth;

  const {
    touched,
    error,
    // autofilled,
    // active,
    // pristine,
    // initial,
    warning
  } = field.meta;

  const onChange = (value) => {
    console.log(value);
    field.input.onChange(value);

    clientFormChange({
      fieldName,
      newValue: value,
      oldValue: field.input.value
    });
    serverFormChange({
      trigger: fieldName,
      newValue: value,
      oldValue: field.input.value
    });
  };

  const onBlur = (event) => {
    field.input.onBlur(undefined);
  };

  const data = values.map((value) => ({
    label: value.label,
    _id: value._id
  }));

  // console.log(`Render in ${fieldName}`);

  if (viewAsHtml) {
    // const selectValue = field.input.value.label || '';
    const values = Array.isArray(field.input.value)
      ? field.input.value
      : [{ label: 'Brak.' }];

    return (
      <Row>
        <Label sm={labelColWidth}>{label}</Label>
        <Label sm={rowColWidth} style={{ fontWeight: 'bold' }}>
          <ul>
            {values.map((val) => (
              <li>{val.label}</li>
            ))}
          </ul>
        </Label>
      </Row>
    );
  }

  return (
    <React.Fragment>
      {!hidden && (
        <FormGroup>
          <Label for={fieldName}>
            {label}{' '}
            {required && (
              <span style={{ color: 'red', fontWeight: '500' }}>*</span>
            )}
          </Label>
          <SelectList
            filter
            multiple={true}
            {...field.input}
            data={data}
            valueField="_id"
            textField="label"
            placeholder={placeholder}
            disabled={disabled || globalDisable}
            onChange={onChange}
            onBlur={onBlur}
            value={field.input.value || []}
            containerClassName={touched && !!error ? 'invalid' : null}
          />
          {touched &&
            ((error && (
              <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>
            )) ||
              (warning && (
                <FormFeedback style={{ display: 'block' }}>
                  {warning}
                </FormFeedback>
              )))}
        </FormGroup>
      )}
    </React.Fragment>
  );
};

class Select extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label, values } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label ||
      values !== nextProps.values
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        globalDisable={this.props.globalDisable}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
          // ...values
        ]}
        component={renderSelect}
      />
    );
  }
}

export default Select;
