import { success, error, abort } from 'redux-saga-requests';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  ids: {},
  meta: {},
  fetching: {},
  error: {}
};

const reducer = (state = initialState, action) => {
  //   console.log(action);
  switch (action.type) {
    case actionTypes.FETCH_DYNAMIC_PAGE:
      const currentId = state.ids[action.meta.pageId]
        ? state.ids[action.meta.pageId]
        : {};
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...currentId,
            id: action.meta.pageId,
            config: {
              ...currentId.config,
              sessionId: null
            }
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            id: action.meta.pageId,
            loading: true,
            aborted: false,
            error: false
          }
        }
      };
    //page ID based
    case success(actionTypes.FETCH_DYNAMIC_PAGE):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            ...action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            loading: false,
            aborted: false,
            error: false
          }
        }
      };
    case success(actionTypes.DOCUMENTS_FETCH):
    case success(actionTypes.TABLE_RECORDS_FETCH):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            ...action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };
    case actionTypes.DOCUMENTS_FETCH:
    case actionTypes.TABLE_RECORDS_FETCH:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: true,
              aborted: false,
              error: false
            }
          }
        }
      };
    case error(actionTypes.DOCUMENTS_FETCH):
    case error(actionTypes.TABLE_RECORDS_FETCH):
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: true
            }
          }
        }
      };
    case abort(actionTypes.DOCUMENTS_FETCH):
    case abort(actionTypes.TABLE_RECORDS_FETCH):
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: true,
              error: false
            }
          }
        }
      };
    // object ID based
    case success(actionTypes.DOCUMENTS_BY_OBJECT_ID_FETCH):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.objectId]: {
            ...state.ids[action.meta.objectId],
            ...action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.objectId]: {
            ...state.meta[action.meta.objectId],
            loading: false,
            aborted: false,
            error: false
          }
        }
      };
    case actionTypes.FORMENGINE_WORKFLOW_RELOAD:
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.objectId]: {
            ...state.ids[action.meta.objectId],
            config: {
              ...state.ids[action.meta.objectId].config,
              ...action.data
            }
          }
        }
        // meta: {
        //   ...state.meta,
        //   [action.meta.objectId]: {
        //     ...state.meta[action.meta.objectId],
        //     loading: false,
        //     aborted: false,
        //     error: false
        //   }
        // }
      };
    case error(actionTypes.FETCH_DYNAMIC_PAGE):
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            loading: false,
            error: true,
            aborted: false,
            errorDetails: action.data
          }
        }
      };
    case abort(actionTypes.FETCH_DYNAMIC_PAGE):
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            loading: false,
            error: false,
            aborted: true
          }
        }
      };
    case actionTypes.CLEAR_DYNAMIC_SESSION:
      if (state.ids[action.meta.pageId]) {
        const config = { ...state.ids[action.meta.pageId].config };
        if (config.formConfig) {
          config.formConfig.sessionId = null;
          const { [action.meta.pageId]: removed, ...ids } = state.ids;
          return {
            ...state,
            ids
          };
        } else {
          config.sessionId = null;
        }

        return {
          ...state,
          ids: {
            ...state.ids,
            [action.meta.pageId]: {
              ...state.ids[action.meta.pageId],
              config: config
            }
          }
        };
      }
      return state;
    default:
      return state;
  }
};

export default reducer;
