import React from 'react';
import { withRouter } from 'react-router';
import {
  //   TabContent,
  //   TabPane,
  Nav,
  NavItem,
  NavLink
  //   Card,
  //   Button,
  //   CardTitle,
  //   CardText,
  //   Row,
  //   Col
} from 'reactstrap';
import classes from './TabPageTemplate.module.scss';

class TabPageTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tabIds = this.props.data.config.tabs.map((tab) => tab.pageId);
    this.initialTab = this.tabIds[0];
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (!this.tabIds.includes(this.props.location.hash.substr(1))) {
      this.props.history.replace({
        hash: this.initialTab,
        path: this.props.location.path,
        search: this.props.location.search
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.hash !== prevProps.location.hash &&
      this.props.location.pathname === prevProps.location.pathname
    ) {
      if (!this.tabIds.includes(this.props.location.hash.substr(1))) {
        this.props.history.replace({
          hash: this.initialTab,
          path: this.props.location.path,
          search: this.props.location.search
        });
      }
    }
  }

  handleChange = (value) => {
    this.props.history.replace({
      hash: value,
      path: this.props.location.path,
      search: this.props.location.search
    });
  };

  render() {
    const { tabs } = this.props.data.config;
    const Dynamic = this.props.dynamicComponent;
    // const { currentTab } = this.state;
    return (
      <div className={classes.tabPanel}>
        <div className={classes.tabHeading}>
          <Nav tabs className={classes.navTabs}>
            {tabs.map((tab) => {
              return (
                <NavItem key={tab.nodeId}>
                  <NavLink
                    className={
                      this.props.location.hash.substr(1) === tab.pageId
                        ? `${classes.tab} active`
                        : `${classes.tab}`
                    }
                    onClick={() => this.handleChange(tab.pageId)}>
                    {tab.displayName}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </div>
        <div className={classes.panelBody}>
          {this.props.location.hash !== '' && (
            <Dynamic
              {...this.props}
              guid={this.props.location.hash.substr(1)}
            />
          )}
        </div>
      </div>
    );

    // return (
    //   <React.Fragment>
    //     <AppBar position="static" color="default" className="roundedTop">
    //       <Tabs
    //         value={
    //           this.tabIds.includes(this.props.location.hash.substr(1))
    //             ? this.props.location.hash.substr(1)
    //             : this.state.initialTab
    //         }
    //         onChange={this.handleChange}
    //         indicatorColor="primary"
    //         textColor="primary"
    //         variant="scrollable"
    //         scrollButtons="auto">
    //         {this.props.tabs.map((tab) => {
    //           return (
    //             <Tab
    //               value={tab.pageId}
    //               label={tab.displayName}
    //               key={tab.nodeId}
    //             />
    //           );
    //         })}
    //       </Tabs>
    //     </AppBar>
    //     <Paper className="paper nonRoundedTop">
    //       {this.state.isLoading && <Loading />}
    //       {!this.state.isLoading && (
    //         <DynamicRenderComponent
    //           pageId={this.props.pageId}
    //           relation={this.props.relation}
    //           objectId={this.props.objectId}
    //           data={this.state.data}
    //         />
    //       )}
    //     </Paper>
    //   </React.Fragment>
    // );
  }
}

export default withRouter(TabPageTemplate);
