import React from 'react';
import { Alert, Spinner } from 'reactstrap';
import classes from './Loading.module.scss';

class LoadingMessage extends React.Component {
  constructor(props) {
    super(props);
    this.enableMessage = this.enableMessage.bind(this);

    this.state = {
      displayMessage: false
    };

    this.timer = setTimeout(this.enableMessage, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableMessage() {
    this.setState({ displayMessage: true });
  }

  render() {
    const { displayMessage } = this.state;

    if (!displayMessage) {
      return null;
    }

    const label = this.props.label ? this.props.label : 'Ładowanie danych...';
    const color = this.props.color ? this.props.color : 'primary';

    return (
      <Alert color={color} className={classes.alertLoader}>
        <div className={classes.loadingText}>{label}</div>
        <Spinner color="primary" />
      </Alert>
    );
  }
}

export default LoadingMessage;
