import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import 'react-widgets/lib/scss/react-widgets.scss';
import parseSchema from './FormParser.js';
import * as actions from '../../../store/actions';
import { Form, Modal, ModalBody, Spinner, Alert, Button } from 'reactstrap';
import classes from './FormContainer.module.scss';

class FormRender extends React.Component {
  componentDidMount() {
    this.initialize();
    // window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.initialize();
    } else if (
      prevProps.sessionId !== this.props.sessionId &&
      this.props.sessionId !== undefined
    ) {
      this.initialize();
    }
    if (prevProps.viewAsHtml === !this.props.viewAsHtml) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    this.props.clearSession();
  }

  initialize() {
    if (!this.props.loading && this.props.sessionId) {
      this.props.formInitializeSession();
    }
  }

  render() {
    const props = this.props;
    const {
      handleSubmit,
      // pristine,
      // submitting,
      error,
      change,
      autofill,
      lookups,
      clientFormChange,
      serverFormChange,
      serverSubmitEvent,
      serverArrayFormChange,
      formFileUploadAction,
      clientFormEvent,
      serverFormEvent,
      viewAsHtml,
      workflowTriggers,
      workflowTriggerAction
    } = props;
    const globalDisable =
      props.globalDisable !== undefined ? props.globalDisable : true;

    const formStructure = props.schema.components.map((node) => {
      return parseSchema({
        node,
        change,
        autofill,
        globalDisable,
        viewAsHtml,
        clientFormChange,
        serverFormChange,
        clientFormEvent,
        serverFormEvent,
        serverSubmitEvent,
        serverArrayFormChange,
        formFileUploadAction,
        lookups
      });
    });

    return (
      <div className={classes.formContainer}>
        {props.showLoadingModal && (
          <Modal isOpen={true} centered className={classes.loadingModal}>
            <ModalBody>
              <Spinner
                color="primary"
                style={{ width: '4rem', height: '4rem' }}
              />
            </ModalBody>
          </Modal>
        )}
        <div className={classes.formHeader}>
          <div>
            <h2>{props.title}</h2>
            {/*             <span>
              {`globalDisable: ${globalDisable}, viewAsHtml: ${viewAsHtml}, form: ${
                props.form
              }, sessionId: ${props.sessionId || 'loading'}`}
            </span> */}
          </div>
          {!props.sessionId && (
            <div className={classes.spinner}>
              <Spinner color="primary" />
            </div>
          )}
        </div>
        {error && <Alert color="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit} autoComplete="off">
          <WorkflowTriggerBar
            triggers={workflowTriggers}
            onClick={workflowTriggerAction}
            globalDisable={globalDisable}
          />
          {formStructure}
          <WorkflowTriggerBar
            triggers={workflowTriggers}
            onClick={workflowTriggerAction}
            globalDisable={globalDisable}
          />
          {/* <Button>Submit</Button> */}
        </Form>
      </div>
    );
  }
}

FormRender = reduxForm({
  // enableReinitialize: true
  // updateUnregisteredFields: true,
})(FormRender);

const mapFormStateToProps = (state, props) => {
  const { data, guid, meta } = props;

  const selector = formValueSelector(guid);

  const sessionId =
    props.data.config && props.data.config.sessionId
      ? props.data.config.sessionId
      : undefined;

  const formEngineInstance = state.formEngine.sessionIds[sessionId];

  const formEngineData =
    formEngineInstance && formEngineInstance.formData
      ? formEngineInstance.formData
      : {
          formMeta: {
            ...data.config.formState,
            globalDisable: true
          }
        };

  const formEngineActions =
    formEngineInstance && formEngineInstance.actions
      ? formEngineInstance.actions
      : {};
  const formEngineTriggers =
    formEngineInstance && formEngineInstance.triggers
      ? formEngineInstance.triggers
      : {};
  const formEngineLookups =
    formEngineInstance && formEngineInstance.lookups
      ? formEngineInstance.lookups
      : {};
  const formEngineLoading =
    formEngineInstance && formEngineInstance.loading
      ? formEngineInstance.loading
      : false;
  const formEngineLoadingModal =
    formEngineInstance && formEngineInstance.showLoader
      ? formEngineInstance.showLoader
      : false;

  const settings = data.settings || {};
  const viewAsHtmlFromSetting = settings['form.ViewAsHtml'] === 'true';
  const viewAsHtmlSelector = selector(state, 'formMeta.viewAsHtml');
  const viewAsHtml =
    viewAsHtmlSelector !== undefined
      ? viewAsHtmlSelector
      : viewAsHtmlFromSetting;

  return {
    globalDisable: selector(state, 'formMeta.globalDisable') || false,
    viewAsHtml: viewAsHtml,
    initialValues: formEngineData,
    actions: formEngineActions,
    triggers: formEngineTriggers,
    lookups: formEngineLookups,
    form: guid,
    schema: data.config ? data.config.formSchema : {},
    sessionId: sessionId,
    title: data.pageName,
    config: data.config,
    id: data.id,
    loading: meta.loading,
    formEngineLoading: formEngineLoading,
    showLoadingModal: formEngineLoadingModal
  };
};

const mapFormDispatchToProps = (dispatch, props) => {
  const {
    data,
    guid
    // match
  } = props;

  const sessionId =
    props.data.config && props.data.config.sessionId
      ? props.data.config.sessionId
      : undefined;

  const settings = data.settings || {};
  const readOnly = settings['form.ReadOnly'] === 'true';
  const viewAsHtml = settings['form.ViewAsHtml'] === 'true';

  return {
    formInitializeSession: () => {
      let initialData = {};

      if (data.config.data) {
        const formState = data.config.formState || {};
        initialData = {
          ...data.config.data,
          formMeta: {
            ...formState,
            globalDisable: formState.globalDisable || readOnly,
            viewAsHtml: formState.viewAsHtml || viewAsHtml
          }
        };
      }
      dispatch(
        actions.initializeSession({
          sessionId,
          formData: initialData,
          actions: data.config.actions,
          triggers: data.config.triggers,
          formId: data.id,
          lookups: data.config.lookups,
          readOnly,
          viewAsHtml
        })
      );
    },
    clientFormChange: (meta) => {
      dispatch(
        actions.clientChangeAction({
          sessionId: sessionId,
          formId: guid,
          ...meta
        })
      );
    },
    serverFormChange: (meta) => {
      dispatch(
        actions.serverFieldChangeAction({
          sessionId: sessionId,
          formId: guid,
          ...meta
        })
      );
    },
    serverArrayFormChange: (meta) => {
      dispatch(
        actions.serverArrayChangeAction({
          sessionId: sessionId,
          formId: guid,
          ...meta
        })
      );
    },
    clientFormEvent: (meta) => {
      dispatch(
        actions.clientEventAction({
          sessionId: sessionId,
          formId: guid,
          ...meta
        })
      );
    },
    serverFormEvent: (meta) => {
      dispatch(
        actions.formEventTriggerAction({
          sessionId: sessionId,
          formId: guid,
          ...meta
        })
      );
    },
    formFileUploadAction: (meta) => {
      dispatch(
        actions.formFileUploadAction({
          sessionId: sessionId,
          ...meta
        })
      );
    },
    serverSubmitEvent: (meta) => {
      dispatch(
        actions.serverSubmitActionTrigger({
          sessionId: sessionId,
          formId: guid,
          ...meta
        })
      );
    },
    workflowTriggerAction: (meta) => {
      dispatch(
        actions.workflowTriggerAction({
          sessionId: sessionId,
          formId: guid,
          ...meta
        })
      );
    },
    onSubmit: (values) => {
      console.log('Built in form submission form:', values);

      // refactor to promise if necescarry
      // dispatch(
      //   actions.serverSubmitAction({
      //     sessionId: sessionId,
      //     formId: guid,
      //     data: values,
      //     buttonName: 'submit'
      //   })
      // );
    },
    clearSession: () => {
      dispatch(actions.clearDynamicSession(data.id));
    }
  };
};

export default connect(
  mapFormStateToProps,
  mapFormDispatchToProps
)(FormRender);

const WorkflowTriggerBar = (props) => {
  const { triggers, onClick, globalDisable } = props;
  if (!triggers) return null;
  return (
    <Alert color="secondary">
      {props.triggers.map((trigger, index) => {
        return (
          <Button
            onClick={() =>
              onClick({
                // customEvent: trigger.Name,
                transitionId: trigger.triggerId,
                trigger
              })
            }
            key={index}
            className={classes.workflowButton}
            color="primary"
            disabled={globalDisable}>
            {trigger.buttonDisplayName}
          </Button>
        );
      })}
    </Alert>
  );
};
