import {
  IDLESTATUS_AWAY,
  IDLESTATUS_INACTIVE,
  IDLESTATUS_EXPIRED
} from './constants';

import { logout } from '../../actions/';

export const idleStatusDelay = (idleStatus) => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY) return 30000; // User becomes away after 20 seconds inactivity
  if (idleStatus === IDLESTATUS_INACTIVE) return 270000; // Call database to look up the users delay time
  if (idleStatus === IDLESTATUS_EXPIRED) return 600000; // Log them out after another minute after they enter the inactive status
};

export const activeStatusAction = (dispatch, getState) => {
  // if (logoutTimer) logoutTimer();
};

export const idleStatusAction = (idleStatus) => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY) {
    // console.info('user is away...');
  }
  if (idleStatus === IDLESTATUS_INACTIVE) {
    // console.log('user is inactive');
  }
  if (idleStatus === IDLESTATUS_EXPIRED) {
    dispatch(
      logout({
        reason: 'User inactive',
        text: 'Użytkownik został automatycznie wylogowany.'
      })
    );
  }
};
