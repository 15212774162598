import React from 'react';
import logo from './../../../assets/img/brand/luxmed/interrisk.png';
import { ThirdTable } from './ThirdTable';
import bar from './../../../assets/img/brand/luxmed/barImg.png';
import photo2 from './../../../assets/img/brand/luxmed/photo2.png';

const Przystapienie = (props) => {
  return (
    // <React.Fragment>
    //   <img style={{ maxWidth: '250px' }} src={logo} alt={logo} />
    //   <br />
    //   <br />
    //   <br />
    //   <div className="container">
    //     <div className="row">
    //       <div class="col-md-6">
    //         <h3 className="page-header">
    //           Ubezpieczenie szkolne EDU Plus z opcją Hejt Stop
    //         </h3>
    //         <h4>Wzorowa ochrona nie tylko dla wzorowych uczniów</h4>
    //         <br />
    //         <p>
    //           EDU Plus to atrakcyjna oferta ubezpieczenia dla przedszkolaków,
    //           uczniów, studentów oraz pracowników oświaty.
    //         </p>
    //         <p>
    //           <b>W pakiecie m.in.:</b>
    //         </p>
    //         <li>
    //           Ochrona ubezpieczeniowa w ciągu całego roku szkolnego i wakacji -
    //           365 dni w roku, przez 24h na dobę
    //         </li>
    //         <li>
    //           Hejt Stop - wsparcie psychologiczne, prawne i informatyczne, w
    //           zakresie mowy nienawiści i bezpieczeństwa w sieci
    //         </li>
    //         <li>Telemedycyna</li>
    //         <li>Pakiet Kleszcz</li>
    //         <li>e-Rehabilitacja</li>
    //         <li>Zwrot kosztów wycieczki</li>
    //         {isOrange ||
    //           (isInsurances && (
    //             <li>
    //               Ochrona ubezpieczeniowa w związku z amatorskim i wyczynowym
    //               uprawianiem sportu
    //             </li>
    //           ))}
    //         <br />
    //         <p>
    //           <b>
    //             Zapoznaj się z naszą propozycją i kup ubezpieczenie w 3 krokach:
    //           </b>
    //         </p>
    //         <h4>
    //           <span style={{ color: 'red' }}>1.</span> Wejdź na stronę:
    //           <br />
    //           <a
    //             href={
    //               isOrange || isInsurances
    //                 ? 'https://klient.interrisk.pl/EduPlusOfertaIndywidualna?agent=OEw1SDVG'
    //                 : 'https://klient.interrisk.pl/EduPlusOfertaIndywidualna?agent=OEw1SDVG'
    //             }
    //             target="_blank">
    //             klient.interrisk.pl/EduPlusOnline
    //           </a>
    //         </h4>

    //         <br />
    //         <h4>
    //           <span style={{ color: 'red' }}>2.</span> Pobierz OWU i potwierdź
    //           zapoznanie się z nim zaznaczając odpowiednie zgody
    //         </h4>

    //         <br />

    //         <h4>
    //           <span style={{ color: 'red' }}>3.</span> Wybierz interesujący Cię
    //           wariant, wypełnij dane osobowe, wciśnij przycisk „Kup
    //           Ubezpieczenie” i opłać składkę
    //         </h4>
    //       </div>
    //       <div class="col-md-6">
    //         <img src={photoB} alt="secondPhoto" />
    //       </div>
    //     </div>
    //     <br />
    //     <p>
    //       <small>
    //         Wyłączenia i ograniczenia odpowiedzialności zawarte są w Ogólnych
    //         Warunkach Ubezpieczenia EDU Plus zatwierdzonych uchwałą nr
    //         01/18/04/2023 Zarządu lnterRisk TUS.A. Vienna lnsurance Group z dnia
    //         1Skwietnia 2023r. dostępnych na stronie www.interrisk.pl lnterRisk
    //         Towarzystwo Ubezpieczeń S.A. Vienna lnsurance Group z siedzibą w
    //         Warszawie przy ul. St. Noakowskiego 22, wpisane do rejestru
    //         przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
    //         Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy
    //         Krajowego Rejestru Sądowego pod nr KRS 0000054136, NIP
    //         526-00-38-806, o kapitale zakładowym w kwocie 137.640.100,00 PLN
    //         (opłacony w całości)
    //       </small>
    //     </p>
    //   </div>
    // </React.Fragment>
    <React.Fragment>
      <img style={{ maxWidth: '250px' }} src={logo} alt={logo} />
      <br />
      <br />
      <br />
      {/* <p className="text-justify">
        Szanowni Państwo,
        <br />
        Akcja ubezpieczeń NNW dla dzieci i młodzieży w ramach Oferty dla
        Pracowników i Współpracowników została zakończona z dniem 30 listopada
        2023r. Dla wszystkich zainteresowanych, którzy chcieliby ubezpieczyć
        swoje dzieci od następstw nieszczęśliwych wypadków przygotowaliśmy
        wspólnie z InterRisk możliwość indywidulanego zawierania ubezpieczenia.
      </p> */}
      <h2 className="page-header mt-4">EDU Plus</h2>
      {/* <h4>Wybierz program ochrony ubezpieczeniowej na rok 2023/2024</h4> */}
      <img src={bar} alt={bar} />
      <br />
      <br />
      <div className="container">
        <div class="row">
          <div class="col-md-6">
            {/* <div style={{ border: '1px dashed black', padding: '10px' }}> */}
            {/* <p>
                <b>
                  Oferta grupowego ubezpieczenia szkolnego EDU Plus dla dzieci
                </b>
              </p> */}
            {/* <h4 className="thirdColor">
                {isInsurances ? '' : 'PRACOWNIKÓW I WSPÓŁPRACOWNIKÓW '}
                {isOrange && 'GRUPY ORANGE'}
                {!isOrange && !isInsurances && 'TVP S.A.'}
              </h4> */}
            {/* </div> */}
            <h4>
              <b>Wzorowa ochrona</b>
            </h4>
            <h5>Nie tylko dla wzorowych uczniów</h5>
            <br />
            <p>
              Oferta ubezpieczenia dla osób w wieku <b>do 20. roku życia.</b>
              <br /> Szeroki zakres świadczeń – w pakiecie m. in.:
            </p>
            <br />
            <ul>
              <li>
                <b>Ochrona ubezpieczonego każdego dnia, 24 h/dobę,</b> w
                dowolnym miejscu na świecie
              </li>
              <br />
              <li>
                <b>Hejt Stop –</b> wsparcie psychologiczne, prawne i
                informatyczne, w zakresie mowy nienawiści i bezpieczeństwa w
                sieci.
              </li>
              <br />
              <li>
                <b>Assistance EDU Plus</b> (indywidualne korepetycje, pomoc
                medyczna).
              </li>
              <br />
              <li>
                <b>Wyczynowe uprawianie sportu</b>
              </li>
              <br />
              <li>
                <b>Pakiet Kleszcz</b>
              </li>
              <br />
              <li>
                <b>Zwrot kosztów wycieczki</b>
              </li>
              <br />
              <li>
                <b>Pobyt w szpitalu</b> w związku z chorobą lub nieszczęśliwym
                wypadkiem.
              </li>
              {/* <li>
                <b>Ochrona 365 dni przez 24 h na dobę</b> - ochrona
                ubezpieczeniowa w ciągu całego roku szkolnego i wakacji na
                terenie całego świata
              </li>
              <li>
                <b>SPORT</b> – rekreacyjne i wyczynowe uprawianie sportu w
                zakresie, również za wynagrodzeniem
              </li>
              <li>
                <b>Kumulacja</b> – wypłaty świadczeń z opcji głównej i opcji
                dodatkowych sumują się
              </li>
              <li>
                <b>Dodatkowe świadczenie</b> - w przypadku uszczerbku w wyniku
                NW powyżej 50%{' '}
              </li>
              <li>Brak karencji</li>
              <li>
                <b>Pakiet Kleszcz</b> - pokrycie kosztów badań diagnosty-
                cznych, antybiotykoterapii, usunięcia kleszcza oraz wypłata
                świadczenia za rozpoznanie boreliozy
              </li>
              <li>
                <b>Hejt Stop</b> – wsparcie psychologiczne, prawne i
                informatyczne, w zakresie mowy nienawiści i bezpieczeństwa w
                sieci
              </li>
              <br />
              <li>
                infolinia czynna 24h / 7 dni w tygodniu <b>22 575 25 25</b>
              </li>
              <li>
                szybki , prosty i nowoczesny sposób zgłoszenia szkody on-line na
                stronie{' '}
                <a href="https://interrisk.pl/" target="_blank">
                  <b>www.interrisk.pl</b>
                </a>{' '}
              </li>
              <li>uproszczona procedura wypłaty odszkodowania</li> */}
            </ul>
          </div>
          <div class="col-md-6">
            <img src={photo2} alt="photo2" />
          </div>
          <br />
          <br />
          {/* <p>
            <b>Ofertę przygotował:</b>
            <br />
            <b>II Oddział InterRisk TU SA Vienna Insurance Group w Warszawie</b>
          </p>
          <br />

          <small>
            Oferta przygotowana na podstawie Ogólnych Warunków Ubezpieczenia EDU
            Plus zatwierdzonych uchwałą nr 01/18/04/2023 Zarządu InterRisk TU
            S.A. Vienna Insurance Group z dnia 18 kwietnia 2023r. Wyłączenia i
            ograniczenia odpowiedzialności zawarte są w Ogólnych Warunkach
            Ubezpieczenia EDU Plus zatwierdzonych uchwałą nr 01/18/04/2023
            Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 18 kwietnia
            2023r. dostępnych na stronie www.interrisk.pl
          </small> */}
        </div>
      </div>
      <h3>Wejdź na stronę i kup ubezpieczenie:</h3>

      <a
        href="https://klient.interrisk.pl/EduPlusOfertaIndywidualna?agent=OEw1SDVG"
        target="_blank"
        rel="noopener noreferrer">
        https://klient.interrisk.pl/EduPlusOfertaIndywidualna?agent=OEw1SDVG
      </a>
      <br />
      <br />
      {/* <br />
      <br />
      <h4 className="firstColor">EDU Plus</h4>
      <h5 className="firstColor mb-4">
        WYBIERZ PROGRAM OCHRONY UBEZPIECZENIOWEJ NA ROK 2023/2024
      </h5>
      {isOrange || isInsurances ? <FirstTableOrange /> : <FirstTable />}
      <Regulamin />
      <SecondTable /> */}
      <ThirdTable />
    </React.Fragment>
  );
};

export default Przystapienie;
