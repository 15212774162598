import React from 'react';
import { connect } from 'react-redux';
import classes from './ObjectDocumentsPageTemplate.module.scss';
import * as actions from '../../../../store/actions/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

import {
  Card,
  // CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  CardFooter
} from 'reactstrap';

class ObjectDocumentsPageTemplate extends React.Component {
  render() {
    const { files } = this.props;
    return (
      <div>
        <h2 className="page-header">{this.props.data.pageName}</h2>
        <Card className={classes.cardContainer}>
          <CardHeader tag="h5">
            <div className={classes.headerGrid}>
              <div className={classes.headerText} />
              <div className={classes.fileCount}>
                {files.length} <FontAwesomeIcon icon="paperclip" />
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className={classes.cardContainerBody}>
              {files &&
                files.length > 0 &&
                files.map((file, index) => (
                  <Card key={index}>
                    <div
                      style={{
                        backgroundImage: `url("${file.fileThumbnailSource}")`
                      }}
                      alt="file thumbnail"
                      className={classes.cardImg}
                    />
                    <CardBody>
                      <CardTitle>{file.fileName}</CardTitle>
                      <CardSubtitle>
                        <Moment format="DD.MM.YYYY HH:mm">
                          {file.fileCreated}
                        </Moment>
                      </CardSubtitle>
                      {/* <CardText>{file.extension}</CardText> */}

                      {/* {file.allowedActions.map((action, index) => (
                        <Button
                          key={index}
                          onClick={() => this.props.download(file.fileId)}>
                          {action.displayName}
                        </Button>
                      ))} */}
                    </CardBody>
                    <CardFooter>
                      <Button
                        onClick={() => this.props.print(file.fileId)}
                        className={classes.marginRight}>
                        Drukuj
                      </Button>
                      <Button onClick={() => this.props.download(file.fileId)}>
                        Pobierz
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
              {(!files || (files && files.length < 1)) && (
                <span>Brak dokumentów.</span>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const data = state.dynamic.ids[ownProps.objectId] || {};
  const files = data.files;
  return {
    files: files || [],
    filesMeta: state.dynamic.meta[ownProps.guid] || {}
  };
};

const mapDispatchToProps = (dispatch, props) => {
  //   const pageId = props.match.params[props.queryParam];
  //   const queryStringParams = new URLSearchParams(props.location.search);
  //   const objectId = queryStringParams.get('objectId');

  return {
    download: (fileId) => {
      dispatch(actions.downloadFile(fileId));
    },
    print: (fileId) => {
      dispatch(actions.printPdf(fileId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectDocumentsPageTemplate);
