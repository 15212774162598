import React from 'react';
import { Fields } from 'redux-form';
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Button,
  Alert
} from 'reactstrap';
import classes from './Radio.module.scss';

const renderFields = (props) => {
  const {
    globalDisable,
    viewAsHtml,
    // change,
    // autofill,
    label,
    // type,
    // placeholder,
    required,
    isArrayField,
    values,
    variant,
    clientFormChange,
    serverFormChange,
    serverArrayFormChange
  } = props;
  const fieldName = props.names[0];

  let field = props[fieldName];
  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    field = props[props.rootKey][props.keyIndex][props.subKey];
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }

  const hidden = formMeta.hidden.input.value;
  const disabled = formMeta.disabled.input.value;

  if (hidden) return null;

  const {
    touched,
    error,
    // autofilled,
    // active,
    // pristing,
    // initial,
    warning
  } = field.meta;

  const onClick = (event) => {
    field.input.onChange(event);

    const changeProps = {
      fieldName,
      trigger: fieldName,
      newValue: event.target.value,
      oldValue: field.input.value
    };

    if (isArrayField) {
      changeProps.arrayAction = {
        rootKey: props.rootKey,
        index: props.keyIndex,
        subKey: props.subKey,
        newValue: event.target.value
      };
      changeProps.rootKey = props.rootKey;

      serverArrayFormChange(changeProps);
    } else {
      serverFormChange(changeProps);
    }

    clientFormChange(changeProps);

    field.input.onBlur(undefined);
  };

  const onChange = (event) => {
    event.preventDefault();
  };
  const onBlur = (event) => {
    field.input.onBlur(undefined);
  };

  const isDisabled = disabled || globalDisable;

  if (viewAsHtml) {
    const inputValue =
      values.find((value) => value.value === field.input.value) || {};

    const displayLabel = inputValue.label || field.input.value;

    return (
      <Row>
        <Label sm={3}>{label}</Label>
        <Label sm={9} style={{ fontWeight: 'bold' }}>
          {displayLabel}
        </Label>
      </Row>
    );
  }

  if (variant && variant === 'buttons') {
    return (
      <div>
        {!hidden && (
          <React.Fragment>
            <FormGroup
              tag="div"
              className="btn-group-toggle"
              style={{
                display: 'grid',
                gridAutoFlow: 'column',
                gridColumnGap: '1rem',
                width: 'fit-content',
                margin: '0 auto 1rem auto'
              }}>
              {values.map((node) => {
                const valueMeta = formMeta[node.value];
                const valueHidden = valueMeta.hidden.input.value;
                const valueDisabled =
                  isDisabled || valueMeta.disabled.input.value;

                if (valueHidden) return null;

                const active =
                  node.value === field.input.value ? 'active ' : '';
                const disabledText = valueDisabled
                  ? `text-muted disabled ${classes.buttonDisabled}`
                  : '';

                return (
                  <Button
                    className={`${active}${disabledText}`}
                    tag="label"
                    outline
                    color="primary"
                    size="lg"
                    key={node.value}>
                    <Input
                      {...field.input}
                      type="radio"
                      value={node.value}
                      {...(touched && !!error ? { invalid: true } : {})}
                      // {...(touched && !!!error ? { valid: true } : {})}
                      disabled={valueDisabled}
                      onChange={onChange}
                      onClick={onClick}
                      onBlur={onBlur}
                    />{' '}
                    {node.label}
                  </Button>
                );
              })}
            </FormGroup>
            {touched &&
              ((error && (
                <Alert className={classes.centeredText} color="danger">
                  {error}
                </Alert>
              )) ||
                (warning && (
                  <Alert className={classes.centeredText} color="danger">
                    {warning}
                  </Alert>
                )))}
          </React.Fragment>
        )}
      </div>
    );
  }

  if (variant && variant === 'horizontal') {
    return (
      <div>
        {!hidden && (
          <FormGroup>
            <Label for={fieldName}>
              {label}{' '}
              {required && (
                <span style={{ color: 'red', fontWeight: '500' }}>*</span>
              )}
            </Label>
            <div className={classes.horizontal}>
              {values.map((node) => {
                const valueMeta = formMeta[node.value];
                const valueHidden = valueMeta.hidden.input.value;
                const valueDisabled =
                  isDisabled || valueMeta.disabled.input.value;

                if (valueHidden) return null;

                return (
                  <FormGroup
                    check
                    style={{
                      marginRight: '1rem'
                    }}
                    key={node.value}>
                    <Label check className={valueDisabled ? 'text-muted' : ''}>
                      <Input
                        {...field.input}
                        type="radio"
                        value={node.value}
                        checked={field.input.value === node.value}
                        {...(touched && !!error ? { invalid: true } : {})}
                        // {...(touched && !!!error ? { valid: true } : {})}
                        disabled={valueDisabled}
                        onChange={onClick}
                        onBlur={onBlur}
                      />{' '}
                      {node.label}{' '}
                    </Label>
                  </FormGroup>
                );
              })}
              {touched &&
                ((error && (
                  <FormFeedback style={{ display: 'block' }}>
                    {error}
                  </FormFeedback>
                )) ||
                  (warning && (
                    <FormFeedback style={{ display: 'block' }}>
                      {warning}
                    </FormFeedback>
                  )))}
            </div>
          </FormGroup>
        )}
      </div>
    );
  }

  return (
    <div>
      {!hidden && (
        <FormGroup row>
          <Label for={fieldName} sm={3}>
            {label}{' '}
            {required && (
              <span style={{ color: 'red', fontWeight: '500' }}>*</span>
            )}
          </Label>
          <Col sm={9}>
            {values.map((node) => {
              const valueMeta = formMeta[node.value];
              const valueHidden = valueMeta.hidden.input.value;
              const valueDisabled =
                isDisabled || valueMeta.disabled.input.value;

              if (valueHidden) return null;

              return (
                <FormGroup
                  check
                  row
                  style={{
                    marginBottom: '1rem',
                    marginLeft: '0px'
                  }}
                  key={node.value}>
                  <Label check className={valueDisabled ? 'text-muted' : ''}>
                    <Input
                      {...field.input}
                      type="radio"
                      value={node.value}
                      checked={field.input.value === node.value}
                      {...(touched && !!error ? { invalid: true } : {})}
                      // {...(touched && !!!error ? { valid: true } : {})}
                      disabled={valueDisabled}
                      onChange={onClick}
                      onBlur={onBlur}
                    />{' '}
                    {node.label}{' '}
                  </Label>
                </FormGroup>
              );
            })}
            {touched &&
              ((error && (
                <FormFeedback style={{ display: 'block' }}>
                  {error}
                </FormFeedback>
              )) ||
                (warning && (
                  <FormFeedback style={{ display: 'block' }}>
                    {warning}
                  </FormFeedback>
                )))}
          </Col>
        </FormGroup>
      )}
    </div>
  );
};

class Radio extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label, type } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label ||
      type !== nextProps.type
    ) {
      return true;
    }
    return false;
  }

  render() {
    const flatMap = (a, cb) => [].concat(...a.map(cb));
    const a = this.props.values.map((subNode) => subNode.value);
    const cb = (value) => [
      `formMeta.${this.props.name}.${value}.hidden`,
      `formMeta.${this.props.name}.${value}.disabled`
    ];
    const values = flatMap(a, cb);

    // const values = this.props.values
    //   .map((subNode) => subNode.value)
    //   .flatMap((value) => [
    //     `formMeta.${this.props.name}.${value}.hidden`,
    //     `formMeta.${this.props.name}.${value}.disabled`
    //   ]);

    return (
      <Fields
        {...this.props}
        key="k"
        globalDisable={this.props.globalDisable}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`,
          ...values
        ]}
        component={renderFields}
      />
    );
  }
}

export default Radio;
