import React from 'react';
import FormContainer from '../../../../components/Forms/FormContainer/FormContainer';

class WorkflowPageTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { config, pageName, id } = data;
    const { formConfig, headingConfig, triggers } = config;
    // console.log(this.props);
    const workflowForm = {
      config: formConfig,
      id: id,
      pageName: pageName
    };

    if (headingConfig) {
      workflowForm.pageName = null;
      headingConfig.formState.globalDisable = true;
    }
    const workflowHeading = {
      data: {
        config: headingConfig,
        settings: { 'form.ViewAsHtml': true },
        id: `${id}heading`,
        pageName: pageName
      },
      guid: `${id}heading`
    };

    return (
      <React.Fragment>
        {headingConfig && (
          <FormContainer {...this.props} {...workflowHeading} />
        )}
        <FormContainer
          {...this.props}
          data={workflowForm}
          workflowTriggers={triggers && triggers.length > 0 ? triggers : null}
        />
      </React.Fragment>
    );
  }
}

export default WorkflowPageTemplate;
