import React from 'react';
import icon2 from './../../../assets/icons/icon2.png';
import icon3 from './../../../assets/icons/icon3.png';
import icon4 from './../../../assets/icons/icon4.png';
import icon5 from './../../../assets/icons/icon5.png';
import img1 from './../../../assets/img/brand/21.jpg';
import img2 from './../../../assets/img/brand/22.jpg';
import img3 from './../../../assets/img/brand/23.jpg';
import { Button } from 'reactstrap';

//const isOrange = window.location.hostname === 'orange.uspartner.pl';
//onst isInsurances = window.location.hostname === 'ubezpieczenia.uspartner.pl';
const isNais =
  window.location.hostname === 'nais.uspartner.pl' ||
  window.location.hostname === 'renomia.uspartner.pl';

let axaLinkAssistance =
  'https://www.axa-assistance.pl/ubezpieczenie-pojazdu/?ExternalPartner=13000170';

if (isNais)
  axaLinkAssistance =
    'https://www.axa-assistance.pl/ubezpieczenie-pojazdu/?ExternalPartner=13100174';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h1>Moto assistance - dla podróżujących samochodem</h1>
      <p className="secondColor mt-4">
        Ochrona ubezpieczeniowa w zakresie Ubezpieczenia Moto Assistance jest
        udzielana przez Inter Partner Assistance S.A. Oddział w Polsce z
        siedzibą w Warszawie przy ul. Giełdowej 1, 01-211 Warszawa, KRS
        0000320749 działający pod marką AXA Partners.
      </p>

      <p className="secondColor mt-4">
        To szeroki zakres wsparcia dla podróżujących samochodem po Polsce i
        Europie.
        {/* Kupując ubezpieczenie moto assistance uzyskasz pomoc na drodze
        np. w razie awarii, wypadku czy kradzieży auta,{' '}
        <b>niezależnie od posiadanego OC.</b> */}
      </p>
      <p className="secondColor mt-4">
        <b>Wsparcie udzielamy 24/7</b> wystarczy, że do nas zadzwonisz.
      </p>
      <br />
      {/* <button>Kup online</button> */}
      <div className="container mt-4">
        <div className="row secondColor">
          <div
            className="col-md-3 text-center flex-column"
            style={{ borderRight: '1px solid #a2a2a2b8' }}>
            <div>
              <img src={icon4} alt="icon" />
            </div>
            <span>Naprawa pojazdu na miejscu zdarzenia</span>
          </div>
          <div
            className="col-md-3 text-center flex-column"
            style={{ borderRight: '1px solid #a2a2a2b8' }}>
            <img src={icon3} alt="icon" /> <div></div>
            <span>Holowanie pojazdu nawet do 1000 km</span>
          </div>
          <div
            className="col-md-3 text-center flex-column"
            style={{ borderRight: '1px solid #a2a2a2b8' }}>
            <div>
              {' '}
              <img src={icon2} alt="icon" />{' '}
            </div>
            <span>Transport ubezpieczonego</span>
          </div>
          <div className="col-md-3 text-center flex-column">
            <div>
              <img src={icon5} alt="icon" />{' '}
            </div>
            <span>Wynajem i dostarczenie samochodu zastępczego</span>
          </div>
        </div>
      </div>
      <br />
      <h3 className="mt-5 text-center">
        Dostępne warianty ubezpieczenia <b>moto assistance</b>
        <br /> Polska i Europa:
      </h3>
      <br />
      <div style={{ background: '#fafafa' }}>
        <table className="table text-center">
          <tr style={{ backgroundColor: '#b5d0ee' }}>
            <th></th>
            <th>Premium 1000</th>
            <th>Comfort</th>
            <th>Standard</th>
          </tr>
          <tr>
            <th className="firstColor">Naprawa na miejscu zdarzenia</th>
            <th>
              {' '}
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
          </tr>
          <tr>
            <th className="firstColor">Holowanie pojazdu</th>
            <th style={{ color: '#7ec400' }}>1.000 km</th>
            <th className="firstColor">500 km</th>
            <th className="firstColor">300 km</th>
          </tr>
          <tr>
            <th className="firstColor">Postój pojazdu na parkingu</th>
            <th style={{ color: '#7ec400' }}>4 doby</th>
            <th className="firstColor">4 doby</th>
            <th className="firstColor">2 doby</th>
          </tr>
          <tr>
            <th className="firstColor">Transport ubezpieczonego</th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
          </tr>
          <tr>
            <th className="firstColor">Zakwaterowanie ubezpieczonego</th>
            <th style={{ color: '#7ec400' }}>4 doby</th>
            <th className="firstColor">4 doby</th>
            <th className="firstColor">3 doby</th>
          </tr>
          <tr>
            <th className="firstColor">Samochód zastępczy</th>
            <th style={{ color: '#7ec400' }}>5 dób</th>
            <th className="firstColor">5 dób</th>
            <th className="firstColor">2 doby</th>
          </tr>
          <tr>
            <th className="firstColor">Wymiana kół</th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
          </tr>
          <tr>
            <th className="firstColor">Pomoc w przypadku braku paliwa</th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
          </tr>
          <tr className="firstColor">
            <th>Pomoc tłumacza</th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
            <th>
              <span>&#10004;</span>
            </th>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="container" style={{ backgroundColor: '#f1f5f9' }}>
        <div className="row">
          <div className="col-md-4 ">
            <div className="">
              <div className="text-center">
                <img src={icon4} alt="icon" />
                <h3 className="mt-4 mb-4">Standard</h3>
              </div>{' '}
              <ul className="secondColor">
                <li>
                  Jeśli planujesz <b>krótki</b> wyjazd do krajów ościennych,
                  wariant „Standard” będzie dla Ciebie najlepszy.
                </li>{' '}
                <li>
                  <b>Limit na holowanie - 300 km</b>, 2 doby postoju na parkingu
                  strzeżonym, 3 dni zakwaterowania ubezpieczonego w hotelu na
                  czas naprawy samochodu, oraz samochód zastępczy na 2 dni.{' '}
                </li>{' '}
                <li>
                  Ubezpieczenie obejmuje również naprawę na miejscu zdarzenia,
                  transport ubezpieczonego, wymianę kół, dowóz paliwa, pomoc
                  tłumacza, specjalistyczne wsparcie ekspertów dotyczące
                  sposobów postępowania w przypadku unieruchomienia pojazdu.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="special">
              <div className="text-center">
                {' '}
                <img src={icon4} alt="icon" />{' '}
                <h3 className="mt-4 mb-4" style={{ color: '#f07662' }}>
                  Comfort
                </h3>
              </div>

              <ul className="secondColor">
                <li>
                  Planujesz spokojnie <b>zwiedzanie Europy?</b> Dzięki
                  wariantowi „Comfort” zapewnimy Ci bezpieczeństwo Twoich
                  podróży.
                </li>
                <li>
                  <b>Limit na holowanie - aż 500 km,</b> 4 doby postoju na
                  parkingu strzeżonym, 4 dni zakwaterowania w hotelu dla
                  ubezpieczonego (na czas napraw samochodu) oraz samochód
                  zastępczy na 5 dni.{' '}
                </li>
                <li>
                  Ubezpieczenie obejmuje również naprawę na miejscu zdarzenia,
                  transport ubezpieczonego, wymianę kół, dowóz paliwa, pomoc
                  tłumacza, specjalistyczne wsparcie ekspertów dotyczące
                  sposobów postępowania w przypadku unieruchomienia pojazdu.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div className="text-center">
                {' '}
                <img src={icon4} alt="icon" />{' '}
                <h3 className="mt-4  mb-4">Premium 100</h3>
              </div>

              <ul className="secondColor">
                <li>
                  Zbliża się <b>długa wyprawa</b> autem? Ten pakiet zapewni Ci
                  najpełniejszą ochronę podczas wyjazdu.{' '}
                </li>
                <li>
                  <b>Najwyższy limit na holowanie - aż 1000 km,</b> 4 doby
                  postoju na parkingu, 4 dni zakwaterowania ubezpieczonego oraz
                  samochód zastępczy na 5 dni.{' '}
                </li>
                <li>
                  Ubezpieczenie obejmuje również naprawę na miejscu zdarzenia,
                  transport ubezpieczonego, wymianę kół, pomoc w przypadku braku
                  paliwa, a nawet pomoc tłumacza.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <a href={axaLinkAssistance} target="_blank" rel="noopener noreferrer">
          <Button color="danger" className="btn-lg">
            Kup ubezpieczenie
            <br />
          </Button>
          <br />
        </a>
      </div>
      {/* <br />
      <Alert style={{ width: '30%', margin: '0 auto', textAlign: 'center' }}>
        Kod rabatowy: <b>1234</b>
      </Alert> */}
      <br />
      <br />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <img src={img3} alt="img" style={{ width: '100%' }} />{' '}
          </div>
          <div className="col-md-6">
            <h3>Dlaczego warto kupić moto assistance?</h3>
            <p className="text-justify">
              Jeśli podróżujesz samochodem po Polsce i Europie, świadczenie moto
              assistance zapewni Ci pomoc w przypadku zdarzeń, które zaszły w
              czasie podróży: awarii pojazdu, wypadku, kradzieży pojazdu,
              uszkodzenia ogumienia lub braku paliwa. Nie musisz się martwić o
              wyszukiwanie najbliższego warsztatu, dogadywać na migi z
              obcojęzycznym mechanikiem czy obawiać się kosmicznych rachunków za
              holowanie. Z moto assistance masz jeden problem z głowy.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-5">
            <h3>Jak pomagamy?</h3>
            <span>
              <b>Oferujemy szeroki zakres usług:</b>
            </span>
            <ul>
              <li>naprawa samochodu w miejscu jego unieruchomienia</li>
              <li>
                holowanie auta, gdy niemożliwa będzie naprawa na miejscu jego
                unieruchomienia (nawet 1000 km w pakiecie Premium 1000)
              </li>
              <li>wymiana koła na zapasowe lub naprawa ogumienia na drodze</li>
              <li>dowóz paliwa, gdy zabraknie nam w trakcie podróży</li>
            </ul>
          </div>
          <div className="col-md-6 mt-5">
            <img src={img2} alt="img2" style={{ width: '100%' }} />{' '}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-5">
            <img src={img1} alt="img1" style={{ width: '100%' }} />{' '}
          </div>
          <div className="col-md-6 mt-5">
            <h4>Zapewniamy</h4>
            <ul>
              <li>
                wynajem i dostarczenie samochodu zastępczego (na 5 dni w
                pakietach Comfort i Premium 1000)
              </li>
              <li>
                zakwaterowanie (nocleg ze śniadaniem w hotelu do 3***) dla osób
                podróżujących na czas naprawy (nawet 4 dni w pakiecie Premium
                1000
              </li>
              <li>postój pojazdu na parkingu (parking strzeżony)</li>
              <li>
                transport powrotny do miejsca zamieszkania lub pierwotnego
                miejsca przeznaczenia
              </li>
              <li>
                telefoniczną pomoc tłumacza umożliwiającą porozumienie się m.in.
                ze służbami medycznymi, urzędnikami, policją, strażą graniczną
                lub innymi osobami.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h6 className="mt-5 firstColor">
        {/* <b>
          Najczęściej zadawane pytania dotyczące moto assistance znajdziesz{' '}
          <a href="https://www.axa-assistance.pl/faq/" target="_blank">
            tutaj.
          </a>
        </b> */}
        Pełne informacje na temat ochrony ubezpieczeniowej oraz jej wyłączeń
        znajdują się w{' '}
        <a
          href="/public-assets/axa/Ogolne_warunki_ubezpieczenia.pdf"
          target="_blank">
          Ogólnych Warunkach Ubezpieczenia
        </a>
      </h6>
    </React.Fragment>
  );
};

export default Ogolne;
