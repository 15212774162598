import React from 'react';
import GoogleMapsUSP from './../majatkowe/GoogleMapsUSP';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CeuForm from './CeuForm';

const Public = (props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfwmGMhAAAAAK4YxJ0Kdx3dASJum_bvYSWUE_jJ">
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <CeuForm />
            </div>
            <div className="col-md-6">
              <GoogleMapsUSP />
            </div>
          </div>
        </div>
      </React.Fragment>
    </GoogleReCaptchaProvider>
  );
};

export default Public;
