import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import { setAuthRedirectPath } from '../../store/actions';

import classes from './ActivationExpired.module.scss';
import ActivationExpiredForm from '../../components/Forms/ActivationExpiredForm/ActivationExpiredForm';

import { Row, Col, Alert } from 'reactstrap';

let ActivationExpired = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to={props.authRedirectPath} />;
  } else {
    const query = new URLSearchParams(props.location.search);

    const requestSent = query.get('requestSent');
    if (requestSent) {
      return (
        <Row>
          <Col sm="10" md="8" lg="6" xl="5" className={classes.centered}>
            <Alert color="success" fade={false}>
              Link aktywacyjny został ponownie wysłany na podany email. Prosimy
              sprawdzić skrzynkę.
            </Alert>
          </Col>
        </Row>
      );
    }

    let form = <ActivationExpiredForm />;

    return (
      <Row>
        <Col sm="10" md="8" lg="6" xl="5" className={classes.centered}>
          {form}
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     setRedirect: () =>
//       dispatch(setAuthRedirectPath(props.location.state.referrer))
//   };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(ActivationExpired);
