import * as actionTypes from '../actions/actionTypes';

// export const fetchLetterTemplates = (pageId) => ({
//   type: actionTypes.LETTER_TEMPLATES_FETCH,
//   request: {
//     url: `/domain/script/webservice/GetLetterTemplates`,
//     method: 'post',
//     data: {}
//   },
//   meta: {
//     pageId
//   }
// });

export const generateLetterTemplate = ({ letterId, objectId = '' }) => ({
  type: actionTypes.LETTER_GENERATE_TEMPLATE,
  request: {
    url: `/DocumentProcessing/Letter/GenerateTemplate/${letterId}/${objectId}`,
    method: 'get'
  },
  meta: {
    letterId,
    objectId,
    asPromise: true
  }
});

export const generateLetterPdf = (data) => ({
  type: actionTypes.LETTER_GENERATE_PDF,
  request: {
    url: `/DocumentProcessing/Letter/GeneratePdfFromTemplate`,
    method: 'post',
    responseType: 'blob',
    data
  },
  meta: {
    ...data,
    asPromise: true
  }
});

export const sendLetterByEmail = (data) => ({
  type: actionTypes.LETTER_SEND_BY_EMAIL,
  request: {
    url: `/DocumentProcessing/Letter/SendTemplateByEmail`,
    method: 'post',
    data
  },
  meta: {
    ...data,
    asPromise: true
  }
});
