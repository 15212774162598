import React from 'react';

export const FourthTable = () => {
  return (
    <div className="container my-5">
      <table className="table table-bordered table-striped text-center">
        <thead>
          <tr className="table-primary">
            <th>ZAKRES UBEZPIECZENIA</th>
            <th>Wariant I</th>
            <th>Wariant II</th>
            <th>Wariant III</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Opcja Podstawowa / Suma Ubezpieczenia (SU)</td>
            <td>30 000 zł</td>
            <td>50 000 zł</td>
            <td>100 000 zł</td>
          </tr>
          <tr className="table-primary">
            <td>
              <b>RODZAJ ŚWIADCZENIA</b>
            </td>
            <td colSpan={3}>
              <b>WYSOKOŚĆ ŚWIADCZENIA (%)</b>
            </td>
          </tr>
          <tr>
            <td>
              Śmierć Ubezpieczonego w wyniku NW (za NW uważa się również zawał
              serca i udar mózgu)
            </td>
            <td>30 000 zł</td>
            <td>50 000 zł</td>
            <td>100 000 zł</td>
          </tr>
          <tr>
            <td>
              Śmierć Ubezpieczonego w wyniku NW na terenie placówki oświatowej
              (świadczenie skumulowane ze świadczeniem za śmierć Ubezpieczonego
              w wyniku NW)
            </td>
            <td>120 000 zł</td>
            <td>200 000 zł</td>
            <td>400 000 zł</td>
          </tr>
          <tr>
            <td>
              Uszczerbek na zdrowiu w wyniku NW (1% SU za 1% uszczerbku na
              zdrowiu, maks. 100% SU) Uszczerbek na zdrowiu ustalany na
              podstawie Tabeli Norm Uszczerbku na Zdrowiu InterRisk– Załącznik
              nr 3 OWU EDU Plus
            </td>
            <td>300 zł / 30 000 zł</td>
            <td>500 zł / 50 000 zł</td>
            <td>1 000 zł / 100 000 zł</td>
          </tr>
          <tr>
            <td>
              Koszty nabycia wyrobów medycznych wydawanych na zlecenie oraz
              koszty zakupu lub naprawy okularów korekcyjnych lub aparatu
              słuchowego lub aparatu ortodontycznego lub pompy insulinowej
              uszkodzonych w wyniku NW na terenie placówki oświatowej
            </td>
            <td>
              do 9 000 zł (w tym okulary, aparat słuchowy, itp. do 300 zł)
            </td>
            <td>
              do 15 000 zł (w tym okulary, aparat słuchowy, itp. do 300 zł)
            </td>
            <td>
              do 30 000 zł (w tym okulary, aparat słuchowy, itp. do 300 zł)
            </td>
          </tr>
          <tr>
            <td>
              Koszty przekwalifikowania zawodowego osób niepełnosprawnych w
              wyniku NW
            </td>
            <td>do 9 000 zł</td>
            <td>do 15 000 zł</td>
            <td>do 30 000 zł</td>
          </tr>
          <tr>
            <td>
              Uszczerbek na zdrowiu w wyniku ataku padaczki (jednorazowe
              świadczenie, warunek: padaczka rozpoznana po raz pierwszy w
              okresie ubezpieczenia)
            </td>
            <td>300 zł</td>
            <td>500 zł</td>
            <td>1 000 zł</td>
          </tr>
          <tr>
            <td>
              Rozpoznanie sepsy u Ubezpieczonego będącego dzieckiem (jednorazowe
              świadczenie, warunek: sepsa rozpoznana po raz pierwszy w okresie
              ubezpieczenia)
            </td>
            <td>3 000 zł</td>
            <td>5 000 zł</td>
            <td>5 000 zł</td>
          </tr>
          <tr>
            <td>
              Śmierć opiekuna prawnego lub rodzica Ubezpieczonego w wyniku NW
              (wypłata za 2 zdarzenia w okresie ubezpieczenia)
            </td>
            <td>3 000 zł</td>
            <td>5 000 zł</td>
            <td>10 000 zł</td>
          </tr>
          <tr>
            <td>
              Pogryzienie przez psa (bez wymogu pobytu w szpitalu – jednorazowe
              świadczenie)
            </td>
            <td>300 zł</td>
            <td>500 zł</td>
            <td>1 000 zł</td>
          </tr>
          <tr>
            <td>
              Pokąsania, ukąszenia (wymóg pobytu w szpitalu 2 dni – jednorazowe
              świadczenie)
            </td>
            <td>600 zł</td>
            <td>1 000 zł</td>
            <td>2 000 zł</td>
          </tr>
          <tr>
            <td>
              Wstrząśnienia mózgu w wyniku NW (wymóg pobytu w szpitalu min. 3
              dni)
            </td>
            <td>300 zł</td>
            <td>500 zł</td>
            <td>1 000 zł</td>
          </tr>
          <tr>
            <td>
              Zatrucie pokarmowe, nagłe zatrucie gazami bądź porażenie prądem
              lub piorunem (wymóg pobytu w szpitalu 3 dni) – jednorazowe
              świadczenie
            </td>
            <td>600 zł</td>
            <td>1 000 zł</td>
            <td>2 000 zł</td>
          </tr>
          <tr>
            <td>
              Rozpoznanie u Ubezpieczonego chorób odzwierzęcych (bąblowicy,
              toksoplazmozy, wścieklizny) – jednorazowe świadczenie
            </td>
            <td>1 500 zł</td>
            <td>2 500 zł</td>
            <td>5 000 zł</td>
          </tr>
          <tr>
            <td>Koszty akcji poszukiwawczej dziecka</td>
            <td>do 3 000 zł</td>
            <td>do 5 000 zł</td>
            <td>do 10 000 zł</td>
          </tr>
          <tr>
            <td>
              Opcja Hejt Stop (Usługi wsparcia informatycznego, psychologicznego
              oraz prawnego w zakresie mowy nienawiści i bezpieczeństwa w sieci)
            </td>
            <td>
              <b>TAK</b>
            </td>
            <td>
              <b>TAK</b>
            </td>
            <td>
              <b>TAK</b>
            </td>
          </tr>

          <tr className="table-primary">
            <td>
              <b>Rozszerzenie o Opcje Dodatkowe</b>
            </td>
            <td colSpan={3}>
              <b>WYSOKOŚĆ ŚWIADCZENIA (%)</b>
            </td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D1 – Śmierć Ubezpieczonego w następstwie wypadku
              komunikacyjnego (świadczenie łączne wraz ze świadczeniem za śmierć
              w wyniku NW)
            </td>
            <td>60 000 zł</td>
            <td>100 000 zł</td>
            <td>160 000 zł</td>
          </tr>
          <tr>
            <td>Opcja Dodatkowa D2 – Oparzenia i odmrożenia</td>
            <td>
              II stopień – 600 zł
              <br />
              III stopień – 1 800 zł
              <br />
              IV stopień – 3 000 zł
            </td>
            <td>
              II stopień – 600 zł
              <br />
              III stopień – 1 800 zł
              <br />
              IV stopień – 3 000 zł
            </td>
            <td>
              II stopień – 600 zł
              <br />
              III stopień – 1 800 zł
              <br />
              IV stopień – 3 000 zł
            </td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D3 – Pobyt w szpitalu w wyniku NW (świadczenie
              przysługuje od 1 dnia pobytu, pod warunkiem pobytu w szpitalu
              trwającego min. 2 dni)
            </td>
            <td>30 zł/dzień</td>
            <td>40 zł/dzień</td>
            <td>50 zł/dzień</td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D4 – Pobyt w szpitalu w wyniku choroby
              (świadczenie przysługuje od 3 dnia pobytu w szpitalu)
            </td>
            <td>30 zł/dzień</td>
            <td>40 zł/dzień</td>
            <td>50 zł/dzień</td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D5 – Poważne Choroby (nowotwór złośliwy, paraliż,
              niewydolność nerek, poliomyelitis, utrata wzroku, utrata mowy,
              utrata słuchu, anemia aplastyczna, stwardnienie rozsiane, cukrzyca
              typu I, niewydolność serca, wada wrodzona serca, zapalenie opon
              mózgowo–rdzeniowych, transplantacja głównych organów)
            </td>
            <td>3 000 zł</td>
            <td>3 500 zł</td>
            <td>4 000 zł</td>
          </tr>
          <tr>
            <td>Opcja Dodatkowa D6 – Operacje w wyniku NW</td>
            <td>do 1 000 zł (zgodnie z Tab.nr 8 OWU)</td>
            <td>do 1 000 zł (zgodnie z Tab.nr 8 OWU)</td>
            <td>do 1 000 zł (zgodnie z Tab.nr 8 OWU)</td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D8 – Koszty leczenia w wyniku nieszczęśliwego
              wypadku
            </td>
            <td>
              do 3 000 zł za wyjątkiem kosztów: <br />• rehabilitacji do 1 500
              zł <br />• operacji plastycznych do 6 000 zł
            </td>
            <td>
              do 3 500 zł za wyjątkiem kosztów: <br />• rehabilitacji do 1 750
              zł <br />• operacji plastycznych do 7 000 zł
            </td>
            <td>
              do 5 000 zł za wyjątkiem kosztów: <br />• rehabilitacji do 2 500
              zł <br />• operacji plastycznych do 10 000 zł
            </td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D10 – Koszty leczenia stomatologicznego w wyniku
              NW
            </td>
            <td>do 1 000 zł</td>
            <td>do 1 000 zł</td>
            <td>do 1 000 zł</td>
          </tr>
          <tr>
            <td>Opcja Dodatkowa D11 – Uciążliwe leczenie w wyniku NW</td>
            <td>200 zł</td>
            <td>200 zł</td>
            <td>200 zł</td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D12 – Assistance EDU PLUS (indywidualne
              korepetycje, pomoc medyczna)
            </td>
            <td>5 000 zł</td>
            <td>5 000 zł</td>
            <td>5 000 zł</td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D16 – Pakiet KLESZCZ i rozpoznanie boreliozy
            </td>
            <td>
              1 500 zł w tym: <br />• świadczenie w wysokości 1 000 zł w
              przypadku rozpoznania boreliozy <br />• zwrot kosztów do 150 zł –
              usunięcie kleszcza <br />• zwrot kosztów do 150 zł – badania
              diagnostyczne potwierdzające lub wykluczające zakażanie boreliozą{' '}
              <br />• zwrot kosztów do 200 zł – antybiotykoterapia zalecona
              przez lekarza
            </td>
            <td>
              1 500 zł w tym: <br />• świadczenie w wysokości 1 000 zł w
              przypadku rozpoznania boreliozy <br />• zwrot kosztów do 150 zł –
              usunięcie kleszcza <br />• zwrot kosztów do 150 zł – badania
              diagnostyczne potwierdzające lub wykluczające zakażanie boreliozą{' '}
              <br />• zwrot kosztów do 200 zł – antybiotykoterapia zalecona
              przez lekarza
            </td>
            <td>
              1 500 zł w tym: <br />• świadczenie w wysokości 1 000 zł w
              przypadku rozpoznania boreliozy <br />• zwrot kosztów do 150 zł –
              usunięcie kleszcza <br />• zwrot kosztów do 150 zł – badania
              diagnostyczne potwierdzające lub wykluczające zakażanie boreliozą{' '}
              <br />• zwrot kosztów do 200 zł – antybiotykoterapia zalecona
              przez lekarza
            </td>
          </tr>
          <tr>
            <td>
              Opcja Dodatkowa D17 – Koszty opłaconej wycieczki szkolnej po NW
            </td>
            <td>do 500 zł</td>
            <td>do 500 zł</td>
            <td>do 500 zł</td>
          </tr>
          <tr>
            <td>
              <b>
                Wyczynowe uprawianie sportu <u>dla osób do 20 roku życia:</u>
              </b>
            </td>
            <td>
              <b>TAK</b>
            </td>
            <td>
              <b>TAK</b>
            </td>
            <td>
              <b>TAK</b>
            </td>
          </tr>
          <tr className="table-primary">
            <td>
              <b>SKŁADKA ROCZNA ZA OSOBĘ:</b>
            </td>
            <td>
              <b>76,00 zł</b>
            </td>
            <td>
              <b>139,00 zł</b>
            </td>
            <td>
              <b>219,00 zł</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
