import React from 'react';

const InsuranceInfo = () => {
  return (
    <div className="container secondColor">
      <h3 className=""> Przedmiot ubezpieczenia:</h3>
      <hr />

      <h5>w zależności od wybranego zakresu jest utrata dochodu z powodu:</h5>
      <ul>
        <li>
          <b>Czasowej całkowitej</b> niezdolności do wykonywania ubezpieczonego
          zawodu w wyniku nieszczęśliwego wypadku lub zachorowania
        </li>
        <li>
          <b>Trwałej całkowitej</b> niezdolności do wykonywania ubezpieczonego
          zawodu w wyniku nieszczęśliwego wypadku lub zachorowania
        </li>
      </ul>
      <br />
      <h5 className="">
        na indywidualny wniosek może być również dodatkowe świadczenie z tytułu:
      </h5>
      <ul>
        <li>
          <b>Śmierci</b> w wyniku nieszczęśliwego wypadku
        </li>
        <li>
          <b>Inwalidztwa</b>
        </li>{' '}
        w wyniku nieszczęśliwego wypadku
        <li>
          <b>Dodatkowych ryzyk: </b> Pobytu w szpitalu, śpiączki, uszczerbku na
          zdrowiu w wyniku nieszczęśliwego wypadku
        </li>
      </ul>
      <br />
      <h3>Dlaczego warto?</h3>
      <hr />

      <ul>
        <li>Uproszczona procedura zawarcia ubezpieczenia</li>
        <li>Umowa zawierana na rok</li>
        <li>Bez badań lekarskich, bez skomplikowanych ankiet medycznych</li>
        <li>Dokument ubezpieczenia przesyłany mailowo</li>
        <li>Ochrona uruchamiana natychmiast po opłaceniu 1-szej składki</li>
      </ul>
      <br />
      <h3>Pracujesz w branży IT jako:</h3>

      <hr />
      <p>
        Informatyk, programista komputerowy, programista aplikacji, architekt
        danych, project manager, web developer, grafik komputerowy, konsultant
        SAP, analityk biznesowy, Chief technical officer (CTO), Chief
        information officer (CIO), inny…
      </p>
      <br />
      <br />
    </div>
  );
};

export default InsuranceInfo;
