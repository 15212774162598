import React from 'react';

const BenefitsTable = () => {
  return (
    <div>
      <hr />
      <br />
      <table className="table  table-condensed table-bordered">
        <tr
          className="thead firstColor textcenter"
          style={{ fontWeight: 'bolder' }}>
          <td rowSpan={2} className="align-middle">
            Lp.
          </td>
          <td
            colSpan={2}
            rowSpan={2}
            className="text-center align-middle"
            style={{ fontSize: '22px' }}>
            ŚWIADCZENIA
          </td>
          <td>Program nr 1</td>
          <td>Program nr 2</td>
          <td>Program nr 3</td>
        </tr>
        <tr>
          <td>
            <small className="align-middle text-center">
              średnie przychody m-czne za okres ostatnich 12 miesięcy w
              wysokości <b>min. 7.800 ZŁ</b>
            </small>
          </td>
          <td>
            <small className="align-middle text-center">
              średnie przychody m-czne za okres ostatnich 12 miesięcy w
              wysokości <b>min. 15.400 ZŁ</b>
            </small>
          </td>
          <td></td>
        </tr>{' '}
        <tr>
          <td>
            <b>1</b>
          </td>
          <td>
            <b className="firstColor">
              Czasowa całkowita niezdolność do pracy w wyniku nieszczęśliwego
              wypadku
            </b>
            <br />{' '}
            <small>
              {' '}
              (nie może przekroczyć 65% kwoty stanowiącej 1/12 Ubezpieczonego
              przychodu za okres 12 miesięcy bezpośrednio poprzedzających
              zawarcie Umowy ubezpieczenia)
            </small>
          </td>
          <td>
            <small className="align-middle text-center">
              świadczenie miesięczne okres wypłaty świadczeń - 12 miesięcy okres
              oczekiwania - 14 dni
            </small>
          </td>
          <td className="align-middle text-center">5 000 zł</td>
          <td className="align-middle text-center">10 000 zł</td>
          <td></td>
        </tr>{' '}
        <tr>
          <td>
            <b>1A</b>
          </td>
          <td>
            <b className="firstColor">
              Czasowa całkowita niezdolność do pracy w wyniku choroby
            </b>
            <br />
            <small>
              {' '}
              (nie może przekroczyć 65% kwoty stanowiącej 1/12 Ubezpieczonego
              przychodu za okres 12 miesięcy bezpośrednio poprzedzających
              zawarcie Umowy ubezpieczenia) opcja dostępna z czasową całkowitą
              niezdolnością wskutek wypadku
            </small>
          </td>
          <td>
            <small className="align-middle text-center">
              świadczenie miesięczne okres wypłaty świadczeń - 12 miesięcy okres
              oczekiwania - 21 dni
            </small>
          </td>
          <td className="align-middle text-center">5 000 zł</td>
          <td className="align-middle text-center">10 000 zł</td>
          <td></td>
        </tr>{' '}
        <tr>
          <td>
            <b>2</b>
          </td>
          <td>
            <b className="firstColor">
              Trwała całkowita niezdolność do pracy w wyniku nieszczęśliwego
            </b>
            wypadku
            <br />
            <small>
              {' '}
              (nie może przekroczyć 10-krotności Ubezpieczonego przychodu za
              okres 12 miesięcy bezpośrednio poprzedzających zawarcie Umowy
              ubezpieczenia) opcja dostępna z czasową całkowitą niezdolnością
              wskutek wypadku
            </small>
          </td>
          <td>
            <small className="align-middle text-center">
              świadczenie jednorazowe okres oczekiwania - 12 miesięcy
            </small>
          </td>
          <td className="align-middle text-center">100 000 zł</td>
          <td className="align-middle text-center">150 000 zł</td>
          <td></td>
        </tr>{' '}
        <tr>
          <td>
            <b>2A</b>
          </td>
          <td>
            <b className="firstColor">
              Trwała całkowita niezdolność do pracy w wyniku choroby
            </b>
            <br />
            <small>
              {' '}
              (nie może przekroczyć 10-krotności Ubezpieczonego przychodu za
              okres 12 miesięcy bezpośrednio poprzedzających zawarcie Umowy
              ubezpieczenia) opcja dostępna z trwałą całkowitą niezdolnością
              wskutek wypadku
            </small>
          </td>
          <td>
            <small className="align-middle text-center">
              świadczenie jednorazowe okres oczekiwania - 12 miesięcy
            </small>
          </td>
          <td className="align-middle text-center">100 000 zł</td>
          <td className="align-middle text-center">150 000 zł</td>
          <td></td>
        </tr>{' '}
        <tr className="text-center">
          <td colSpan={2}></td>
          <td>
            <b>Przedział wiekowy:</b>
          </td>
          <td colSpan={3}>
            <b>składka miesięczna:</b>
          </td>
        </tr>
        <tr className="text-center firstColor">
          <td colSpan={2}></td>
          <td>
            <b>18-40</b>
          </td>
          <td>
            <b>78 zł</b>
          </td>
          <td>
            <b>146 zł</b>
          </td>
          <td></td>
        </tr>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default BenefitsTable;
