import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import classes from './ActivationExpiredForm.module.scss';
import InputField from '../../StaticFormComponents/TextField';
import { replace } from 'connected-react-router';

import { Button, Form, Alert, Spinner } from 'reactstrap';

import { required, email } from 'redux-form-validators';

let ActivationExpiredForm = (props) => {
  const { handleSubmit, pristine, submitting, error } = props;

  return (
    <Form className={classes.form} onSubmit={handleSubmit}>
      <Alert color="primary">
        Twój link służący do aktywacji konta jest już nieaktualny, prosimy o
        ponowne wprowadzenie adresu email na który zostanie wysłany nowy link
        aktywacyjny.
      </Alert>

      {error && <Alert color="danger">{error}</Alert>}

      <Field
        name="email"
        label="Email"
        component={InputField}
        placeholder="Email"
        type="text"
        className="form-control"
        validate={[
          required({ msg: 'Pole wymagane' }),
          email({ msg: 'Wymagany jest poprawny email.' })
        ]}
      />
      <Button
        type="submit"
        color="success"
        className={classes.submitButton}
        disabled={submitting || pristine}>
        {!submitting && 'Wyślij'}
        {submitting && <Spinner size="sm" />}
      </Button>
    </Form>
  );
};

ActivationExpiredForm = reduxForm({
  form: 'activationExpired'
})(ActivationExpiredForm);

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      return new Promise((resolve, reject) => {
        dispatch(actions.resendActivation(values))
          .then((successAction) => {
            resolve(true);
            dispatch(replace('/activationExpired?requestSent=true'));
          })
          .catch((errorOrAbortAction) => {
            let errorMessage = errorOrAbortAction.error.response.data.message;

            if (!errorMessage) {
              errorMessage =
                'Konto o podanym email nie istnieje lub email został już potwierdzony.';
            }
            reject(
              new SubmissionError({
                _error: errorMessage
              })
            );
          });
      });
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ActivationExpiredForm);
