import React from 'react';

import { Input, FormGroup, Label, FormFeedback } from 'reactstrap';

const renderCheckbox = ({
  input,
  label,
  link,
  name,
  type,
  className,
  meta: { touched, error, warning }
}) => {
  const html = { __html: label };
  return (
    <FormGroup check style={{ marginBottom: '1rem' }}>
      <Label check>
        <Input
          {...(touched && !!error ? { invalid: true } : {})}
          {...(touched && !!!error ? { valid: true } : {})}
          {...input}
          type={type}
          className={className}
          {...(type === 'checkbox' ? { checked: !!input.value } : {})}
        />
        <span dangerouslySetInnerHTML={html} />
        {touched &&
          ((error && <FormFeedback>{error}</FormFeedback>) ||
            (warning && <FormFeedback>{warning}</FormFeedback>))}
      </Label>
    </FormGroup>
  );
};

export default renderCheckbox;
