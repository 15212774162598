import React from 'react';
// import logo from './../../../assets/img/brand/pzu.png';
import photo3 from './../../../assets/img/brand/luxmed/22.jpg';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h4 className="firstColor">
        Bezpłatne wsparcie w likwidacji szkody komunikacyjnej z OC/AC
      </h4>
      <br />
      <h5 className="firstColor">Zakres usługi</h5>
      <ul className="mt-4 firstColor">
        <li>
          <b>Całkowicie bezpłatna usługa</b> likwidacji szkody komunikacyjnej w
          serwisach autoryzowanych oraz certyfikowanych współpracujących z
          siecią USP.
        </li>
        <li>
          <b>Zgłoszenie szkody</b> do Towarzystwa Ubezpieczeniowego w imieniu
          Klienta.
        </li>
        <li>
          <b>Bezgotówkowa likwidacja</b> szkody rozliczana pomiędzy Towarzystwem
          ubezpieczeniowym, a serwisem naprawczym bez udziału gotówkowego
          Klienta.
        </li>
        <li>
          <b>Zapewnienie bezpłatnego pojazdu zastępczego na czas naprawy</b>
        </li>
        <li>2 lata gwarancji na wykonaną naprawę pojazdu</li>
        <li>
          Pierwszeństwo realizacji napraw pojazdów w serwisach współpracujących
        </li>
        <li>
          Uzyskanie utraty wartości pojazdu w przypadku pierwszej kolizji z OC
          sprawcy na pojazdach nie starszych niż 5 lat
        </li>
      </ul>
      <br />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h4 className="firstColor">Kontakt:</h4>
            <br />
            <h5 className="firstColor">Telefonicznie: 507 20 90 40</h5>
            <br />
            <span className="firstColor">
              lub poprzez wypełnienie formularza:
            </span>
            <br />
            <br />
            <br />
            <a
              href="https://www.uniqueservicepartner.pl/kontakt/"
              target="_blank"
              className="btn btn-md btn-primary"
              rel="noopener noreferrer">
              <b>Skontaktuj się z nami</b>
            </a>
          </div>
          <div className="col-md-6">
            <img
              src={photo3}
              alt={photo3}
              style={{ maxWidth: '400px', borderRadius: '3px' }}
            />
          </div>
        </div>
      </div>

      {/* <p className="mt-4 firstColor">
        <b>5 sytuacji, w których portal Cash może Ci pomóc</b>{' '}
      </p>
      <ul className="firstColor">
        <li>
          Potrzebujesz dodatkowej gotówki na swoje wydatki - bez poręczycieli i
          zbędnych formalności
        </li>
        <li>
          Spłata obecnych pożyczek i kredytów jest dla Ciebie trudna –
          miesięczne raty pochłaniają zbyt dużą część Twojego budżetu{' '}
        </li>
        <li>
          Myślisz o zakupie czy remoncie domu lub mieszkania i potrzebujesz
          sprawdzonego źródła sfinansowania tych wydatków
        </li>
        <li>
          Chcesz odkładać na przyszłość lub szukasz pomysłu na swoje
          oszczędności
        </li>
        <li>
          Potrzebne Ci wygodne konto osobiste z kartą i aplikacją na telefon
        </li>
      </ul>

      <br />
      <p className="firstColor">
        <b>Przejdź do portalu Cash. Wybierz jedną z opcji:</b>
      </p>

      <div className="d-flex justify-content-around">
        <a
          href="https://www.portalcash.pl/TVP?utm_source=uniqueservicepartners&utm_medium=umiejscowieniereklamy&utm_campaign=partnerships"
          target="_blank"
          className="btn btn-md btn-primary d-flex align-items-center"
          rounded>
          <span>
            <b>Masz umowę o pracę z TVP</b>
          </span>
        </a>
        <a
          href="https://www.portalcash.pl/UniqueOffer_fe59767e-ab18-481b-8234-89196cfc07f3?utm_source=uniqueservicepartners&utm_medium=umiejscowieniereklamy&utm_campaign=partnerships"
          target="_blank"
          className="btn btn-md btn-primary d-flex align-items-center">
          <b>
            Masz inną umowę niż umowa o pracę <br />
            lub jesteś członkiem rodziny <br />
            pracownika TVP
          </b>
        </a>
      </div>

      <br />
      <p className="firstColor mt-5">
        <b>Jeśli potrzebujesz pomocy zadzwoń: 22 566 00 06</b>
        <br />
        od poniedziałku do piątku, 8:00 - 18:00 (opłata zgodna z taryfą
        operatora)
      </p>
      <br />
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
            <img src={logo} alt="pzu" style={{ width: '150px' }} />
          </div>
          <div>
            <p
              className="firstColor d-flex flex-column justify-content-center"
              style={{ height: '100%' }}>
              <b>Portal Cash – Twój zaufany partner</b>
              <b>
                Portalem Cash zarządza PZU Cash SA – spółka z Grupy PZU
              </b>{' '}
            </p>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Ogolne;
