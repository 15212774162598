import React, { Component } from 'react';
import { connect } from 'react-redux';
import Countdown, { formatTimeDelta } from 'react-countdown-now';

const renderMinutes = (delta) => {
  const formatted = formatTimeDelta(delta);
  const { minutes, seconds } = formatted;

  return (
    <span>
      {minutes}:{seconds}
    </span>
  );
};
class Inactive extends Component {
  state = { deadline: Date.now() };

  componentDidMount() {
    if (this.props.inactive === 'INACTIVE') {
      this.inactiveNotif();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.inactive === 'INACTIVE' && prevProps.inactive === 'AWAY') {
      this.inactiveNotif();
    }
  }

  inactiveNotif = () => {
    const deadline = Date.now() + 600000;
    this.setState({ deadline });
  };

  render() {
    if (this.props.inactive === 'INACTIVE') {
      return (
        <div className="autologout">
          Automatyczne wylogowanie za&nbsp;
          <Countdown date={this.state.deadline} renderer={renderMinutes} />
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    inactive: state.idle.idleStatus || ''
  };
};

export default connect(mapStateToProps)(Inactive);
