import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { reducer as idleReducer } from '../components/idle';

import { requestsReducer } from 'redux-saga-requests';
// import { ReduxStore as UppyRedux } from 'uppy';
import UppyRedux from '@uppy/store-redux';

import authReducer from './auth';
import dynamicReducer from './dynamic';
import modalReducer from './modal';
import { formEngineReducer, reduxFormExtension } from './formEngine';
import reduceReducers from 'reduce-reducers';

const extendedFormReducer = reduceReducers(formReducer, reduxFormExtension);

export default (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    form: extendedFormReducer,
    menu: requestsReducer({ actionType: `FETCH_MENU` }),
    dynamic: dynamicReducer,
    formEngine: formEngineReducer,
    uppy: UppyRedux.reducer,
    modal: modalReducer,
    idle: idleReducer
  });

  return (state, action) => {
    if (action.type === 'AUTH_LOGOUT') {
      state = { router: { ...state.router } };
    }

    return appReducer(state, action);
  };
};
