import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

const isOrange = window.location.hostname === 'orange.uspartner.pl';
const isInsurances = window.location.hostname === 'ubezpieczenia.uspartner.pl';
const isNais = window.location.hostname === 'nais.uspartner.pl';
const isRenomia = window.location.hostname === 'renomia.uspartner.pl';

const Footer = () => (
  <div className="footer">
    <div
      className="fixedWidth footerGrid"
      // style={{
      //   display: 'grid',
      //   gridAutoFlow: 'column',
      //   gridAutoColumns: 'max-content',
      //   gridColumnGap: '1rem'
      // }}
    >
      <div className="company">
        <a
          href="https://uspartner.pl"
          target="_blank"
          rel="noopener noreferrer">
          O nas
        </a>
      </div>

      {!isOrange && !isInsurances && !isNais && !isRenomia && (
        <div className="link1">
          <a
            href="/public-assets/USP_regulamin_serwisu%20TVP%2015.04.2019.pdf"
            // href={
            //   isOrange
            //     ? '/public-assets/USP_regulamin_serwisu_Orange_18.09.2023.pdf'
            //     : '/public-assets/luxmed2023/USP_regulamin_serwisu%20TVP%2015.04.2019.pdf'
            // }
            target="_blank"
            rel="noopener noreferrer">
            Regulamin
          </a>
        </div>
      )}

      {isNais && (
        <div className="link1">
          <a
            href="/public-assets/USP_regulamin_serwisu_Nais.pdf"
            // href={
            //   isOrange
            //     ? '/public-assets/USP_regulamin_serwisu_Orange_18.09.2023.pdf'
            //     : '/public-assets/luxmed2023/USP_regulamin_serwisu%20TVP%2015.04.2019.pdf'
            // }
            target="_blank"
            rel="noopener noreferrer">
            Regulamin
          </a>
        </div>
      )}

      {isRenomia && (
        <div className="link1">
          <a
            href="/public-assets/USP_regulamin_serwisu_Renomia.pdf"
            // href={
            //   isOrange
            //     ? '/public-assets/USP_regulamin_serwisu_Orange_18.09.2023.pdf'
            //     : '/public-assets/luxmed2023/USP_regulamin_serwisu%20TVP%2015.04.2019.pdf'
            // }
            target="_blank"
            rel="noopener noreferrer">
            Regulamin
          </a>
        </div>
      )}
      <div className="link2">
        <Link to="/kontakt">Kontakt</Link>
      </div>
      <div className="text">
        <span className="avoidWrap">UNIQUE SERVICE PARTNER Sp. z o.o.</span>
        <br />
        <span className="avoidWrap">ul. Obrzeżna 7,</span>{' '}
        <span className="avoidWrap">02-691 Warszawa.</span>
        <br />
        <span className="avoidWrap">KRS: 0000634003,</span>{' '}
        <span className="avoidWrap">NIP: 525-267-38-99,</span>{' '}
        <span className="avoidWrap">REGON: 365261002,</span>{' '}
        <span className="avoidWrap">
          <a href="tel:+48224173440">Tel. 22 417 34 40</a>
        </span>{' '}
        <span className="avoidWrap">(8:00-16:00)</span>
      </div>

      <div className="logo">
        <img src="/public-assets/tvp/usp567noborder.png" alt="logo USP" />
      </div>
    </div>
  </div>
);

export default Footer;
