import React from 'react';
import { Fields } from 'redux-form';
import { Row, Col, Input, FormGroup, Label, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { required } from 'redux-form-validators';

const renderFields = (props) => {
  // console.log(props);
  const {
    globalDisable,
    // change,
    // autofill,
    label,
    // type,
    // placeholder,
    required,
    isArrayField,
    clientFormChange,
    serverFormChange,
    viewAsHtml
  } = props;
  const fieldName = props.names[0];

  let field = props[fieldName];
  let formMeta = props.formMeta[fieldName];

  if (isArrayField) {
    field = props[props.rootKey][props.keyIndex][props.subKey];
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }

  const hidden = formMeta.hidden.input.value;
  const disabled = formMeta.disabled.input.value;

  if (hidden) return null;

  const {
    touched,
    error,
    // autofilled,
    // active,
    // pristing,
    // initial,
    warning
  } = field.meta;

  const onChange = (event) => {
    field.input.onChange(event);
    clientFormChange({
      fieldName,
      newValue: event.target.checked,
      oldValue: field.input.value
    });

    serverFormChange({
      trigger: fieldName,
      newValue: event.target.checked,
      oldValue: field.input.value
    });
  };

  // console.log(`Render in ${fieldName}`);

  const isDisabled = disabled || globalDisable;
  const html = { __html: label };

  if (viewAsHtml) {
    return (
      <Row>
        <Label sm={12}>
          {field.input.value && (
            <FontAwesomeIcon
              fixedWidth
              icon="check"
              style={{ color: 'green', marginRight: '0.5rem' }}
            />
          )}
          {!field.input.value && (
            <FontAwesomeIcon
              fixedWidth
              icon="times"
              style={{ color: 'red', marginRight: '0.5rem' }}
            />
          )}
          <span dangerouslySetInnerHTML={html} />
        </Label>
      </Row>
    );
  }
  return (
    <Col>
      {!hidden && (
        <FormGroup check row style={{ marginBottom: '1rem' }}>
          <Label check className={isDisabled ? 'text-muted' : ''}>
            <Input
              type="checkbox"
              {...(touched && !!error ? { invalid: true } : {})}
              // {...(touched && !!!error ? { valid: true } : {})}
              {...field.input}
              checked={field.input.value}
              disabled={isDisabled}
              onChange={onChange}
            />{' '}
            <span dangerouslySetInnerHTML={html} />
            {required && (
              <span style={{ color: 'red', fontWeight: '500' }}>*</span>
            )}
            {touched &&
              ((error && <FormFeedback>{error}</FormFeedback>) ||
                (warning && <FormFeedback>{warning}</FormFeedback>))}
          </Label>
        </FormGroup>
      )}
    </Col>
  );
};

class Checkbox extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label, type } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label ||
      type !== nextProps.type
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        key="k"
        globalDisable={this.props.globalDisable}
        // change={this.props.change}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={renderFields}
        // label="label"
        // type="text"
      />
    );
  }
}

export default Checkbox;
