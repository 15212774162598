import { put } from 'redux-saga/effects';
import * as actions from '../actions';
// import { autofill } from 'redux-form';
// import cloneDeep from 'lodash-es/cloneDeep';
// import ObservableSlim from 'observable-slim';
// import { push } from 'connected-react-router';
// import { getFormSyncErrors, touch } from 'redux-form';

export function* afterDynamicSaga(action) {
  // yield console.log('action', action);

  switch (action.data.pageTemplate) {
    case 'DocumentsPageTemplate':
      yield put(actions.fetchDocuments(action.data.pageId));
      break;
    case 'DataTableTemplate':
    case 'EmployeesConfirmProposalPageTemplate':
      yield put(actions.fetchTableRecords(action.data.pageId));
      break;
    case 'ObjectDocumentsPageTemplate':
      yield put(actions.fetchDocumentsByObjectIdTvp(action.meta.objectId));
      break;
    // case 'LettersPageTemplate':
    //   yield put(actions.fetchLetterTemplates(action.data.pageId));
    //   break;
    default:
      break;
  }
  // const data = { ...action.meta.initialData };
  // const formId = action.meta.formId;
}
