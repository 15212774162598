import React from 'react';
import { Fields } from 'redux-form';
import ReactTimeout from 'react-timeout';
import Loading from '../Loading/Loading';

class IntervalTimer extends React.Component {
  componentDidMount() {
    this.props.setInterval(() => {
      this.props.action();
    }, this.props.interval);
  }
  render() {
    return null;
  }
}

const ConnectedIntervalTimer = ReactTimeout(IntervalTimer);

const renderIntervalRequest = (props) => {
  const {
    // viewAsHtml,
    action,
    showSpinner,
    color,
    isArrayField,
    label,
    interval
  } = props;
  const fieldName = props.name;
  let formMeta = props.formMeta[fieldName];
  if (isArrayField) {
    console.log('array props? ', props);
    formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
  }
  const hidden = formMeta.hidden.input.value;

  // const globalDisable = props.globalDisable === true;
  // const disabled = formMeta.disabled.input.value === true;

  if (hidden) return null;

  return (
    <React.Fragment>
      {showSpinner && <Loading label={label} color={color} />}
      <ConnectedIntervalTimer action={action} interval={interval} />
    </React.Fragment>
  );
};

class IntervalRequest extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label, action } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label ||
      action !== nextProps.action
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        globalDisable={this.props.globalDisable}
        names={[
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={renderIntervalRequest}
      />
    );
  }
}

export default IntervalRequest;
