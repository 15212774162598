import DokumentyDoPobrania from './DokumentyDoPobrania';
import Ogolne from './Ogolne';
import PorownaniePakietow from './PorownaniePakietow';
import Przystapienie from './Przystapienie';
import Reklamacja from './Reklamacja';
import Ogolne3 from './Ogolne3';
import ZasadyUmawiania from './ZasadyUmawiania';
import Pomoc from './Pomoc';

export default {
  DokumentyDoPobrania,
  Ogolne,
  PorownaniePakietow,
  Przystapienie,
  Reklamacja,
  Ogolne3,
  ZasadyUmawiania,
  Pomoc
};
