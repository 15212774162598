import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class PorownaniePakietow extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <h3 className="page-header firstColor">
          Porównanie pakietów medycznych
        </h3>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === '1'
              })}
              onClick={() => {
                this.toggle('1');
              }}>
              Lekarze
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === '2'
              })}
              onClick={() => {
                this.toggle('2');
              }}>
              Badania laboratoryjne
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === '3'
              })}
              onClick={() => {
                this.toggle('3');
              }}>
              Badania diagnostyczne
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === '4'
              })}
              onClick={() => {
                this.toggle('4');
              }}>
              Zabiegi i badania ambulatoryjne
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === '5'
              })}
              onClick={() => {
                this.toggle('5');
              }}>
              Pozostałe
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <p />
            <table className="table table-striped table-condensed table-bordered secondColor">
              <tbody>
                <tr>
                  <th style={{ width: '33%' }} className="text-center">
                    Podstawowy
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    Rozszerzony
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    VIP
                  </th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>internista</li>
                      <li>lekarz rodzinny</li>
                      <li>pediatra</li>
                      <li>chirurg ogólny</li>
                      <li>ortopeda</li>
                      <li>ginekolog</li>
                      <li>laryngolog</li>
                      <li>okulista</li>
                      <li>dermatolog</li>
                      <li>endokrynolog</li>
                      <li>proktolog</li>
                      <li>diabetolog</li>
                      <li>dietetyk*</li>
                      <li>alergolog</li>
                      <li>gastroenterolog</li>
                      <li>kardiolog</li>
                      <li>neurolog</li>
                      <li>urolog</li>
                      <li>onkolog</li>
                      <li>ginekolog-onkolog</li>
                      <li>ginekolog-endokrynolog</li>
                      <li>lekarz rehabilitacji</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        wszyscy specjaliści z zakresu Podstawowego oraz niższej
                        wymienieni
                      </li>
                      {/* <li>alergolog</li> */}
                      <li>chirurg naczyniowy</li>
                      <li>chirurg onkolog</li>
                      <li>pulmonolog</li>
                      <li>reumatolog</li>
                      <li>hematolog</li>
                      <li>nefrolog</li>
                      <li>neurochirurg</li>
                      <li>medycyna podróży</li>
                      <li>lekarz chorób zakaźnych</li>
                      <li>lekarz medycyny sportowej</li>
                    </ul>
                  </td>
                  <td>
                    Nielimitowany dostęp do lekarzy wszystkich specjalności bez
                    skierowania.
                  </td>
                </tr>
              </tbody>
            </table>
            <small>
              *dietetyk – porada obejmuje wywiad i zalecenia dietetyczne bez
              ułożenia diety. Limit: 3 porady w ciągu roku.{' '}
            </small>
            <br />
            <small>
              Szczegółowe opisy zakresów dostępne w sekcji{' '}
              <a
                href="https://tvp-tuwpzuw.uspartner.pl/pracownicy/dokumenty/"
                target="_blank"
                rel="noopener noreferrer">
                Dokumenty do pobrania
              </a>
              .{' '}
            </small>
          </TabPane>
          <TabPane tabId="2">
            <p />
            <table className="table table-striped table-condensed table-bordered">
              <tbody>
                <tr>
                  <th style={{ width: '33%' }} className="text-center">
                    Podstawowy
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    Rozszerzony
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    VIP
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>
                      Badania laboratoryjne – wraz z pobraniem materiału do
                      badań, na podstawie skierowania od Konsultanta Wykonawcy –
                      w zakresie opisanym poniżej:
                    </p>

                    <ul>
                      <li>hematologiczne i koagulologiczne</li>
                      <li>
                        biochemiczne (surowicy krwi) i hormonalne oraz markery
                        nowotworowe
                      </li>
                      <li>diagnostyka infekcji</li>
                      <li>testowe moczu</li>
                      <li>kału</li>
                      <li>posiewy</li>
                      <li>badania bakteriologiczne wraz z pobraniem wymazu</li>
                    </ul>
                  </td>
                  <td colSpan="2">
                    Dostęp do wszystkich badań diagnostycznych dostępnych na
                    terenie Rzeczpospolitej Polskiej, wykonywanych ze wskazań
                    medycznych na podstawie skierowań wystawionych przez
                    Konsultanta Wykonawcy, z wyłączeniem badań genetycznych i
                    eksperymentalnych oraz badań, których cena jednostkowa
                    według cenników stosowanych standardowo w Placówkach
                    Udostępnionych Przez Wykonawcę przekracza 1 500,00 zł.
                  </td>
                </tr>
              </tbody>
            </table>
            <small>
              Szczegółowe opisy zakresów dostępne w sekcji{' '}
              <a
                href="https://tvp-tuwpzuw.uspartner.pl/pracownicy/dokumenty/"
                target="_blank"
                rel="noopener noreferrer">
                Dokumenty do pobrania
              </a>
              .
            </small>
          </TabPane>
          <TabPane tabId="3">
            <p />
            <table className="table table-striped table-condensed table-bordered">
              <tbody>
                <tr>
                  <th style={{ width: '33%' }} className="text-center">
                    Podstawowy
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    Rozszerzony
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    VIP
                  </th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>EKG spoczynkowe</li>
                      <li>
                        zdjęcie klatki piersiowej w projekcji PA i bocznej –
                        również z barytem, zdjęcie przeglądowe jamy brzusznej,
                        zdjęcie zatok, zdjęcia kostne – czaszki w projekcji PA i
                        bocznej, kręgosłupa (szyjnego, piersiowego, lędźwiowego,
                        krzyżowo-biodrowego) w projekcji AP i bocznej, kończyn w
                        projekcji AP i bocznej, miednicy w projekcji AP i
                        bocznej, stawów w projekcji AP i bocznej
                      </li>
                      <li>
                        USG jamy brzusznej, w tym wszystkich narządów jamy
                        brzusznej, USG tarczycy, USG ginekologiczne przez
                        powłoki brzusznej, USG ciąży przez powłoki brzuszne, USG
                        piersi, USG gruczołu krokowego przez powłoki brzuszne,
                        ECHO serca, USG transwaginalne, USG gruczołu krokowego
                        transrektalne, USG ślinianek, USG jąder, USG stawu, USG
                        ścięgna
                      </li>
                      <li>mammografia</li>
                      <li>spirometria</li>
                      <li>audiometria</li>
                      <li>densytometria</li>
                      <li>
                        skórne testy alergiczne (panel pokarmowy i wziewny)
                      </li>
                    </ul>
                  </td>
                  <td colSpan="2">
                    Dostęp do wszystkich badań diagnostycznych dostępnych na
                    terenie Rzeczpospolitej Polskiej, wykonywanych ze wskazań
                    medycznych na podstawie skierowań wystawionych przez
                    Konsultanta Wykonawcy, z wyłączeniem badań genetycznych i
                    eksperymentalnych oraz badań, których cena jednostkowa
                    według cenników stosowanych standardowo w Placówkach
                    Udostępnionych Przez Wykonawcę przekracza 1 500 zł.
                  </td>
                </tr>
              </tbody>
            </table>
            <small>
              Szczegółowe opisy zakresów dostępne w sekcji{' '}
              <a
                href="https://tvp-tuwpzuw.uspartner.pl/pracownicy/dokumenty/"
                target="_blank"
                rel="noopener noreferrer">
                Dokumenty do pobrania
              </a>
              .
            </small>
          </TabPane>
          <TabPane tabId="4">
            <p />
            <table className="table table-striped table-condensed table-bordered">
              <tbody>
                <tr>
                  <th style={{ width: '33%' }} className="text-center">
                    Podstawowy
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    Rozszerzony
                  </th>
                  <th style={{ width: '33%' }} className="text-center">
                    VIP
                  </th>
                </tr>
                <tr>
                  <td colSpan="3">
                    <p>
                      Zabiegi i badania ambulatoryjne – zabiegi wykonywane w
                      warunkach ambulatoryjnych przez lekarza lub pielęgniarkę
                      podczas lub po konsultacji lekarskiej, w tym bezpłatne
                      materiały, leki i znieczulenie miejscowe niezbędne do
                      wykonania zabiegu lub badania, a w szczególności:
                    </p>

                    <ul>
                      <li>założenie i zmiana opatrunku</li>
                      <li>opatrunki związane z oparzeniami</li>
                      <li>
                        nacięcie i drenaż ropnia w przypadkach wymagających
                        niezwłocznej pomocy
                      </li>
                      <li>założenie i usunięcie szwów</li>
                      <li>usunięcie kleszcza</li>
                      <li>
                        założenie, zmiana i zdjęcie gipsu (w tym gipsu lekkiego)
                      </li>
                      <li>
                        unieruchomienie, założenie temblaka, założenie opaski
                      </li>
                      <li>punkcja, w tym m. in. zatoki szczękowej</li>
                      <li>założenie i usunięcie przedniej tamponady nosa</li>
                      <li>
                        badanie ostrości widzenia wraz z doborem szkieł
                        korekcyjnych, pomiar ciśnienia śródgałkowego, badanie
                        dna oka
                      </li>
                      <li>usunięcie ciała obcego z oka, nosa, ucha, gardła</li>
                      <li>płukanie uszu</li>
                      <li>przedmuchiwanie i kateteryzacja trąbki słuchowej</li>
                      <li>
                        iniekcje: domięśniowa, dożylna, podskórna, dostawowa,
                        wlew dożylny (kroplówka)
                      </li>
                      <li>wykonanie szczepienia</li>
                      <li>pobranie materiału do badań</li>
                      <li>standardowa cytologia ginekologiczna</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <small>
              Szczegółowe opisy zakresów dostępne w sekcji{' '}
              <a
                href="https://tvp-tuwpzuw.uspartner.pl/pracownicy/dokumenty/"
                target="_blank"
                rel="noopener noreferrer">
                Dokumenty do pobrania
              </a>
              .
            </small>
          </TabPane>
          <TabPane tabId="5">
            <p />
            <table className="table table-striped table-condensed table-bordered">
              <tbody>
                <tr>
                  <th style={{ width: '25%' }} className="text-center">
                    Świadczenie
                  </th>
                  <th style={{ width: '25%' }} className="text-center">
                    Podstawowy
                  </th>
                  <th style={{ width: '25%' }} className="text-center">
                    Rozszerzony
                  </th>
                  <th style={{ width: '25%' }} className="text-center">
                    VIP
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Badania profilaktyczne</th>
                  <td colSpan="2">
                    <p>Raz do roku bez skierowania:</p>
                    <ul>
                      <li>mocz – badanie ogólne</li>
                      <li>morfologia, płytki krwi, rozmaz automatyczny</li>
                      <li>lipidogram</li>
                      <li>glukoza / glukoze na czczo</li>
                      <li>standardowa cytologia ginekologiczna</li>
                    </ul>
                  </td>
                  <td>
                    <p>Przegląd stanu zdrowia w każdym roku.</p>
                  </td>
                </tr>
                <tr>
                  <th className="text-center">Wizyty domowe</th>
                  <td colSpan="3">
                    Dwie wizyty w każdym z 12 miesięcy obowiązywania ochrony
                    ubezpieczeniowej dla każdego Uprawnionego.
                  </td>
                </tr>
                <tr>
                  <th className="text-center">Szczepienia przeciwko grypie</th>
                  <td colSpan="3">Raz do roku.</td>
                </tr>
                <tr>
                  <th className="text-center">Rehabilitacja</th>
                  <td colSpan="2">
                    Limit 30 procedur w ciągu 12 miesięcy obowiązywania ochrony
                    ubezpieczeniowej dla każdego Uprawnionego*:
                    <ul>
                      <li>konsultacja fizjoterapeuty</li>
                      <li>
                        konsultacja lekarska w celu ustalenia trybu
                        rehabilitacji (ze wskazań medycznych)
                      </li>
                      <li>elektrostymulacja</li>
                      <li>fonoforeza</li>
                      <li>galwanizacja</li>
                      <li>jonoforeza</li>
                      <li>krioterapia</li>
                      <li>laser</li>
                      <li>pole magnetyczne</li>
                      <li>prądy DD</li>
                      <li>prądy interferencyjne</li>
                      <li>prądy TENS</li>
                      <li>ultradźwięki</li>
                      <li>prądy Traberta</li>
                      <li>ćw. instruktażowe</li>
                      <li>terapia indywidualna</li>
                      <li>wyciąg trakcyjny kręgosłup lędźwiowy</li>
                      <li>wyciąg trakcyjny kręgosłup szyjny</li>
                      <li>
                        terapia miorelaksacyjna – masaż leczniczy kręgosłupa
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>Nielimitowane zabiegi fizjoterapii.</p>
                  </td>
                </tr>
                <tr>
                  <th className="text-center">Operacje</th>
                  <td>Nie</td>

                  <td>
                    Chirurgia jednego dnia - lista zabiegów - dedykowana
                    infolinia do umawiania operacji.
                  </td>
                  <td>
                    Operacje chirurgiczne - lista procedur - dedykowana
                    infolinia do umawiania operacji.
                  </td>
                </tr>
                <tr>
                  <th className="text-center">Stomatologia</th>
                  <td colSpan="2">
                    W zakresie nie węższym niż: pomoc doraźna, dyżur
                    stomatologiczny, profilaktyka stomatologiczna, znieczulenia
                  </td>

                  <td>
                    Nielimitowana opieka stomatologiczna w pełnym zakresie
                    stomatologii i chirurgii stomatologicznej z wyłączeniem
                    protetyki oraz usług z zakresu ortodoncji i implantologii.
                  </td>
                </tr>
                <tr>
                  <th className="text-center">Indywidualny Opiekun Medyczny</th>
                  <td>Nie</td>
                  <td>Nie</td>
                  <td>Tak</td>
                </tr>
                <tr>
                  <th className="text-center">Prowadzenie ciąży</th>
                  <td>Tak</td>
                  <td>Tak</td>
                  <td>Tak</td>
                </tr>
                <tr>
                  <th className="text-center">Prywatny poród</th>
                  <td>Nie</td>
                  <td>Nie</td>
                  <td>Tak</td>
                </tr>
                <tr>
                  <th className="text-center">
                    Wyjazdowa opieka pielęgniarska
                  </th>
                  <td>Nie</td>
                  <td>Nie</td>
                  <td>Tak</td>
                </tr>
              </tbody>
            </table>
            <small>
              Szczegółowe opisy zakresów dostępne w sekcji{' '}
              <a
                href="https://tvp-tuwpzuw.uspartner.pl/pracownicy/dokumenty/"
                target="_blank"
                rel="noopener noreferrer">
                Dokumenty do pobrania
              </a>
              .{' '}
            </small>
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}

export default PorownaniePakietow;
