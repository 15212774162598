import React from 'react';
// import { connect } from 'react-redux';

import classes from './ChangePassword.module.scss';
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm/ChangePasswordForm';

import { Row, Col, Alert } from 'reactstrap';

const ChangePassword = (props) => {
  const query = new URLSearchParams(props.location.search);

  const newPassword = query.get('newPassword');
  if (newPassword) {
    return (
      <Row>
        <Col sm="10" md="8" lg="6" xl="5" className={classes.centered}>
          <Alert color="success" fade={false}>
            Hasło zostało pomyślnie zmienione.
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col sm="10" md="8" lg="6" xl="5" className={classes.centered}>
        <ChangePasswordForm key={props.location.key} />
      </Col>
    </Row>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.auth.token !== null,
//     authRedirectPath: state.auth.authRedirectPath
//   };
// };

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     setRedirect: () =>
//       dispatch(setAuthRedirectPath(props.location.state.referrer))
//   };
// };

// export default connect(
//   mapStateToProps
//   // mapDispatchToProps
// )(ChangePassword);

export default ChangePassword;
