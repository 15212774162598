import React from 'react';
import calendar5 from './../../../assets/img/brand/luxmed/calendarIcons/5.png';
import calendar7 from './../../../assets/img/brand/luxmed/calendarIcons/7.png';
import calendar15 from './../../../assets/img/brand/luxmed/calendarIcons/15.png';
import calendar16 from './../../../assets/img/brand/luxmed/calendarIcons/16.png';
import calendar25 from './../../../assets/img/brand/luxmed/calendarIcons/25.png';

const isNais =
  window.location.hostname === 'nais.uspartner.pl' ||
  window.location.hostname === 'renomia.uspartner.pl';

const ZasadyPrzystepowania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor ">Zasady przystępowania</h3>
      <b className="firstColor">
        Do Ubezpieczenia Szpitalnego LUX MED mogą przystąpić:
      </b>
      <ul className="firstColor">
        <li className="mt-4">
          <b>
            pracownicy, byli pracownicy i współpracownicy {!isNais && 'TVP'}
          </b>{' '}
          – osoby w wieku od 18 do 70 roku życia będące Głównym Ubezpieczonym
        </li>
        <li className="mt-2">
          <b>partnerzy pracowników, byłych pracowników i współpracowników</b> –
          współmałżonek lub osoba prowadząca z Głównym Ubezpieczonym wspólne
          gospodarstwo domowe, która w dniu rozpoczęcia ochrony miała ukończone
          18 i nie miała ukończonych 70 lat.
        </li>
        <li className="mt-2">
          <b>
            dzieci pełnoletnie/ dzieci niepełnoletnie pracowników, byłych
            pracowników i współpracowników –
          </b>{' '}
          <br />
          Dziecko Niepełnoletnie – dziecko własne lub przysposobione Głównego
          Ubezpieczonego lub Partnera, które nie ukończyło 18 lat. <br />
          Dziecko Pełnoletnie - dziecko własne lub przysposobione Głównego
          Ubezpieczonego lub Partnera, które ukończyło 18 lat.
        </li>
      </ul>
      <br />
      <div className="ovalSpan mt-3">
        Jeśli jesteś zainteresowany przystąpieniem do Ubezpieczenia pamiętaj,
        aby:
      </div>
      <div className="container firstColor mt-4">
        <div className="row">
          <div className="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={calendar5} alt="calendar" />
          </div>
          <div className="col-sm-11">
            najpóźniej do 5 dnia każdego miesiąca zgłosić chęć przystąpienia do
            ubezpieczenia poprzez naszą stronę internetową –{' '}
            <b>
              to bardzo ważne, jeśli chcesz, aby opieka rozpoczęła się od
              kolejnego miesiąca
            </b>
          </div>
        </div>
      </div>

      <div className="ovalSpan mt-5">Co nastąpi dalej?</div>
      <div className="container firstColor mt-4">
        <div className="row">
          <div className="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={calendar7} alt="calendar" />
          </div>
          <div className="col-sm-11">
            do 7 dnia miesiąca na podany przez Ciebie adres e-mail podczas
            rejestracji otrzymasz link do e-deklaracji –{' '}
            <b>
              wypełnij ją wybierając interesujący Cię wariant i rodzaj
              ubezpieczenia. Aby opieka mogła rozpocząć się od kolejnego
              miesiąca Twoja e-deklaracja musi trafić do LUX MED Ubezpieczenia
              najpóźniej do 12 dnia miesiąca.
            </b>
          </div>
        </div>
      </div>
      <p className="firstColor mt-5">
        Jeśli e-deklaracja będzie wymagała uzupełnienia o dodatkowe informacje
        Pracownik LUX MED Ubezpieczenia skontaktuje się z Tobą mailowo lub
        telefonicznie w celu uzupełnienia niezbędnych informacji
      </p>
      <div className="container firstColor mt-5">
        <div className="row">
          <div className="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={calendar15} alt="calendar" />
          </div>
          <div className="col-sm-11">
            do 15 dnia miesiąca otrzymasz potwierdzenie, czy Twój wniosek został
            zaakceptowany. Do tego dnia otrzymasz również informacje o
            ewentualnych wyłączeniach zakresu ubezpieczenia, które mogą pojawić
            się na etapie oceny przez LUX MED Ubezpieczenia złożonego przez
            Ciebie wniosku{' '}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={calendar16} alt="calendar" />
          </div>
          <div className="col-sm-11">
            16 dnia miesiąca na podany przez Ciebie adres email podczas
            rejestracji otrzymasz numer rachunku bankowego do wpłaty pierwszej i
            kolejnych składek za wybrany przez Ciebie wariant ubezpieczenia{' '}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={calendar25} alt="calendar" />
          </div>
          <div className="col-sm-11">
            składkę za ubezpieczenie należy opłacić{' '}
            <b>najpóźniej do 25 dnia miesiąca</b> - w przypadku opłacenia
            składki po tym terminie Twoja ochrona ubezpieczeniowa nie będzie
            możliwa od najbliższego miesiąca i rozpocznie się w miesiącu
            kolejnym{' '}
          </div>
        </div>
      </div>
      <br />
      <br />

      <p className="firstColor">
        Na każdym kroku procesu rejestracji jesteśmy do Twojej dyspozycji w
        godzinach od 08:00 do 16:00
        <br /> telefonicznie <b>22 417 34 40</b> i mailowo{' '}
        <b>
          {isNais ? (
            <a href="mailto:nais@uspartner.pl">nais@uspartner.pl</a>
          ) : (
            <a href="mailto:nais@uspartner.pl">tvpubezpieczenia@uspartner.pl</a>
          )}
        </b>{' '}
      </p>
      <br />
      <p className="firstColor">
        LUX MED Ubezpieczenia to nazwa handlowa zakładu ubezpieczeń LMG
        Försäkrings AB z siedzibą w Sztokholmie, wykonującego w Polsce
        działalność poprzez oddział LMG Försäkrings AB S.A. Oddział w Polsce z
        siedzibą w Warszawie.
      </p>
    </React.Fragment>
  );
};

export default ZasadyPrzystepowania;
