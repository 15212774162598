import React from 'react';

import { Input, FormGroup, Label, FormFeedback, Alert } from 'reactstrap';

const renderField = ({
  input,
  label,
  autocomplete,
  name,
  type,
  required,
  meta: { touched, error, warning }
}) => {
  return (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <span style={{ color: 'red' }}> *</span>}
      </Label>
      <Input
        {...(touched && !!error ? { invalid: true } : {})}
        {...(touched && !!!error ? { valid: true } : {})}
        {...input}
        autoComplete={autocomplete}
        placeholder={label}
        type={type}
        className="form-control"
      />
      {touched && (error && <FormFeedback>{error}</FormFeedback>)}
      {warning && (
        <Alert color="danger" style={{ marginTop: '1rem' }}>
          {warning}
        </Alert>
      )}
    </FormGroup>
  );
};

export default renderField;
