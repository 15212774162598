import { takeEvery, all, select } from 'redux-saga/effects';
import {
  createRequestInstance,
  watchRequests,
  // sendRequest,
  success
} from 'redux-saga-requests';
import { createDriver } from 'redux-saga-requests-axios';
import axios from 'axios';

import * as actionTypes from '../actions/actionTypes';
import { logoutSaga, authFlowSaga, redirectPathSaga } from './auth';
import { registerUserSaga } from './register';
import {
  clientSideActionSaga,
  serverArrayChangeSaga,
  updateFormWithServerValuesSaga,
  initializeFormSaga,
  serverEventCompleteSaga,
  serverFieldChangeSaga,
  formEventSaga,
  formSubmitEventSaga,
  workflowTriggerSaga,
  workflowTriggerCompleteSaga
} from './formEngine';
import { afterDynamicSaga } from './dynamic';
import { downloadFileSaga, printPdfSaga } from './documents';

import { LOCATION_CHANGE } from 'connected-react-router';

const axiosInstance = axios.create({
  baseURL: '/api'
});

function* onRequestSaga(request, action) {
  const token = yield select((state) => state.auth.token);
  if (!request.headers) request.headers = {};
  request.headers.Authorization = token ? `Bearer ${token}` : '';
  return request;
}

// function* dynamicSaga(action) {
//   yield call(sendRequest, action);
// }

export function* rootSaga() {
  yield createRequestInstance({
    driver: createDriver(axiosInstance),
    onRequest: onRequestSaga
  });
  try {
    yield all([
      watchRequests({
        takeLatest: false,
        abortOn: actionTypes.AUTH_INITIATE_LOGOUT
      }),
      authFlowSaga(),
      takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
      takeEvery(actionTypes.REGISTER, registerUserSaga),
      takeEvery(LOCATION_CHANGE, redirectPathSaga),
      takeEvery(actionTypes.FORMENGINE_CLIENT_CHANGE, clientSideActionSaga),
      takeEvery(
        actionTypes.FORMENGINE_SERVER_ARRAY_CHANGE,
        serverArrayChangeSaga
      ),
      takeEvery(
        actionTypes.FORMENGINE_SERVER_FIELD_CHANGE,
        serverFieldChangeSaga
      ),
      takeEvery(actionTypes.FORMENGINE_INITIALIZE, initializeFormSaga),
      takeEvery(
        success(actionTypes.FORMENGINE_SERVER_CHANGE),
        updateFormWithServerValuesSaga
      ),
      takeEvery(actionTypes.FORMENGINE_EVENT_TRIGGER, formEventSaga),
      takeEvery(
        actionTypes.FORMENGINE_SUBMIT_EVENT_TRIGGER,
        formSubmitEventSaga
      ),
      takeEvery(
        success(actionTypes.FORMENGINE_SERVER_EVENT),
        serverEventCompleteSaga
      ),
      takeEvery(
        success(actionTypes.FORMENGINE_SUBMIT_EVENT_SEND),
        serverEventCompleteSaga
      ),
      takeEvery(actionTypes.FORMENGINE_WORKFLOW_TRIGGER, workflowTriggerSaga),
      takeEvery(
        success(actionTypes.FORMENGINE_WORKFLOW_TRIGGER_SEND),
        workflowTriggerCompleteSaga
      ),
      takeEvery(success(actionTypes.FETCH_DYNAMIC_PAGE), afterDynamicSaga),
      takeEvery(success(actionTypes.DOCUMENTS_DOWNLOAD), downloadFileSaga),
      takeEvery(success(actionTypes.DOCUMENTS_PRINT_PDF), printPdfSaga)
      // takeLatest('FETCH_DYNAMIC_PAGE', dynamicSaga)
    ]);
  } catch (error) {
    console.log(error);
  }
}

// export function* watchBurgerBuilder() {
//   yield takeEvery(actionTypes.INIT_INGREDIENTS, initIngredientsSaga);
// }

// export function* watchOrder() {
//   yield takeLatest(actionTypes.PURCHASE_BURGER, purchaseBurgerSaga);
//   yield takeEvery(actionTypes.FETCH_ORDERS, fetchOrdersSaga);
// }
