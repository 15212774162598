import React from 'react';
import consultant from './../../../assets/img/brand/pzu/consultant.png';
import mobile from './../../../assets/img/brand/pzu/mobile.png';
import screen from './../../../assets/img/brand/pzu/screen.png';
import person from './../../../assets/img/brand/pzu/person.png';
import mail from './../../../assets/img/brand/pzu/mail.png';
import write from './../../../assets/img/brand/pzu/write.png';
import notes from './../../../assets/img/brand/pzu/notes.png';
import info from './../../../assets/img/brand/pzu/info.png';
import bone from './../../../assets/img/brand/pzu/bone.png';

const ZasadyUmawiania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor">Opieka medyczna</h3>
      <div className="text-white p-2 sixthColor iconFromLeft infoIcon mt-4 mb-4">
        <h5 className="firstColor">
          <b>Jak umówić się na wizytę lekarską lub badanie?</b>
        </h5>
      </div>
      <div className="secondColor">
        <b className="firstColor">Do Twojej dyspozycji oddajemy: </b>
        <br />
        <br />
        <b>3 całodobowe infolinie czynne 7 dni w tygodniu:</b>
        <div class="container">
          <div class="row">
            <div class="col-sm-1 d-flex align-items-center">
              <img src={consultant} alt="consultant" />
            </div>
            <div class="col-sm">
              22 505 15 48; 799 698 698; 801 405 905
              <br />
              <small>(opłata zgodna z taryfą operatora)</small>
            </div>
          </div>
        </div>
        <br />
        <ul>
          <li>
            Konsultant pomoże umówić wizytę i udzieli niezbędnych informacji. Po
            zakończeniu rozmowy otrzymasz SMS potwierdzający termin wizyty.
          </li>
          <li>Dzień przed wizytą dostaniesz SMS przypominający.</li>
          <li>Na wizytę zabierz ze sobą dokument potwierdzający tożsamość.</li>
        </ul>
        <br />
        <b>Portal Pacjenta i aplikację mobilną</b>
        <br />
        <div class="container">
          <div class="row mt-3">
            <div class="col-sm-1 ">
              <img src={screen} alt="screen" />
            </div>
            <div class="col-sm-3">mojePZU.pl</div>

            <div class="col-sm-1">
              <img src={mobile} alt="mobile" />
            </div>
            <div class="col-sm">
              mojePZU.pl mobile
              <br />
              <small>(do pobrania z App Store i Google Play)</small>
            </div>
          </div>
        </div>
        <br />
        <span>
          Załóż konto w serwisie{' '}
          <a
            href="https://moje.pzu.pl"
            target="_blank"
            rel="noopener noreferrer">
            <b>https://moje.pzu.pl</b>
          </a>
          . Do jego założenia wystarczy Twoje imię, nazwisko i PESEL. Wybierz
          interesujące Cię usługi i korzystaj z Portalu:
        </span>
        <br />
        <br />
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <ul>
                <li>
                  <b>umawiaj i odwołuj</b> wizyty online,
                </li>
                <li>zweryfikuj swój zakres ubezpieczenia,</li>
                <li>
                  dołącz <b>dokumentację medyczną,</b>
                </li>
              </ul>
            </div>
            <div className="col-sm">
              <ul>
                <li>kup usługę spoza zakresu ubezpieczenia,</li>
                <li>
                  zamów <b>e-receptę</b> w ramach kontynuacji leczenie.{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <br />
        <div class="container">
          <b>Bezpośrednie umawianie w placówce:</b>

          <div class="row mt-3">
            <div class="col-sm-1 d-flex align-items-center justify-content-end">
              <img src={person} alt="person" />
            </div>
            <div class="col-sm-11 ">
              Masz również możliwość <b>bezpośredniego umówienia</b> się w
              wybranych placówkach medycznych. Lista placówek do bezpośredniego
              umawiania się jest dostępna w zakładce Dokumenty do pobrania.
            </div>
          </div>
          <br />
          <br />

          <b>Adres e-mail, do umawiania badań w języku angielskim:</b>

          <div class="row mt-3">
            <div class="col-sm-1 d-flex align-items-center justify-content-end">
              <img src={mail} alt="mail" />
            </div>
            <div class="col-sm-11">
              Jeśli nie posługujesz się biegle językiem polskim, na wizytę lub
              badania możesz umówić się w języku angielskim, wystarczy że
              wyślesz wiadomość na:{' '}
              <b>
                <a href="mailto:zamow_wizyte@pzu.pl">zamow_wizyte@pzu.pl</a>
              </b>{' '}
              lub{' '}
              <b>
                <a href="mailtovisit@pzu.pl">visit@pzu.pl</a>
              </b>
              . Do wiadomości możesz załączyć skierowania. Nasz konsultant
              skontaktuje się z Tobą.
            </div>
          </div>
        </div>
        <b />
        <p></p>
        <br />
        <b className="firstColor">
          Nie możesz przyjść na wizytę lub badanie - odwołaj ją
        </b>
        <ul className="okCircle">
          <li className="mt-2">Odpowiadając NIE na SMS-a przypominającego</li>
          <li className="mt-2">Dzwoniąc na infolinię</li>
          <li className="mt-2"> Klikając Odwołaj wizytę w mojePZU</li>
        </ul>
        <br />

        <div className="p-2 sixthColor  mt-4 mb-4">
          <h5 className="firstColor ml-4">
            <b>Gwarantowane terminy realizacji wizyt *</b>
          </h5>
        </div>
        <ul className="mt-4 ok">
          <li>Internista 1 dzień roboczy</li>
          <li>Pediatra 2 dni robocze</li>
          <li>
            Specjaliści: chirurg, ginekolog, laryngolog, okulista, ortopeda,
            dermatolog do 3 dni roboczych
          </li>
          <li>Pozostali specjaliści do 7 dni roboczych</li>
          <li> Badania laboratoryjne oraz RTG do 3 dni roboczych</li>
          <li> USG ogólne oraz mammografia do 5 dni roboczych</li>
          <li>
            Tomografia komputerowa, EKG, biopsje, densytometria do 7 dni
            roboczych
          </li>
          <li>Pozostałe badania diagnostyczne do 15 dni roboczych</li>
        </ul>
        <p>
          * Wskazane terminy nie dotyczą sytuacji, w której Pacjent ma określone
          preferencje co do godziny wizyty, placówki medycznej czy też
          konkretnego lekarza.{' '}
        </p>
        <br />
        <h3 className="firstColor">
          <b>Medycyna pracy</b>
        </h3>

        <div class="container">
          <div class="row mt-4">
            <div class="col-sm-1 d-flex align-items-center text-center">
              <img src={info} alt="info" />
            </div>
            <div class="col-sm-11 sixthColor pt-2 pb-2">
              <h5 className="firstColor">
                <b>Jak umówić się na badanie?</b>
              </h5>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-1 d-flex align-items-center text-center">
              <img src={write} alt="write" />
            </div>
            <div class="col-sm-11">
              Pracodawca wystawi skierowanie na badanie medycyny pracy i
              przekaże je bezpośrednio do nas.
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-1 d-flex align-items-center">
              <img src={consultant} alt="consultant" />
            </div>
            <div class="col-sm-11">
              Konsultant infolinii skontaktuje się z Tobą i przekaże informację
              o umówionym terminie badania.
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-1 d-flex align-items-center">
              <img src={notes} alt="notes" />
            </div>
            <div class="col-sm-11">
              Po badaniach otrzymasz od lekarza medycyny pracy dwa
              zaświadczenia. Jedno przekazujesz swojemu pracodawcy, a drugie
              zachowujesz dla siebie.
            </div>
          </div>
          <br />
        </div>
        <br />
        <h3 className="firstColor">
          <b>
            Operacje chirurgiczne (dostępne w pakiecie VIP)
            <br /> Chirurgia jednego dnia (dostępne w pakiecie rozszerzonym i
            VIP)
          </b>
        </h3>
        <div class="container">
          <div class="row mt-4">
            <div class="col-sm-1">
              <img src={bone} alt="bone" />
            </div>
            <div class="col-sm-11">
              Operacje chirurgiczne oraz zabiegi mogą być wykonane zgodnie z
              przypisanych zakresem ubezpieczenia, na podstawie skierowania od
              lekarza. Usługę możesz zarezerwować na infolinii PZU Zdrowie pod
              numerem (22) 735 39 55, czynnej od poniedziałku do piątku.
              <br />
              <br />
              Usługę zrealizujesz w placówce, którą wskaże Ci konsultant
              infolinii.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ZasadyUmawiania;
