import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Field, reduxForm } from 'redux-form';
import classes from './LoginForm.module.scss';
import InputField from '../../StaticFormComponents/TextField';

import { Button, Form, Alert, Spinner } from 'reactstrap';

import { required } from 'redux-form-validators';

let LoginForm = (props) => {
  const { handleSubmit, pristine, submitting, error } = props;
  return (
    <Form className={classes.form} onSubmit={handleSubmit}>
      <h4>Logowanie</h4>
      {error && <Alert color="danger">{error}</Alert>}

      <Field
        name="username"
        label="Adres e-mail"
        component={InputField}
        placeholder="Login"
        type="text"
        autocomplete="nope"
        className="form-control"
        validate={[required({ msg: 'Pole wymagane' })]}
      />
      <Field
        name="password"
        label="Hasło"
        component={InputField}
        type="password"
        autocomplete="nope"
        className="form-control"
        validate={[required({ msg: 'Pole wymagane' })]}
      />
      <Button
        type="submit"
        color="success"
        className={classes.submitButton}
        disabled={submitting || pristine}>
        {!submitting && 'Zaloguj się'}
        {submitting && <Spinner size="sm" />}
      </Button>
    </Form>
  );
};

LoginForm = reduxForm({
  form: 'login'
})(LoginForm);

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      return new Promise((resolve, reject) => {
        dispatch(actions.auth({ values, resolve, reject }));
      });
    }
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
