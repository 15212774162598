import React from 'react';

const renderStatic = (node, index) => {
  switch (node.type) {
    case 'h2':
      return (
        <h2
          key={index}
          className={node.classes ? node.classes.join(' ') : null}>
          {node.value}
        </h2>
      );
    case 'p':
      return (
        <p key={index} className={node.classes ? node.classes.join(' ') : null}>
          {node.value}
        </p>
      );
    case 'ul':
      return (
        <ul
          key={index}
          className={node.classes ? node.classes.join(' ') : null}>
          {node.components.map((subNode, index) =>
            renderStatic(subNode, index)
          )}
        </ul>
      );
    case 'li':
      return (
        <li
          key={index}
          className={node.classes ? node.classes.join(' ') : null}>
          {node.value}
        </li>
      );
    case 'li br':
      return (
        <b>
          <li
            key={index}
            className={node.classes ? node.classes.join(' ') : null}>
            {node.value}
          </li>
        </b>
      );
    case 'span':
      return (
        <span
          key={index}
          className={node.classes ? node.classes.join(' ') : null}>
          {node.value}
        </span>
      );
    case 'span bold':
      return (
        <span
          key={index}
          className={node.classes ? node.classes.join(' ') : null}>
          <b>{node.value}</b>
        </span>
      );
    default:
      return;
  }
};

const StaticPageTemplate = (props) => {
  //   const Dynamic = props.dynamicComponent;
  const schema = props.data.config.schema;

  const renderedSchema = schema.components.map((node, index) =>
    renderStatic(node, index)
  );

  return <React.Fragment>{renderedSchema}</React.Fragment>;
};

export default StaticPageTemplate;
