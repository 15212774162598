import React from 'react';
import { Fields } from 'redux-form';
import {
  Label,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classes from './Tabs.module.scss';
import classnames from 'classnames';

class RenderTabs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.node.components[0].key
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const props = this.props;
    const {
      // globalDisable,
      viewAsHtml,
      label,
      labelColWidth,
      // type,
      // placeholder,
      // required,
      isArrayField,
      // clientFormChange,
      // serverFormChange,
      // serverArrayFormChange,
      parseProps,
      parseSchema
      // rows
    } = props;

    const fieldName = props.names[0];

    let field = props[fieldName];
    let formMeta = props.formMeta[fieldName];

    if (isArrayField) {
      // console.log('array props? ', props);
      field = props[props.rootKey][props.keyIndex][props.subKey];
      formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
    }

    const hidden = formMeta.hidden.input.value;
    // const disabled = formMeta.disabled.input.value;

    if (hidden) return null;

    const rowColWidth = 12 - labelColWidth;

    // const {
    //   // touched,
    //   // error,
    //   // autofilled,
    //   // active,
    //   // pristine,
    //   // initial,
    //   // warning
    // } = field.meta || {};

    // const onChange = (event) => {
    //   field.input.onChange(event);

    //   clientFormChange({
    //     fieldName,
    //     newValue: event.target.value,
    //     oldValue: field.input.value
    //   });
    // };

    // const onBlur = (event) => {
    //   event.preventDefault();
    //   field.input.onBlur(undefined);

    //   if (isArrayField) {
    //     serverArrayFormChange({
    //       rootKey: props.rootKey
    //     });
    //   } else {
    //     serverFormChange({
    //       trigger: fieldName,
    //       newValue: event.target.value,
    //       oldValue: field.input.value
    //     });
    //   }
    // };

    if (viewAsHtml) {
      return (
        <Row>
          <Label sm={labelColWidth}>{label}</Label>
          <Label sm={rowColWidth} style={{ fontWeight: 'bold' }}>
            {field.input.value}
          </Label>
        </Row>
      );
    }

    return (
      <div className={classes.tabPanel}>
        <div className={classes.tabHeading}>
          <Nav tabs className={classes.navTabs}>
            {props.node.components.map((tab) => {
              return (
                <NavItem key={tab.key}>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === tab.key,
                      [classes.tab]: true
                    })}
                    onClick={() => this.toggle(tab.key)}>
                    {tab.label}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </div>
        <div className={classes.panelBody}>
          <TabContent activeTab={this.state.activeTab}>
            {props.node.components.map((tab, index) => {
              if (tab.components) {
                return (
                  <TabPane tabId={tab.key} key={index}>
                    {tab.components.map((tabComponent, tabIndex) => {
                      return (
                        <React.Fragment key={tabIndex}>
                          {parseSchema({
                            ...parseProps,
                            node: tabComponent,
                            key: tabComponent.key
                          })}
                        </React.Fragment>
                      );
                    })}
                  </TabPane>
                );
              } else {
                return null;
              }
            })}
            <TabPane tabId="1" />
          </TabContent>
        </div>
      </div>
    );
  }

  // return (
  //   <div>
  //     {!hidden && (
  //       <FormGroup row>
  //         <Label for={fieldName} sm={labelColWidth}>
  //           {label}{' '}
  //           {required && (
  //             <span style={{ color: 'red', fontWeight: '500' }}>*</span>
  //           )}
  //         </Label>
  //         <Col sm={rowColWidth}>
  //           <Input
  //             {...(touched && !!error ? { invalid: true } : {})}
  //             // {...(touched && !!!error ? { valid: true } : {})}
  //             {...field.input}
  //             placeholder={placeholder}
  //             type={type}
  //             className="form-control"
  //             disabled={disabled || globalDisable}
  //             onChange={onChange}
  //             onBlur={onBlur}
  //             {...{ rows: rows } || null}
  //           />
  //           {touched &&
  //             ((error && <FormFeedback>{error}</FormFeedback>) ||
  //               (warning && <FormFeedback>{warning}</FormFeedback>))}
  //         </Col>
  //       </FormGroup>
  //     )}
  //   </div>
  // );
}

class Tabs extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { globalDisable, viewAsHtml, label, type, parseProps } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      parseProps !== nextProps.parseProps ||
      label !== nextProps.label ||
      type !== nextProps.type
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        key="k"
        globalDisable={this.props.globalDisable}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`
        ]}
        component={RenderTabs}
      />
    );
  }
}

export default Tabs;
