import React from 'react';

const GoogleMapsUSP = () => {
  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <iframe
          width="100%"
          height="350"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Unique%20Serive%20Partner,%20Obrze%C5%BCna%207+(Unique%20Serive%20Partner)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          style={{ border: 0 }}
          allowFullScreen></iframe>
        <p>
          <a
            href="https://www.gps.ie/"
            target="_blank"
            rel="noopener noreferrer"></a>
        </p>
      </div>
      <p className="text-justify">
        <small>
          Administratorem danych osobowych jest Unique Service Partner sp. z
          o.o., z siedzibą w Warszawie, ul. Obrzeżna 7, 02-691 Warszawa (dalej:
          USP). Dane osobowe osób korzystających z formularza kontaktowego
          przetwarzane są w celu obsługi zapytania przesłanego przez
          udostępniony formularz. Więcej informacji o przetwarzaniu danych
          osobowych, w tym o przysługujących prawach, znajduje się w naszej{' '}
          <a
            href="https://www.uniqueservicepartner.pl/polityka-prywatnosci/"
            target="_blank"
            rel="noreferrer">
            Polityce Prywatności
          </a>
          .
        </small>
      </p>
    </React.Fragment>
  );
};

export default GoogleMapsUSP;
