import React from 'react';
import { connect } from 'react-redux';
import classes from './TablePageTemplate.module.scss';
// import * as actions from '../../../../store/actions/index';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../../../components/Loading/Loading';
import { push } from 'connected-react-router';
import Moment from 'react-moment';
import moment from 'moment';
import { fetchTableRecords, openModal } from '../../../../store/actions';

import {
  Table,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Alert
} from 'reactstrap';

class TablePageTemplate extends React.Component {
  render() {
    const {
      columns,
      actions,
      tablePage,
      totalPages,
      //  formConfig,
      records,
      loadingRecords,
      columnKeys,
      settings
    } = this.props;
    const hasMultipleActions = actions && actions.length > 1;
    return (
      <React.Fragment>
        <h2 className="page-header">{this.props.data.pageName}</h2>
        {settings.nonEmptyAlert &&
          !loadingRecords &&
          records.length > 0 &&
          settings.nonEmptyAlert === 'refundacje' && (
            <Alert color="info">
              Szanowni Państwo, uprzejmie dziękujemy za przesłanie Wniosku o
              refundację. Informujemy, że postaramy się go rozpatrzyć
              niezwłocznie, jednak nie później niż w ciągu 30 dni. O podjętej
              decyzji zostaną Państwo poinformowani na wskazany we Wniosku adres
              korespondencyjny lub e-mail. Wszelkie zapytania dotyczące
              refundacji mogą Państwo przesyłać na adres:{' '}
              {/* <a href="mailto:zwroty_refundacje@pzu.pl"> */}
              {/* zwroty_refundacje@pzu.pl */}
              {/* </a> */}.
            </Alert>
          )}
        <div className={classes.tableOverflow}>
          <Table size="ml" hover>
            <thead>
              <tr className="bg-primary">
                {columns.map((column) => (
                  <th key={column.columnKey}>{column.columnName}</th>
                ))}
                {hasMultipleActions && <th className="bg-primary" />}
              </tr>
            </thead>
            <tbody>
              {records.map((row, index) => {
                const rowActions = actions.filter((action) => {
                  const condition = action.condition;
                  if (condition.operator) {
                    switch (condition.operator) {
                      case '=':
                        if (row[condition.keyInRow] === condition.value) {
                          return true;
                        } else {
                          return false;
                        }
                      case '!=':
                        if (row[condition.keyInRow] !== condition.value) {
                          return true;
                        } else {
                          return false;
                        }
                      default:
                        return true;
                    }
                  } else {
                    return true;
                  }
                });

                const defaultActions = rowActions.filter(
                  (x) => x.isDefaultAction
                );

                const defaultAction = () => {
                  if (defaultActions.length > 0) {
                    this.props.dispatchAction(row, defaultActions[0]);
                  }
                };

                return (
                  <tr key={index}>
                    {columnKeys.map((key, index) => {
                      const cellData = row[key];
                      let formattedCell = cellData || '';

                      if (
                        cellData &&
                        cellData.length > 10 &&
                        moment(cellData, moment.ISO_8601, true).isValid()
                      ) {
                        formattedCell = (
                          <Moment format="DD.MM.YYYY">{cellData}</Moment>
                        );
                      }

                      return (
                        <td key={index} onClick={defaultAction}>
                          {formattedCell}
                        </td>
                      );
                    })}

                    {hasMultipleActions && (
                      <td>
                        {rowActions.map((action, index) => {
                          return (
                            <Button
                              key={index}
                              className="bg-primary"
                              onClick={() =>
                                this.props.dispatchAction(row, action)
                              }>
                              {action.displayName}
                            </Button>
                          );
                        })}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {records.length < 1 && !loadingRecords && (
            <div className={classes.centered}>Brak danych.</div>
          )}
          {loadingRecords && <Loading />}
          {totalPages > 1 && (
            <Pagination
              aria-label="Strony tabelki"
              className={classes.tablePaginator}>
              <PaginationItem>
                <PaginationLink
                  previous
                  href="#"
                  onClick={() =>
                    this.props.changePage(parseInt(tablePage) - 2, totalPages)
                  }
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">
                  Strona {tablePage}/{totalPages}
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  next
                  href="#"
                  onClick={() => this.props.changePage(tablePage, totalPages)}
                />
              </PaginationItem>
            </Pagination>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const data = state.dynamic.ids[ownProps.guid] || {};
  const config = data.config;
  const columns = config.columns || [];
  const actions = config.actions || [];
  const settings = data.settings || {};
  const meta = state.dynamic.meta[ownProps.guid] || {};
  const formConfig = config.formConfig || {};
  const columnKeys = columns.map((column) => column.columnKey);
  const records = data.data || [];
  const loadingRecords =
    meta.tableRecords && meta.tableRecords.loading === true;
  const tableMetadata = data.metadata || {};
  const tablePage = tableMetadata.pageIndex ? tableMetadata.pageIndex + 1 : 1;
  const totalPages = data.metadata
    ? Math.ceil(tableMetadata.total / tableMetadata.pageSize)
    : 1;

  return {
    data: data,
    columns: columns,
    actions: actions,
    formConfig: formConfig,
    columnKeys: columnKeys,
    records: records,
    settings,
    meta,
    tablePage,
    loadingRecords,
    totalPages
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  //   const pageId = props.match.params[props.queryParam];
  //   const queryStringParams = new URLSearchParams(props.location.search);
  //   const objectId = queryStringParams.get('objectId');
  //const defaultActions = props.data.config.actions.filter(x => x.isDefaultAction);
  //const defaultAction = defaultActions[0];
  //console.log(defaultAction);

  return {
    dispatchAction: (row, action) => {
      if (action && action.actionType === 'redirect') {
        let params = `/${action.webPage}`;
        if (action.tabWebPage) {
          params = params + `/${action.tabWebPage}`;
        }
        const queryStringParams = {};
        action.webPageParams.forEach((param) => {
          queryStringParams[param.key] = row[param.value];
        });

        const queryString = `?${new URLSearchParams(
          queryStringParams
        ).toString()}`;

        dispatch(push({ pathname: params, search: queryString }));
      } else if (action && action.actionType === 'modal') {
        const queryStringParams = {};
        action.webPageParams.forEach((param) => {
          queryStringParams[param.key] = row[param.value];
        });

        const queryString = `?${new URLSearchParams(
          queryStringParams
        ).toString()}`;

        dispatch(
          openModal({ id: action.webPage, type: 'dynamic', queryString })
        );
      }
    },
    navigateTo: (row, action) => {
      console.log(row);
      console.log(action);
    },
    changePage: (pageId, totalPages) => {
      if (totalPages > pageId > 0) {
        dispatch(fetchTableRecords(ownProps.guid, pageId));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablePageTemplate);
